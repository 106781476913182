.map{
  padding-top: 3rem;
  &__wrapper{
    max-width: 1435px;
  }
  &__title{
    max-width: 932px;
    margin-bottom: 3rem;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  &__subtitle{
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 3.2rem;
    color: $base-color;
    margin-bottom: 1.5rem;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  &-form{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 35px;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 0;
    }
    &__title{
      width: 100%;
      color: $gray;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      @media (max-width: 768px) {
        font-size: 10px;
        text-align: center;
      }
    }
    &__labels{
      display: flex;
      flex-wrap: wrap;
      margin-right: 30px;
      width: percentage(1130 / 1415);
      @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
        justify-content: center;
      }
      label{
        input{
          display: none;
        }
        span{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80px;
          width: 80px;
          border: 1px solid $footer-bg;
          box-sizing: border-box;
          cursor: pointer;
          @include transition();
          @media (max-width: 600px) {
            height: 70px;
            width: 70px;
          }
          svg{
            path{
              @include transition();
            }
          }
          &:hover{
            background-color: $brown;
            border-color: $brown;
            @include transition();
            svg{
              path{
                fill: $white;
                @include transition();
              }
            }
          }
        }
        input:checked + span{
          background-color: $brown;
          border-color: $brown;
          @include transition();
          svg{
            path{
              fill: $white;
              @include transition();
            }
          }
        }
      }
    }
    &__reset{
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          justify-content: center;
          height: 80px;
        }
        button{
          border: 0;
          outline: 0;
          background-color: transparent;
          span{
            color: $blue;
            opacity: 0.8;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
          }
        }
    }

  }
  &-table{
    margin-bottom: 6rem;
    &--header{
      display: flex;
      padding-bottom: 1.1rem;
      span{
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $base-color;
        opacity: .3;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    &--line{
      display: flex;
      align-items: flex-start;
      padding: 2rem 0;
      @media (max-width: 1000px) {
        flex-wrap: wrap;
      }
      &:nth-child(even){
        background-color: rgba(234, 234, 234, .3);;
      }
      .map-table--number{
        box-sizing: border-box;
        padding-left: 2rem;
        @media (max-width: 1000px) {
          padding: 10px;
          text-align: left;
        }
        a{
          color: $base-color;
          font-weight: $font-weight--500;
          font-size: 1.8rem;
          line-height: 3.2rem;
          text-transform: uppercase;
        }
      }
      .map-table--locations{
        @media (max-width: 1000px) {
          padding-right: 10px;
        }
        span{
          color: rgba(33, 35, 36, .8);
          font-size: 1.5rem;
          line-height: 2.8rem;
        }
      }
      .map-table--options{
        box-sizing: border-box;
        padding-left: 4.8rem;
        padding-right: 2rem;
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        align-items: center;
        @media (max-width: 1000px) {
          margin-top: 15px;
          padding: 0 10px;
        }
        .icon{
          margin-right: 2rem;
          padding: .5rem 0;
          display: flex;
          align-items: center;
          @media (max-width: 768px){
            max-width: 30px;
          }
        }
      }
    }
    &--number{
      width: percentage(140 / 1405);
      @media (max-width: 1000px) {
        width: 25%;
      }
    }
    &--locations{
      width: percentage(300 / 1405);
      @media (max-width: 1000px) {
        width: 75%;
      }
    }
    &--options{
      width: percentage(965 / 1405);
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }
  &-section{
    position: relative;
  }
  .yandex-map{
    height: 500px;
    margin-bottom: 6rem;
    overflow: hidden;
    img[alt="Какая карта будет"]{
      width: 100%;
      height: 100%;
      min-width: 1920px;
    }
  }
  .empty-map{
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(248, 248, 248, .9);
    &.active{
      display: flex;
    }
    span{
      color: $base-color;
      font-size: 4rem;
      line-height: 5rem;
      font-weight: 500;
    }
  }
}