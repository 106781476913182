@charset "UTF-8";
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
/*------------------------------------*\
  # Transitions
\*------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

* {
  box-sizing: border-box; }

html {
  font-size: 10px;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html,
body {
  height: 100%;
  position: relative; }

body {
  color: #212324;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", "Arial", "Helvetica CY", "Nimbus Sans L", sans-serif;
  line-height: normal;
  background-color: #fff; }

a {
  color: #212324;
  /*
  text-decoration: none;
  background-color: transparent;
  outline: none;
  -webkit-text-decoration-skip: objects;
  &:hover {
    color: $link-color--hover;
    text-decoration: underline;
  }

  &:active { color: $link-color--active; }
  */ }

b,
strong {
  font-weight: 700; }

i,
em {
  font-style: italic; }

s,
strike {
  text-decoration: line-through; }

dfn {
  font-style: italic; }

hr {
  margin: 10px 0;
  padding: 0;
  height: 0;
  border-color: #9F9F9F;
  border-style: solid;
  border-width: 1px 0 0 0;
  box-sizing: content-box; }

sub,
sup {
  font-size: 80%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

mark {
  color: #000;
  background: #ff0; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  height: 0;
  display: none; }

img {
  border: 0; }

figure {
  margin: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

td,
th {
  padding: 0; }

input,
textarea,
button,
select,
optgroup {
  margin: 0;
  padding: 0;
  outline: none;
  color: #212324;
  font-size: 16px;
  font-weight: 400;
  font-family: "Roboto", "Arial", "Helvetica CY", "Nimbus Sans L", sans-serif;
  line-height: normal;
  border-radius: 0; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

legend {
  border: 0;
  padding: 0; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  box-sizing: border-box; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  box-sizing: content-box;
  outline-offset: -2px;
  -webkit-appearance: textfield; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

.container {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .container > .content {
    flex: 1 0 auto;
    position: relative; }

ol, ul {
  list-style: none; }

.wrapper {
  margin: 0 auto;
  padding: 0 10px;
  max-width: 1920px;
  min-width: 280px; }

h1 {
  font-weight: 900;
  line-height: 5rem;
  font-size: 3.4rem;
  color: #1B77A1;
  margin-bottom: 3rem; }

.btn {
  font-size: 1.4rem;
  line-height: 2.6rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  background: #C2A271;
  border: 2px solid #C2A271;
  padding: 12px 24px;
  width: 100%;
  max-width: 22.8rem;
  position: relative;
  display: inline-block;
  text-align: center;
  transition: all 0.2s ease-in-out 0s; }
  @media (max-width: 1440px) {
    .btn {
      padding: 10px 20px; } }
  @media (max-width: 1440px) {
    .btn {
      padding: 8px 18px; } }
  .btn::after {
    content: '';
    width: 4rem;
    height: 2px;
    background: #A38453;
    position: absolute;
    right: 0;
    top: calc(50% - 4px);
    transform: translateX(50%);
    transition: all 0.2s ease-in-out 0s; }
    @media (max-width: 1440px) {
      .btn::after {
        top: calc(50% - 2px); } }
  .btn:hover {
    border-color: #A38453; }
    .btn:hover::after {
      transform: translateX(0); }
  .btn:active {
    background: #A38453; }

.btn-add {
  font-size: 1.4rem;
  line-height: 2.6rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #008EB9;
  background: transparent;
  border: 2px solid #008EB9;
  padding: 12px 24px;
  width: 100%;
  max-width: 22.8rem;
  position: relative;
  display: inline-block;
  text-align: center;
  margin-left: 40px;
  transition: all 0.2s ease-in-out 0s; }
  .btn-add:hover {
    background-color: #008EB9;
    color: #ffffff; }

.form-input {
  font-size: 1.5rem;
  line-height: 2.8rem;
  padding: 10px 16px;
  color: #212324;
  margin-bottom: 15px;
  border: 1px solid #EAEAEA; }
  .form-input::placeholder {
    color: #BCBDBD;
    opacity: 1; }

.form-checkbox {
  margin-bottom: 15px; }
  .form-checkbox input {
    display: none; }
    .form-checkbox input:checked + .form-checkbox-span span {
      background-color: #008EB9;
      transition: all 0.2s ease-in-out 0s; }
      .form-checkbox input:checked + .form-checkbox-span span:after {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 3px;
        background-color: #ffffff;
        border-radius: 2px;
        transform: rotate(45deg);
        top: 10px;
        left: 2px; }
        @media (max-width: 768px) {
          .form-checkbox input:checked + .form-checkbox-span span:after {
            width: 7px;
            top: 8px; } }
      .form-checkbox input:checked + .form-checkbox-span span:before {
        content: '';
        display: block;
        width: 15px;
        height: 3px;
        background-color: #ffffff;
        border-radius: 2px;
        transform: rotate(-49deg);
        position: absolute;
        left: 5px;
        top: 8px; }
        @media (max-width: 768px) {
          .form-checkbox input:checked + .form-checkbox-span span:before {
            width: 10px;
            top: 6px; } }
  .form-checkbox-span {
    display: inline-block;
    padding-left: 39px;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 1px;
    color: #212324;
    position: relative; }
    @media (max-width: 768px) {
      .form-checkbox-span {
        font-size: 1.4rem; } }
    .form-checkbox-span span {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 2px solid #008EB9;
      position: absolute;
      left: 0;
      cursor: pointer;
      transition: all 0.2s ease-in-out 0s; }
      @media (max-width: 768px) {
        .form-checkbox-span span {
          width: 20px;
          height: 20px; } }
    .form-checkbox-span a {
      color: #008EB9; }

.form-submit {
  display: inline-block;
  position: relative; }
  .form-submit:after {
    content: '';
    width: 4rem;
    height: 2px;
    background: #A38453;
    position: absolute;
    right: 0;
    top: calc(50% - 4px);
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    transition: all 0.2s ease-in-out 0s; }
  .form-submit:hover:after {
    transform: translateX(0); }
  .form-submit input {
    width: 200px;
    padding: 10px 24px; }
    .form-submit input:after {
      display: none; }

.forward {
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: #008EB9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.2s ease-in-out 0s; }
  .forward::after {
    content: '';
    width: 15px;
    height: 8px;
    background-image: url(../images/all-arrow.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-left: 1.5rem;
    transition: all 0.2s ease-in-out 0s; }
  .forward:hover {
    color: #00a2d3; }
    .forward:hover::after {
      transform: translateX(100%); }
  .forward:active {
    color: #1B77A1; }

div.modal-feedback {
  max-width: 720px;
  margin: 0 auto;
  position: relative; }
  div.modal-feedback .wrapper {
    padding: 3rem 3rem 4rem;
    max-width: none;
    margin: 0; }

.mfp-close-btn-in .mfp-close {
  color: transparent;
  top: 5px;
  right: 5px; }
  .mfp-close-btn-in .mfp-close:before, .mfp-close-btn-in .mfp-close:after {
    content: '';
    display: block;
    height: 44px;
    width: 2px;
    left: 50%;
    background-color: #9F9F9F;
    position: absolute; }
  .mfp-close-btn-in .mfp-close:before {
    transform: rotate(45deg); }
  .mfp-close-btn-in .mfp-close:after {
    transform: rotate(-45deg);
    top: 0; }

html {
  margin-right: 0; }

@media (max-width: 1440px) {
  html {
    font-size: 8.5px; } }

@media (max-width: 1000px) {
  html {
    font-size: 8px; } }

@media (max-width: 768px) {
  html {
    font-size: 7px; } }

.form-error {
  padding: 8px 4px 0;
  color: #e00000;
  font-size: 12px; }

/* roboto-regular - cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v20-cyrillic-ext_latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v20-cyrillic-ext_latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-cyrillic-ext_latin-regular.woff2") format("woff2"), url("../fonts/roboto-v20-cyrillic-ext_latin-regular.woff") format("woff"), url("../fonts/roboto-v20-cyrillic-ext_latin-regular.ttf") format("truetype"), url("../fonts/roboto-v20-cyrillic-ext_latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-500 - cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v20-cyrillic-ext_latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v20-cyrillic-ext_latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-cyrillic-ext_latin-500.woff2") format("woff2"), url("../fonts/roboto-v20-cyrillic-ext_latin-500.woff") format("woff"), url("../fonts/roboto-v20-cyrillic-ext_latin-500.ttf") format("truetype"), url("../fonts/roboto-v20-cyrillic-ext_latin-500.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-700 - cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v20-cyrillic-ext_latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/roboto-v20-cyrillic-ext_latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-cyrillic-ext_latin-700.woff2") format("woff2"), url("../fonts/roboto-v20-cyrillic-ext_latin-700.woff") format("woff"), url("../fonts/roboto-v20-cyrillic-ext_latin-700.ttf") format("truetype"), url("../fonts/roboto-v20-cyrillic-ext_latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* roboto-900 - cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/roboto-v20-cyrillic-ext_latin-900.eot");
  /* IE9 Compat Modes */
  src: local("Roboto Black"), local("Roboto-Black"), url("../fonts/roboto-v20-cyrillic-ext_latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v20-cyrillic-ext_latin-900.woff2") format("woff2"), url("../fonts/roboto-v20-cyrillic-ext_latin-900.woff") format("woff"), url("../fonts/roboto-v20-cyrillic-ext_latin-900.ttf") format("truetype"), url("../fonts/roboto-v20-cyrillic-ext_latin-900.svg#Roboto") format("svg");
  /* Legacy iOS */ }

.loader {
  position: fixed;
  display: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1; }

.lds-dual-ring {
  display: block;
  width: 64px;
  height: 64px; }
  .lds-dual-ring::after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #212324 transparent #212324 transparent;
    animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.logotype {
  width: 128px;
  max-width: 128px;
  margin-right: 10px;
  transition: all 0.2s ease-in-out 0s; }
  @media (max-width: 1000px) {
    .logotype {
      position: absolute;
      top: 10px; } }
  @media (max-width: 768px) {
    .logotype {
      width: 100px; } }
  .logotype:hover {
    transform: scale(1.1); }
  .logotype:active {
    transform: scale(1); }
  .logotype-pskov {
    width: 175px;
    max-width: 175px;
    margin-right: 0; }
    @media (max-width: 1000px) {
      .logotype-pskov {
        right: 10px;
        top: 50px; } }
    @media (max-width: 768px) {
      .logotype-pskov {
        width: 150px;
        max-width: 150px;
        top: 84px;
        right: 11%; } }
    @media (max-width: 480px) {
      .logotype-pskov {
        width: 100px;
        right: 10px;
        top: 96px; } }
  .logotype a {
    display: flex; }
    .logotype a img {
      width: 100%; }

.title-h2 {
  width: 100%;
  text-align: center;
  font-weight: 900;
  color: #1B77A1;
  font-size: 3.4rem;
  line-height: 4.8rem; }
  .title-h2--left {
    text-align: left; }
  .title-h2--white {
    color: #ffffff; }
  .title-h2 h2 {
    font-size: inherit;
    font-weight: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    line-height: inherit; }

.title-h1 {
  width: 100%;
  text-align: center;
  font-weight: 900;
  color: #1B77A1;
  font-size: 3.4rem;
  line-height: 4.8rem; }
  .title-h1--left {
    text-align: left; }
  .title-h1--white {
    color: #ffffff; }
  .title-h1 h1 {
    font-size: inherit;
    font-weight: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    line-height: inherit; }

.text {
  color: #212324;
  font-size: 1.5rem;
  line-height: 2.8rem;
  font-weight: 400; }
  .text--white {
    color: #ffffff; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

.breadcrumbs {
  background: #F8F8F8;
  padding-top: 1.4rem; }
  .breadcrumbs__wrapper {
    max-width: 1435px; }
  .breadcrumbs-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .breadcrumbs-list__item {
      padding-right: 2.6rem;
      margin-right: 1rem;
      position: relative;
      margin-bottom: 1.4rem; }
      .breadcrumbs-list__item::after {
        content: '';
        width: 1.6rem;
        height: 1px;
        background: #C2A271;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
      .breadcrumbs-list__item:last-child {
        margin-right: 0;
        padding-right: 0; }
        .breadcrumbs-list__item:last-child::after {
          display: none; }
    .breadcrumbs-list__link {
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: rgba(33, 35, 36, 0.3);
      transition: all 0.2s ease-in-out 0s; }
      .breadcrumbs-list__link:hover {
        color: #00a2d3; }
      .breadcrumbs-list__link:active {
        color: #1B77A1; }
    .breadcrumbs-list__active-page {
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #008EB9; }

.pagination {
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center; }
  @media (max-width: 600px) {
    .pagination {
      height: 45px; } }
  .pagination-prev, .pagination-next {
    position: relative;
    width: 50px;
    height: 100%;
    cursor: pointer; }
    .pagination-prev:before, .pagination-prev:after, .pagination-next:before, .pagination-next:after {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: #008EB9;
      left: 25%;
      transform: translateX(-50%); }
    .pagination-prev:before, .pagination-next:before {
      transform: rotate(45deg);
      top: 30px; }
      @media (max-width: 600px) {
        .pagination-prev:before, .pagination-next:before {
          top: 27px; } }
    .pagination-prev:after, .pagination-next:after {
      top: 20px;
      transform: rotate(-45deg); }
      @media (max-width: 600px) {
        .pagination-prev:after, .pagination-next:after {
          top: 17px; } }
  .pagination-next {
    transform: rotate(180deg); }
  .pagination-list {
    display: flex;
    box-sizing: border-box;
    border: 2px solid #008EB9;
    height: 100%; }
    .pagination-list__item.active + .pagination-list__item a:after {
      display: none; }
    .pagination-list__item.active a {
      color: #ffffff;
      background-color: #008EB9; }
      .pagination-list__item.active a:after {
        display: none; }
    .pagination-list__item:hover a {
      background-color: #008EB9;
      color: #ffffff;
      transition: all 0.2s ease-in-out 0s; }
      .pagination-list__item:hover a:after {
        opacity: 0; }
    .pagination-list__item:hover + .pagination-list__item a:after {
      opacity: 0;
      transition: all 0.2s ease-in-out 0s; }
    .pagination-list__item a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%;
      position: relative;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: #BCBDBD;
      transition: all 0.2s ease-in-out 0s; }
      @media (max-width: 600px) {
        .pagination-list__item a {
          width: 45px; } }
      .pagination-list__item a:after {
        content: '';
        width: 1px;
        height: 16px;
        position: absolute;
        background-color: #EAEAEA;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.2s ease-in-out 0s; }

.cookie-policy {
  position: fixed;
  width: 100%;
  z-index: 50;
  background-color: #008EB9; }
  .cookie-policy .wrapper {
    max-width: 1435px;
    padding: 30px 10px; }
  .cookie-policy-row {
    display: flex;
    justify-content: space-between; }
  .cookie-policy-text {
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: #ffffff;
    opacity: .8;
    width: 74.64387%; }
  .cookie-policy-button {
    width: 217px; }
    .cookie-policy-button button {
      padding: 10px 24px; }
      .cookie-policy-button button:after {
        top: calc(50% - 2px); }

.gas-station-map .ymaps-2-1-75-image-with-content-content {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  top: 4px !important;
  left: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  line-height: 16px !important;
  font-family: "Roboto", "Arial", "Helvetica CY", "Nimbus Sans L", sans-serif !important;
  color: rgba(33, 35, 36, 0.8); }

.gas-station-map .ymaps-2-1-75-balloon__content {
  margin-right: 55px !important;
  padding: 24px 0 26px 20px !important; }
  @media (max-width: 600px) {
    .gas-station-map .ymaps-2-1-75-balloon__content {
      padding: 14px 0 16px 10px !important; } }
  @media (max-width: 600px) {
    .gas-station-map .ymaps-2-1-75-balloon__content > ymaps {
      height: auto !important; } }
  .gas-station-map .ymaps-2-1-75-balloon__content .ymaps-2-1-75-balloon-content__header {
    padding-left: 41px;
    position: relative;
    font-size: 15px;
    line-height: 28px;
    color: #212324;
    margin-bottom: 8px; }
  .gas-station-map .ymaps-2-1-75-balloon__content .ymaps-2-1-75-balloon-content__footer {
    height: auto; }
  .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-phone {
    padding-left: 41px;
    background-image: url("../images/phone.svg");
    background-repeat: no-repeat;
    background-position: 4px 5px;
    margin-bottom: 5px; }
    .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-phone-item {
      font-weight: 700;
      color: rgba(33, 35, 36, 0.8);
      font-size: 14px;
      line-height: 24px; }
  .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-info {
    padding-left: 41px;
    background-image: url("../images/address.svg");
    background-repeat: no-repeat;
    background-position: 3px 3px;
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400; }
    .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-info address {
      color: rgba(33, 35, 36, 0.8); }
    .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-info-gps {
      display: block;
      color: #BCBDBD;
      opacity: .8; }
      .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-info-gps--coordinates {
        color: #1B77A1; }
    .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-info-time {
      display: block;
      color: #BCBDBD;
      opacity: .8; }
      .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-info-time .time {
        color: #212324; }
  .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-option {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-option-image {
      margin-right: 15px;
      display: flex;
      align-items: center; }
      @media (max-width: 600px) {
        .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-option-image {
          height: 30px; } }
      .gas-station-map .ymaps-2-1-75-balloon__content .gasoline-option-image:last-child {
        margin-right: 0; }

.gas-station-map .ymaps-2-1-75-balloon__close {
  top: 20px;
  right: 20px; }
  .gas-station-map .ymaps-2-1-75-balloon__close-button {
    width: 24px;
    height: 24px;
    background: url("../images/close-baloon.svg") no-repeat; }

.map {
  padding-top: 3rem; }
  .map__wrapper {
    max-width: 1435px; }
  .map__title {
    max-width: 932px;
    margin-bottom: 3rem; }
    @media (max-width: 768px) {
      .map__title {
        text-align: center; } }
  .map__subtitle {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 3.2rem;
    color: #212324;
    margin-bottom: 1.5rem; }
    @media (max-width: 768px) {
      .map__subtitle {
        text-align: center; } }
  .map-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 35px; }
    @media (max-width: 768px) {
      .map-form {
        flex-direction: column;
        margin-bottom: 0; } }
    .map-form__title {
      width: 100%;
      color: #9F9F9F;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 1.5rem; }
      @media (max-width: 768px) {
        .map-form__title {
          font-size: 10px;
          text-align: center; } }
    .map-form__labels {
      display: flex;
      flex-wrap: wrap;
      margin-right: 30px;
      width: 79.85866%; }
      @media (max-width: 768px) {
        .map-form__labels {
          width: 100%;
          margin-right: 0;
          justify-content: center; } }
      .map-form__labels label input {
        display: none; }
      .map-form__labels label span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 80px;
        border: 1px solid #EAEAEA;
        box-sizing: border-box;
        cursor: pointer;
        transition: all 0.2s ease-in-out 0s; }
        @media (max-width: 600px) {
          .map-form__labels label span {
            height: 70px;
            width: 70px; } }
        .map-form__labels label span svg path {
          transition: all 0.2s ease-in-out 0s; }
        .map-form__labels label span:hover {
          background-color: #C2A271;
          border-color: #C2A271;
          transition: all 0.2s ease-in-out 0s; }
          .map-form__labels label span:hover svg path {
            fill: #ffffff;
            transition: all 0.2s ease-in-out 0s; }
      .map-form__labels label input:checked + span {
        background-color: #C2A271;
        border-color: #C2A271;
        transition: all 0.2s ease-in-out 0s; }
        .map-form__labels label input:checked + span svg path {
          fill: #ffffff;
          transition: all 0.2s ease-in-out 0s; }
    .map-form__reset {
      display: flex;
      align-items: center; }
      @media (max-width: 768px) {
        .map-form__reset {
          justify-content: center;
          height: 80px; } }
      .map-form__reset button {
        border: 0;
        outline: 0;
        background-color: transparent; }
        .map-form__reset button span {
          color: #008EB9;
          opacity: 0.8;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-transform: uppercase; }
  .map-table {
    margin-bottom: 6rem; }
    .map-table--header {
      display: flex;
      padding-bottom: 1.1rem; }
      .map-table--header span {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: #212324;
        opacity: .3;
        letter-spacing: 1px;
        text-transform: uppercase; }
    .map-table--line {
      display: flex;
      align-items: flex-start;
      padding: 2rem 0; }
      @media (max-width: 1000px) {
        .map-table--line {
          flex-wrap: wrap; } }
      .map-table--line:nth-child(even) {
        background-color: rgba(234, 234, 234, 0.3); }
      .map-table--line .map-table--number {
        box-sizing: border-box;
        padding-left: 2rem; }
        @media (max-width: 1000px) {
          .map-table--line .map-table--number {
            padding: 10px;
            text-align: left; } }
        .map-table--line .map-table--number a {
          color: #212324;
          font-weight: 500;
          font-size: 1.8rem;
          line-height: 3.2rem;
          text-transform: uppercase; }
      @media (max-width: 1000px) {
        .map-table--line .map-table--locations {
          padding-right: 10px; } }
      .map-table--line .map-table--locations span {
        color: rgba(33, 35, 36, 0.8);
        font-size: 1.5rem;
        line-height: 2.8rem; }
      .map-table--line .map-table--options {
        box-sizing: border-box;
        padding-left: 4.8rem;
        padding-right: 2rem;
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        align-items: center; }
        @media (max-width: 1000px) {
          .map-table--line .map-table--options {
            margin-top: 15px;
            padding: 0 10px; } }
        .map-table--line .map-table--options .icon {
          margin-right: 2rem;
          padding: .5rem 0;
          display: flex;
          align-items: center; }
          @media (max-width: 768px) {
            .map-table--line .map-table--options .icon {
              max-width: 30px; } }
    .map-table--number {
      width: 9.96441%; }
      @media (max-width: 1000px) {
        .map-table--number {
          width: 25%; } }
    .map-table--locations {
      width: 21.35231%; }
      @media (max-width: 1000px) {
        .map-table--locations {
          width: 75%; } }
    .map-table--options {
      width: 68.68327%; }
      @media (max-width: 1000px) {
        .map-table--options {
          width: 100%; } }
  .map-section {
    position: relative; }
  .map .yandex-map {
    height: 500px;
    margin-bottom: 6rem;
    overflow: hidden; }
    .map .yandex-map img[alt="Какая карта будет"] {
      width: 100%;
      height: 100%;
      min-width: 1920px; }
  .map .empty-map {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(248, 248, 248, 0.9); }
    .map .empty-map.active {
      display: flex; }
    .map .empty-map span {
      color: #212324;
      font-size: 4rem;
      line-height: 5rem;
      font-weight: 500; }

.text .wrapper {
  max-width: 1435px;
  padding: 0 10px;
  padding-top: 30px; }

.text-block section {
  margin-bottom: 3rem; }

.text-block h2 {
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 3.5rem;
  color: #212324;
  margin-bottom: 1.5rem; }

.text-block p {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.8rem;
  color: rgba(33, 35, 36, 0.8);
  margin-bottom: 1.5rem; }

.text-block img {
  max-width: 1168px;
  display: block;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  width: 100%; }

.text-block figure {
  max-width: 1168px;
  margin: 0 auto;
  margin-bottom: 3rem; }
  .text-block figure figcaption {
    color: rgba(188, 189, 189, 0.8);
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 400; }

.text-block h3 {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.2rem;
  margin-bottom: 1.5rem;
  color: rgba(33, 35, 36, 0.8); }

.text-block ul {
  margin-bottom: 1.5rem; }
  .text-block ul li {
    padding-left: 3rem;
    margin-bottom: 1.5rem;
    position: relative;
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: rgba(33, 35, 36, 0.8); }
    .text-block ul li:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #008EB9;
      position: absolute;
      left: .2rem;
      top: 1.2rem; }

.text-block ol {
  margin-bottom: 1.5rem;
  counter-reset: list; }
  .text-block ol li {
    padding-left: 3rem;
    margin-bottom: 1.5rem;
    position: relative;
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: rgba(33, 35, 36, 0.8); }
    .text-block ol li:before {
      counter-increment: list;
      content: counter(list);
      color: rgba(27, 119, 161, 0.8);
      font-weight: 700;
      position: absolute;
      left: 0;
      padding-right: .9rem;
      background-image: url("../images/list-rect.png");
      background-repeat: no-repeat;
      background-position: right center; }

.text-block table {
  margin: 0 auto;
  overflow-x: auto;
  max-width: 100%;
  border-collapse: collapse;
  display: flex;
  margin-bottom: 3rem;
  border: 0;
  border-spacing: 0; }
  .text-block table th {
    background-color: #008EB9;
    color: #ffffff;
    padding: .8rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: left;
    min-width: 150px; }
  .text-block table td {
    color: rgba(33, 35, 36, 0.8);
    font-size: 1.5rem;
    line-height: 2.8rem;
    padding: .8rem;
    min-width: 150px; }
  .text-block table tr:nth-child(even) {
    background-color: #EAEAEA; }

.text-video {
  position: relative;
  max-width: 1168px;
  margin: 0 auto;
  margin-bottom: 6rem; }
  .text-video .video-img {
    position: absolute;
    z-index: 2;
    cursor: pointer; }
    .text-video .video-img img {
      margin-bottom: 0; }
    .text-video .video-img:after {
      content: '';
      display: block;
      position: absolute;
      width: 70px;
      height: 70px;
      background-color: #008EB9;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      background-image: url("../images/play.svg");
      background-repeat: no-repeat;
      background-position: center;
      transition: all 0.2s ease-in-out 0s; }
    .text-video .video-img:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(33, 35, 36, 0.5), rgba(33, 35, 36, 0.5));
      opacity: 0;
      transition: all 0.2s ease-in-out 0s; }
    .text-video .video-img:hover:before {
      opacity: 1;
      transition: all 0.2s ease-in-out 0s; }
    .text-video .video-img:hover:after {
      border-radius: 50%; }
  .text-video-wrapper {
    padding-bottom: 56.25%;
    position: relative;
    width: 100%; }
  .text-video .video-frame {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: auto;
    display: block;
    max-width: 1168px; }

.text-slider {
  overflow: hidden;
  position: relative;
  max-width: 1168px;
  margin: 0 auto; }
  .text-slider .swiper-slide-inner {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    height: 0; }
    .text-slider .swiper-slide-inner img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      max-width: 100%;
      max-height: 100%;
      margin-bottom: 0; }
  .text-slider-nav {
    position: relative;
    top: -22px;
    margin: 0 auto;
    padding: 0 119px;
    overflow: hidden; }
    @media (max-width: 600px) {
      .text-slider-nav {
        padding: 0 58px; } }
    @media (max-width: 480px) {
      .text-slider-nav {
        padding: 0 30px;
        top: -10px; } }
    .text-slider-nav .wrapper {
      width: 100%; }
    .text-slider-nav .swiper-slide {
      max-width: 218px;
      opacity: 0;
      transition: opacity .5s; }
      .text-slider-nav .swiper-slide-visible {
        opacity: 1;
        transition: opacity .5s; }
        .text-slider-nav .swiper-slide-visible > div {
          position: relative; }
          .text-slider-nav .swiper-slide-visible > div:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(33, 35, 36, 0.5), rgba(33, 35, 36, 0.5)); }
      .text-slider-nav .swiper-slide-thumb-active > div {
        position: relative; }
        .text-slider-nav .swiper-slide-thumb-active > div:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(0deg, rgba(27, 119, 161, 0.42), rgba(27, 119, 161, 0.42)); }
  .text-slider .swiper-button-prev, .text-slider .swiper-button-next {
    background-image: none;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    border: 2px solid #008EB9;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0; }
    @media (max-width: 480px) {
      .text-slider .swiper-button-prev, .text-slider .swiper-button-next {
        width: 25px;
        height: 25px; } }
  .text-slider .swiper-button-next {
    right: 39px; }
    @media (max-width: 600px) {
      .text-slider .swiper-button-next {
        right: 8px; } }
    @media (max-width: 480px) {
      .text-slider .swiper-button-next {
        right: 0;
        transform: rotate(180deg);
        top: 60%; } }
    @media (max-width: 400px) {
      .text-slider .swiper-button-next {
        top: 65%; } }
    .text-slider .swiper-button-next:before {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 12px;
      background-color: #1B77A1;
      transform: rotate(-45deg);
      bottom: 13px; }
    .text-slider .swiper-button-next:after {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 12px;
      background-color: #1B77A1;
      transform: rotate(45deg);
      top: 13px; }
  .text-slider .swiper-button-prev {
    left: 39px; }
    @media (max-width: 600px) {
      .text-slider .swiper-button-prev {
        left: 8px; } }
    @media (max-width: 480px) {
      .text-slider .swiper-button-prev {
        left: 0;
        transform: rotate(180deg);
        top: 60%; } }
    @media (max-width: 400px) {
      .text-slider .swiper-button-prev {
        top: 65%; } }
    .text-slider .swiper-button-prev:before {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 12px;
      background-color: #1B77A1;
      transform: rotate(-45deg);
      top: 13px; }
    .text-slider .swiper-button-prev:after {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 12px;
      background-color: #1B77A1;
      transform: rotate(45deg);
      bottom: 13px; }

.request-page .wrapper {
  max-width: 1435px;
  padding: 0 10px;
  padding-top: 30px;
  padding-bottom: 60px; }
  @media (max-width: 1000px) {
    .request-page .wrapper {
      padding-bottom: 50px; } }
  @media (max-width: 768px) {
    .request-page .wrapper {
      padding-bottom: 40px;
      padding-top: 25px; } }
  @media (max-width: 600px) {
    .request-page .wrapper {
      padding-bottom: 30px;
      padding-top: 20px; } }

.poll .wrapper {
  max-width: 1435px;
  padding-top: 30px;
  padding-bottom: 60px; }
  @media (max-width: 768px) {
    .poll .wrapper {
      padding-top: 25px;
      padding-bottom: 50px; } }
  @media (max-width: 600px) {
    .poll .wrapper {
      padding-top: 20px;
      padding-bottom: 40px; } }

.poll h2 {
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 3.5rem;
  color: #212324;
  margin-bottom: 1.5rem; }

.poll-voting--advantages {
  margin-bottom: 3rem; }
  .poll-voting--advantages span {
    display: block;
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: rgba(33, 35, 36, 0.8);
    margin-bottom: 1.5rem; }
  .poll-voting--advantages ul li {
    padding-left: 3rem;
    margin-bottom: 1.5rem;
    position: relative;
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: rgba(33, 35, 36, 0.8); }
    .poll-voting--advantages ul li:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #008EB9;
      position: absolute;
      left: .2rem;
      top: 1rem; }

.poll-voting--choose {
  padding-bottom: 30px;
  border-bottom: 1px solid #EAEAEA;
  margin-bottom: 30px; }
  @media (max-width: 768px) {
    .poll-voting--choose {
      margin-bottom: 25px;
      padding-bottom: 25px; } }
  @media (max-width: 600px) {
    .poll-voting--choose {
      margin-bottom: 20px;
      padding-bottom: 20px; } }
  .poll-voting--choose__wrapper {
    background-color: #EAEAEA;
    padding: 29px 26px 25px;
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .poll-voting--choose__wrapper {
        padding: 24px 21px 20px; } }
    @media (max-width: 600px) {
      .poll-voting--choose__wrapper {
        padding: 19px 20px 15px; } }
    .poll-voting--choose__wrapper:last-child {
      margin-bottom: 0; }
  .poll-voting--choose h3 {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 3.2rem;
    margin-bottom: 3rem;
    color: rgba(33, 35, 36, 0.8); }
  .poll-voting--choose label {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    cursor: pointer;
    min-height: 40px; }
    .poll-voting--choose label:last-child {
      margin-bottom: 0; }
    @media (max-width: 768px) {
      .poll-voting--choose label {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        min-height: auto; } }
    .poll-voting--choose label:hover .radio-btn:before {
      opacity: 1;
      transition: all 0.2s ease-in-out 0s; }
    .poll-voting--choose label:hover .radio-btn .choose {
      border: 1px solid #008EB9;
      transition: all 0.2s ease-in-out 0s; }
    .poll-voting--choose label input {
      display: none; }
      .poll-voting--choose label input:checked + .radio-btn:before {
        opacity: 1; }
      .poll-voting--choose label input:checked + .radio-btn .choose {
        border: 1px solid #008EB9; }
        .poll-voting--choose label input:checked + .radio-btn .choose:before {
          content: '';
          display: block;
          width: 2px;
          height: 5px;
          border-radius: 1px;
          background-color: #ffffff;
          position: absolute;
          transform: rotate(-43deg);
          left: 7px;
          top: 10px; }
        .poll-voting--choose label input:checked + .radio-btn .choose:after {
          content: '';
          display: block;
          width: 2px;
          height: 10px;
          border-radius: 1px;
          background-color: #ffffff;
          position: absolute;
          transform: rotate(43deg);
          left: 11px;
          top: 6px; }
      .poll-voting--choose label input:checked ~ .percent .percent-some {
        background-color: #008EB9; }
    .poll-voting--choose label > .radio-btn {
      font-size: 1.5rem;
      line-height: 24px;
      min-height: 24px;
      color: rgba(33, 35, 36, 0.8);
      padding-left: 39px;
      position: relative;
      width: 20.54292%;
      margin-right: 10px; }
      @media (max-width: 768px) {
        .poll-voting--choose label > .radio-btn {
          width: 100%;
          margin-right: 0; } }
      .poll-voting--choose label > .radio-btn:before {
        content: '';
        display: block;
        position: absolute;
        left: 3px;
        top: 3px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #008EB9;
        opacity: 0;
        transition: all 0.2s ease-in-out 0s; }
      .poll-voting--choose label > .radio-btn .choose {
        display: block;
        left: 0;
        position: absolute;
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border: 2px solid #008EB9;
        border-radius: 50%;
        transition: all 0.2s ease-in-out 0s; }
    .poll-voting--choose label .percent {
      display: flex;
      opacity: 0;
      width: 78.0631%;
      transition: all .5s; }
      @media (max-width: 768px) {
        .poll-voting--choose label .percent {
          width: 100%;
          margin-top: 15px; } }
      .poll-voting--choose label .percent-some {
        display: block;
        background-color: #C2A271;
        height: 40px;
        transition: all .5s;
        width: 0; }
      .poll-voting--choose label .percent-description {
        display: flex;
        align-items: center;
        margin-left: 3rem; }
        @media (max-width: 768px) {
          .poll-voting--choose label .percent-description {
            flex-direction: column;
            justify-content: center; } }
        .poll-voting--choose label .percent-description-left {
          font-size: 2.6rem;
          line-height: 3.5rem;
          color: #212324;
          font-weight: 700; }
        .poll-voting--choose label .percent-description-right {
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: rgba(33, 35, 36, 0.3);
          margin-left: 1.5rem;
          text-align: center; }
          @media (max-width: 768px) {
            .poll-voting--choose label .percent-description-right {
              margin-left: 0; } }

.poll-archive-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4rem;
  margin-top: 3rem; }

.poll-archive-item {
  display: block;
  padding: 30px;
  width: 32.29682%;
  margin-right: 1.55477%;
  box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
  transition: all 0.2s ease-in-out 0s;
  margin-bottom: 20px; }
  @media (max-width: 1000px) {
    .poll-archive-item {
      width: calc(50% - 10px);
      margin-right: 20px; }
      .poll-archive-item:nth-child(2n) {
        margin-right: 0; } }
  @media (max-width: 768px) {
    .poll-archive-item {
      padding: 21px; } }
  @media (max-width: 600px) {
    .poll-archive-item {
      width: 100%;
      margin-right: 0;
      padding: 20px; } }
  .poll-archive-item:hover {
    box-shadow: 8px 10px 20px rgba(33, 35, 36, 0.15);
    transition: all 0.2s ease-in-out 0s; }
  .poll-archive-item:nth-child(3n) {
    margin-right: 0; }
  .poll-archive-item--header {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 3.2rem;
    color: #212324;
    display: block;
    margin-bottom: 10px; }
    @media (max-width: 600px) {
      .poll-archive-item--header {
        margin-bottom: 5px; } }
  .poll-archive-item--date {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    @media (max-width: 600px) {
      .poll-archive-item--date {
        margin-bottom: 15px; } }
    .poll-archive-item--date svg {
      margin-right: 10px; }
    .poll-archive-item--date span {
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: rgba(33, 35, 36, 0.3); }
  .poll-archive-item--value {
    margin-bottom: 21px; }
    @media (max-width: 600px) {
      .poll-archive-item--value {
        margin-bottom: 15px; } }
    .poll-archive-item--value .number {
      font-size: 7.2rem;
      line-height: 8rem;
      font-weight: 900;
      text-align: center;
      display: block;
      margin-bottom: 5px; }
    .poll-archive-item--value .description {
      display: block;
      font-size: 1.5rem;
      line-height: 2.2rem;
      text-align: center;
      color: #212324;
      opacity: .8; }
  .poll-archive-item--votebar {
    height: 36px;
    background-color: #C2A271;
    margin-bottom: 30px; }
    @media (max-width: 600px) {
      .poll-archive-item--votebar {
        margin-bottom: 25px; } }
    .poll-archive-item--votebar .most {
      background-color: #008EB9;
      height: 100%; }
  .poll-archive-item--details {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;
    color: #008EB9;
    padding-right: 27px;
    position: relative; }
    .poll-archive-item--details:after {
      content: '';
      display: block;
      position: absolute;
      top: 3px;
      right: 0;
      width: 14px;
      height: 8px;
      background-image: url("../images/details-arrow.svg"); }

.error .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 10px; }
  @media (max-width: 1000px) {
    .error .wrapper {
      padding: 50px 10px; } }
  @media (max-width: 768px) {
    .error .wrapper {
      padding: 40px 10px; } }
  @media (max-width: 600px) {
    .error .wrapper {
      padding: 30px 10px; } }

.error .header-block {
  text-align: center; }
  @media (max-width: 600px) {
    .error .header-block h1 {
      font-size: 3.2rem; } }

.error-image {
  margin-bottom: 5.8rem; }
  .error-image svg {
    transform-origin: 50% 50%;
    width: 100%;
    height: auto; }
  .error-image .arrow {
    transform-box: fill-box;
    transform-origin: 100% 80%;
    animation-name: beginning-error, error;
    animation-duration: 2s, 1.5s;
    animation-delay: 0s, 2s;
    animation-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1), cubic-bezier 0.8, 1.5, 0.2, -1;
    animation-iteration-count: 1, infinite; }

.error-description {
  font-size: 1.5rem;
  line-height: 2.8rem;
  text-align: center;
  color: rgba(33, 35, 36, 0.8);
  max-width: 450px;
  margin-bottom: 6rem; }
  .error-description a {
    font-size: 1.4rem;
    line-height: 2.4rem;
    font-weight: 700;
    color: rgba(27, 119, 161, 0.8);
    text-transform: uppercase; }

.error .btn {
  padding: 10px 24px; }
  .error .btn:after {
    top: calc(50% - 2px); }

@keyframes beginning-error {
  85% {
    transform: rotate(135deg);
    animation-timing-function: ease-in; }
  100% {
    transform: rotate(-10deg); } }

@keyframes error {
  0% {
    transform: rotate(-10deg); }
  90% {
    transform: rotate(10deg); }
  100% {
    transform: rotate(-10deg); } }

.guest .wrapper {
  max-width: 1435px;
  padding: 30px 10px; }
  @media (max-width: 768px) {
    .guest .wrapper {
      padding: 20px 10px; } }

.guest .header-block {
  margin-bottom: 30px; }
  @media (max-width: 768px) {
    .guest .header-block {
      margin-bottom: 20px; } }

.guest-section {
  display: flex; }
  @media (max-width: 600px) {
    .guest-section {
      flex-wrap: wrap; } }
  .guest-section-left {
    width: 74.59075%; }
    @media (max-width: 600px) {
      .guest-section-left {
        width: 100%; } }
  .guest-section-right {
    margin-left: 20px;
    width: 337px; }
    @media (max-width: 768px) {
      .guest-section-right {
        width: 250px; } }
    @media (max-width: 600px) {
      .guest-section-right {
        width: 100%;
        margin-left: 0; } }
  .guest-section-message {
    padding: 30px;
    background-color: #f9f9f9;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid transparent;
    margin-bottom: 20px;
    transition: all 0.2s ease-in-out 0s; }
    @media (max-width: 768px) {
      .guest-section-message {
        padding: 20px; } }
    .guest-section-message.background {
      border: 1px solid #BCBDBD;
      background-color: transparent;
      transition: all 0.2s ease-in-out 0s; }
    .guest-section-message--header {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 3.2rem;
      color: rgba(33, 35, 36, 0.8);
      max-width: 900px;
      margin-bottom: 10px;
      overflow: hidden; }
    .guest-section-message--info {
      overflow: hidden; }
      .guest-section-message--info span {
        display: inline-flex;
        align-items: center;
        position: relative;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #BCBDBD; }
        .guest-section-message--info span svg {
          margin-right: 10px; }
        .guest-section-message--info span.date {
          margin-left: 30px; }
    .guest-section-message--answer {
      max-width: 900px;
      padding: 30px 0 15px;
      display: none; }
      @media (max-width: 768px) {
        .guest-section-message--answer {
          padding: 20px 0 10px; } }
      @media (max-width: 600px) {
        .guest-section-message--answer {
          padding: 10px 0 10px; } }
      .guest-section-message--answer.active + span span {
        text-indent: 0;
        transition: all 0.2s ease-in-out 0s; }
      .guest-section-message--answer .answer {
        display: inline-block;
        color: rgba(33, 35, 36, 0.8);
        font-size: 1.5rem;
        line-height: 2.8rem;
        padding-left: 28px;
        position: relative; }
        .guest-section-message--answer .answer:before {
          content: '';
          display: block;
          width: 13px;
          height: 2px;
          background-color: #008EB9;
          position: absolute;
          left: 0;
          top: 1.2rem; }
    .guest-section-message--decision {
      margin-top: 15px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2.4rem;
      text-transform: uppercase;
      color: #008EB9; }
      @media (max-width: 768px) {
        .guest-section-message--decision {
          margin-top: 10px; } }
      .guest-section-message--decision:hover {
        color: #1B77A1; }
        .guest-section-message--decision:hover svg path {
          fill: #1B77A1; }
          .guest-section-message--decision:hover svg path:nth-child(2) {
            animation-name: messageone;
            animation-duration: 2s;
            animation-timing-function: steps(1, end);
            animation-iteration-count: infinite; }
          .guest-section-message--decision:hover svg path:nth-child(3) {
            animation-name: messagetwo;
            animation-duration: 2s;
            animation-timing-function: steps(1, end);
            animation-iteration-count: infinite; }
          .guest-section-message--decision:hover svg path:nth-child(4) {
            animation-name: messagethree;
            animation-duration: 2s;
            animation-timing-function: steps(1, end);
            animation-iteration-count: infinite; }
      .guest-section-message--decision svg {
        margin-right: 15px; }
      .guest-section-message--decision span {
        text-indent: -6rem;
        overflow: hidden;
        transition: all 0.2s ease-in-out 0s; }
  .guest-section-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
    background-image: url("../images/form-bg.svg");
    background-repeat: no-repeat;
    background-position: 88px 88%; }
    @media (max-width: 768px) {
      .guest-section-form {
        padding: 20px; } }
    .guest-section-form h2 {
      font-size: 2.6rem;
      line-height: 3.5rem;
      color: #212324;
      font-weight: 700;
      text-align: center;
      margin-bottom: 30px; }
      @media (max-width: 768px) {
        .guest-section-form h2 {
          margin-bottom: 20px; } }
    .guest-section-form .form-input {
      width: 100%; }
    .guest-section-form textarea.form-input {
      resize: none;
      height: 200px;
      margin-bottom: 10px; }

@keyframes messageone {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  66% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes messagetwo {
  0% {
    opacity: 1; }
  25% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes messagethree {
  0% {
    opacity: 1; }
  33% {
    opacity: 1; }
  50% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.contacts .wrapper {
  max-width: 1435px; }

.contacts h2 {
  font-size: 2.6rem;
  line-height: 3.5rem;
  font-weight: 700;
  color: #212324;
  text-align: center; }

.contacts .header-block {
  margin: 30px 0; }
  @media (max-width: 768px) {
    .contacts .header-block {
      margin: 20px 0; } }

.contacts-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
  position: relative;
  z-index: 1; }
  @media (max-width: 768px) {
    .contacts-main {
      flex-wrap: wrap;
      margin-bottom: 50px; } }
  @media (max-width: 600px) {
    .contacts-main {
      margin-bottom: 40px; } }
  .contacts-main-left {
    max-width: 300px;
    width: 100%; }
    @media (max-width: 1000px) {
      .contacts-main-left {
        max-width: 240px; } }
    @media (max-width: 768px) {
      .contacts-main-left {
        max-width: none;
        display: flex;
        justify-content: space-between; } }
    @media (max-width: 600px) {
      .contacts-main-left {
        flex-wrap: wrap; } }
  .contacts-main-item {
    margin-bottom: 30px; }
    @media (max-width: 768px) {
      .contacts-main-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px; }
        .contacts-main-item:nth-child(3n) {
          margin-right: 0; } }
    @media (max-width: 600px) {
      .contacts-main-item {
        width: calc(50% - 10px); }
        .contacts-main-item:nth-child(3n) {
          margin-right: 20px; }
        .contacts-main-item:nth-child(2n) {
          margin-right: 0; } }
    .contacts-main-item.phone .contacts-main-item--header:before {
      background-image: url("../images/phone.svg"); }
    .contacts-main-item.phone a {
      display: block;
      font-size: 2.6rem;
      line-height: 3.5rem;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.8); }
      @media (max-width: 375px) {
        .contacts-main-item.phone a {
          font-size: 2rem;
          line-height: 3.2rem; } }
    .contacts-main-item.address .contacts-main-item--header:before {
      background-image: url("../images/address.svg");
      width: 18px;
      height: 18px; }
    .contacts-main-item.email .contacts-main-item--header:before {
      background-image: url("../images/mail.svg");
      width: 18px;
      height: 14px; }
    .contacts-main-item.email a {
      font-size: 1.5rem;
      line-height: 2.8rem;
      color: #008EB9;
      border-bottom: 1px dotted #008EB9; }
    .contacts-main-item--header {
      display: block;
      padding-left: 39px;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: rgba(33, 35, 36, 0.3);
      position: relative;
      margin-bottom: 15px;
      letter-spacing: 1px;
      text-transform: uppercase; }
      .contacts-main-item--header:before {
        content: '';
        display: flex;
        position: absolute;
        left: 0;
        width: 16px;
        height: 16px;
        top: 50%;
        transform: translateY(-50%); }
    .contacts-main-item address {
      font-size: 1.5rem;
      line-height: 2.8rem;
      color: #242424; }
  .contacts-main .yandex-map {
    height: 504px;
    max-width: 1069px;
    width: 100%;
    padding-right: 20px;
    padding-bottom: 20px;
    position: relative; }
    @media (max-width: 768px) {
      .contacts-main .yandex-map {
        height: 494px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px; } }
    .contacts-main .yandex-map:after {
      content: '';
      display: block;
      position: absolute;
      width: calc(100%  - 20px);
      height: calc(100% - 20px);
      left: 20px;
      top: 20px;
      background-color: #EAEAEA;
      z-index: -1; }
      @media (max-width: 768px) {
        .contacts-main .yandex-map:after {
          width: 100%;
          height: calc(100% + 10px);
          left: 0;
          top: -10px; } }

.contacts-employees {
  position: relative;
  z-index: 0; }
  .contacts-employees:before {
    content: '';
    display: block;
    height: 300px;
    width: 100%;
    background-color: #f9f9f9;
    position: absolute;
    top: -90px;
    z-index: -1; }
  .contacts-employees h2 {
    margin-bottom: 60px; }
    @media (max-width: 768px) {
      .contacts-employees h2 {
        margin-bottom: 50px; } }
    @media (max-width: 600px) {
      .contacts-employees h2 {
        margin-bottom: 40px; } }
  .contacts-employees-section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 60px; }
    @media (max-width: 768px) {
      .contacts-employees-section {
        margin-bottom: 50px; } }
    @media (max-width: 600px) {
      .contacts-employees-section {
        margin-bottom: 40px; } }
    .contacts-employees-section--item {
      padding: 30px;
      box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      width: calc(50% - 10px);
      margin-right: 20px;
      margin-bottom: 20px; }
      @media (max-width: 768px) {
        .contacts-employees-section--item {
          padding: 20px; } }
      @media (max-width: 600px) {
        .contacts-employees-section--item {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px; } }
      .contacts-employees-section--item:nth-child(2n) {
        margin-right: 0; }
      .contacts-employees-section--item__header {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 2.6rem;
        line-height: 3.5rem;
        font-weight: 700;
        color: rgba(33, 35, 36, 0.8); }
      .contacts-employees-section--item__subheader {
        display: inline-block;
        margin-bottom: 15px;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: rgba(33, 35, 36, 0.3); }
      .contacts-employees-section--item__phone {
        display: inline-block;
        padding-left: 39px;
        position: relative;
        font-size: 1.5rem;
        line-height: 2.8rem;
        font-weight: 700;
        color: #212324; }
        .contacts-employees-section--item__phone:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 16px;
          height: 16px;
          background-image: url("../images/phone.svg");
          top: 50%;
          transform: translateY(-50%); }

.contacts-feedback {
  background-color: #f9f9f9; }
  .contacts-feedback .wrapper {
    padding: 30px 10px 60px;
    max-width: 712px; }
    @media (max-width: 768px) {
      .contacts-feedback .wrapper {
        padding: 20px 10px 50px; } }
    @media (max-width: 600px) {
      .contacts-feedback .wrapper {
        padding: 20px 10px 40px; } }
  .contacts-feedback h2 {
    font-size: 2.6rem;
    line-height: 3.5rem;
    font-weight: 700;
    color: #212324;
    text-align: center;
    margin-bottom: 60px; }
    @media (max-width: 768px) {
      .contacts-feedback h2 {
        margin-bottom: 40px; } }
    @media (max-width: 600px) {
      .contacts-feedback h2 {
        margin-bottom: 30px; } }
  .contacts-feedback-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .contacts-feedback-form--left {
      display: flex;
      flex-direction: column;
      width: calc(50% - 10px);
      margin-right: 20px; }
      @media (max-width: 600px) {
        .contacts-feedback-form--left {
          width: 100%;
          margin-right: 0;
          margin-bottom: 15px; } }
      .contacts-feedback-form--left input:last-child {
        margin-bottom: 0; }
    .contacts-feedback-form--right {
      width: calc(50% - 10px); }
      @media (max-width: 600px) {
        .contacts-feedback-form--right {
          width: 100%; } }
      .contacts-feedback-form--right textarea {
        width: 100%;
        resize: none;
        max-height: 115px;
        margin-bottom: 0; }
        @media (max-width: 1440px) {
          .contacts-feedback-form--right textarea {
            max-height: 104px; } }
        @media (max-width: 768px) {
          .contacts-feedback-form--right textarea {
            max-height: 97px; } }
    .contacts-feedback-form .form-checkbox {
      width: 100%;
      margin-bottom: 30px;
      margin-top: 30px; }
      @media (max-width: 768px) {
        .contacts-feedback-form .form-checkbox {
          margin-bottom: 25px;
          margin-top: 25px; } }
      @media (max-width: 768px) {
        .contacts-feedback-form .form-checkbox {
          margin-bottom: 20px;
          margin-top: 20px;
          text-align: center; } }
      @media (max-width: 430px) {
        .contacts-feedback-form .form-checkbox {
          text-align: left; } }
      .contacts-feedback-form .form-checkbox-span {
        line-height: 2.4rem; }

.landing-page .main-slider__content {
  padding: 60px;
  height: 365px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-top: 160px; }
  @media (max-width: 1440px) {
    .landing-page .main-slider__content {
      margin-right: 23.6rem;
      height: 345px; } }
  @media (max-width: 1000px) {
    .landing-page .main-slider__content {
      margin: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%); } }
  @media (max-width: 768px) {
    .landing-page .main-slider__content {
      padding-top: 120px;
      height: 315px;
      top: 45%; } }
  @media (max-width: 600px) {
    .landing-page .main-slider__content {
      padding: 10px;
      padding-top: 90px;
      padding-bottom: 20px;
      top: 43%; } }
  .landing-page .main-slider__content:before {
    content: '';
    display: block;
    height: 130px;
    width: 677px;
    position: absolute;
    top: 0;
    left: -36px;
    background: linear-gradient(83.94deg, #C2A271 28.21%, #EDC382 60.91%);
    transform: skew(-29deg); }
    @media (max-width: 1440px) {
      .landing-page .main-slider__content:before {
        width: 600px;
        height: 115px; } }
    @media (max-width: 768px) {
      .landing-page .main-slider__content:before {
        width: 100%;
        background: linear-gradient(87.5deg, #008EB9 -9.47%, #1B77A1 59.79%);
        transform: none;
        left: 0; } }
    @media (max-width: 600px) {
      .landing-page .main-slider__content:before {
        height: 83px; } }
  .landing-page .main-slider__content:after {
    content: '';
    display: block;
    width: 315px;
    height: 200px;
    position: absolute;
    top: -40px;
    right: -45px;
    background: linear-gradient(87.5deg, #008EB9 -9.47%, #1B77A1 59.79%);
    transform: skew(-29deg); }
    @media (max-width: 1440px) {
      .landing-page .main-slider__content:after {
        width: 285px;
        height: 180px;
        top: -36px; } }
    @media (max-width: 768px) {
      .landing-page .main-slider__content:after {
        display: none; } }
  .landing-page .main-slider__content .header {
    position: absolute;
    top: 5px;
    z-index: 1;
    left: 40px;
    display: block;
    width: 550px;
    height: 120px; }
    @media (max-width: 1440px) {
      .landing-page .main-slider__content .header {
        width: 495px;
        height: 108px;
        top: 3px;
        left: 10px; } }
    @media (max-width: 768px) {
      .landing-page .main-slider__content .header {
        left: 50%;
        transform: translateX(-50%);
        top: 7px; } }
    @media (max-width: 600px) {
      .landing-page .main-slider__content .header {
        width: 300px;
        height: 80px; } }
    .landing-page .main-slider__content .header img {
      width: 100%; }
    .landing-page .main-slider__content .header-text {
      font-size: 1.1rem;
      color: #ffffff;
      position: relative;
      top: -40px;
      left: -20px; }
      @media (max-width: 768px) {
        .landing-page .main-slider__content .header-text {
          left: 0; } }
      @media (max-width: 600px) {
        .landing-page .main-slider__content .header-text {
          display: block;
          max-width: 100px;
          top: -20px;
          text-align: center;
          left: 10px; } }
  .landing-page .main-slider__content p {
    align-self: flex-end; }
  .landing-page .main-slider__content .buttons {
    align-self: flex-end;
    display: flex;
    height: 50px;
    width: 100%; }
    @media (max-width: 768px) {
      .landing-page .main-slider__content .buttons {
        justify-content: center; } }
    @media (max-width: 768px) {
      .landing-page .main-slider__content .buttons a {
        display: flex;
        align-items: center;
        justify-content: center; } }
    @media (max-width: 600px) {
      .landing-page .main-slider__content .buttons a {
        padding: 10px; } }
    .landing-page .main-slider__content .buttons a:first-child {
      margin-left: 0; }

.landing-page .main-slider__slide {
  height: 800px; }
  @media (max-width: 1440px) {
    .landing-page .main-slider__slide {
      height: 720px; } }
  @media (max-width: 600px) {
    .landing-page .main-slider__slide {
      height: 600px; } }

.landing-page .about {
  padding-top: 0;
  margin-bottom: 4.2rem; }
  .landing-page .about-numbers {
    margin-top: 6rem; }
    @media (max-width: 1000px) {
      .landing-page .about-numbers {
        flex-wrap: wrap; } }
    .landing-page .about-numbers:before {
      content: none; }
    @media (max-width: 1000px) {
      .landing-page .about-numbers__item {
        width: 50%; } }
    @media (max-width: 480px) {
      .landing-page .about-numbers__item {
        width: 100%; } }
    .landing-page .about-numbers__item:hover {
      z-index: 5; }
      @media (max-width: 1440px) {
        .landing-page .about-numbers__item:hover:first-child .about-numbers__item-icon {
          padding-left: 0; } }
      .landing-page .about-numbers__item:hover .about-numbers__item-number {
        font-size: 3.4rem;
        line-height: 5rem;
        text-align: center;
        color: #212324;
        transform: translateX(-50%) translateY(calc(76px)); }
        @media (max-width: 480px) {
          .landing-page .about-numbers__item:hover .about-numbers__item-number {
            transform: translateX(-50%) translateY(calc(60px)); } }
      .landing-page .about-numbers__item:hover .about-numbers__item-icon {
        opacity: .2; }
        @media (max-width: 1440px) {
          .landing-page .about-numbers__item:hover .about-numbers__item-icon svg {
            width: 100%; } }
        @media (max-width: 480px) {
          .landing-page .about-numbers__item:hover .about-numbers__item-icon {
            top: 0; } }
      .landing-page .about-numbers__item:hover .about-numbers__item-inner:after {
        height: 263px;
        transition: all 0.2s ease-in-out 0s; }
        @media (max-width: 480px) {
          .landing-page .about-numbers__item:hover .about-numbers__item-inner:after {
            height: 215px; } }
      .landing-page .about-numbers__item:hover .about-numbers__item-text {
        top: calc(100% + 56px);
        height: auto;
        padding: 22px;
        transition: all 0.2s ease-in-out 0s; }
        @media (max-width: 1440px) {
          .landing-page .about-numbers__item:hover .about-numbers__item-text {
            top: calc(100% + 73px); } }
        @media (max-width: 480px) {
          .landing-page .about-numbers__item:hover .about-numbers__item-text {
            top: calc(100% + 68px); } }
        .landing-page .about-numbers__item:hover .about-numbers__item-text span {
          transform: none; }
    @media (max-width: 1440px) {
      .landing-page .about-numbers__item:first-child .about-numbers__item-icon {
        padding-left: 10px; } }
    @media (max-width: 1000px) {
      .landing-page .about-numbers__item:first-child .about-numbers__item-icon {
        padding-left: 0; } }
    .landing-page .about-numbers__item-inner {
      position: relative;
      z-index: 4;
      background-color: #F8F8F8; }
      .landing-page .about-numbers__item-inner:before {
        content: none; }
      .landing-page .about-numbers__item-inner:after {
        top: -7px;
        box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
        background-color: #ffffff;
        transition: all 0.2s ease-in-out 0s; }
    .landing-page .about-numbers__item-content {
      display: flex;
      justify-content: center; }
      @media (max-width: 1440px) {
        .landing-page .about-numbers__item-content {
          height: 183px; } }
      @media (max-width: 480px) {
        .landing-page .about-numbers__item-content {
          height: 140px; } }
    .landing-page .about-numbers__item-icon {
      right: calc(65% + 8px); }
      @media (max-width: 1440px) {
        .landing-page .about-numbers__item-icon svg {
          width: 80%; } }
    .landing-page .about-numbers__item-number {
      font-size: 2.6rem;
      line-height: 3.5rem;
      color: #212324;
      white-space: nowrap;
      left: calc(35% + 8px); }
      @media (max-width: 1440px) {
        .landing-page .about-numbers__item-number {
          font-size: 2rem; } }
    .landing-page .about-numbers__item-text {
      position: absolute;
      top: 0;
      height: 0;
      overflow: hidden;
      width: 100%;
      background-color: #008EB9;
      padding: 0 22px;
      box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
      transition: all 0.2s ease-in-out 0s; }
      .landing-page .about-numbers__item-text span {
        font-size: 1.5rem;
        line-height: 2.8rem; }

.landing-page .landing-question {
  margin-bottom: 6rem; }
  .landing-page .landing-question .wrapper {
    max-width: 1425px; }
  @media (max-width: 600px) {
    .landing-page .landing-question .guest-section-left {
      order: 2; } }
  @media (max-width: 600px) {
    .landing-page .landing-question .guest-section-right {
      order: 1;
      margin-bottom: 20px; } }
  .landing-page .landing-question .pagination {
    margin-top: 6rem; }

.landing-page .forward {
  font-weight: 700; }

.landing-page .main-news {
  padding: 0; }

.landing-page .main-news__wrapper {
  max-width: 1425px; }
  .landing-page .main-news__wrapper > .main-news__container {
    width: 100%;
    margin: 6rem auto; }
    .landing-page .main-news__wrapper > .main-news__container .main-news__container {
      margin: 0; }
    .landing-page .main-news__wrapper > .main-news__container .main-news__item {
      width: 32.38434%;
      margin: 0;
      margin-right: 1.42349%; }
      @media (max-width: 1000px) {
        .landing-page .main-news__wrapper > .main-news__container .main-news__item {
          width: 100%;
          margin: 0; } }
      .landing-page .main-news__wrapper > .main-news__container .main-news__item:nth-child(3n) {
        margin-right: 0; }

.header__wrapper {
  max-width: 1822px;
  padding-top: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.header-content {
  width: calc(100% - 120px - 20px);
  max-width: 1610px; }
  @media (max-width: 1000px) {
    .header-content {
      width: 100%; } }
  .header-content__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem; }
    @media (max-width: 1440px) {
      .header-content__top {
        margin-bottom: 1.4rem; } }
    @media (max-width: 1000px) {
      .header-content__top {
        margin-bottom: 2.4rem; } }
    @media (max-width: 480px) {
      .header-content__top {
        margin-bottom: 2rem; } }
  .header-content__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
    @media (max-width: 1000px) {
      .header-content__bottom {
        flex-wrap: wrap;
        justify-content: flex-end; } }
    @media (max-width: 768px) {
      .header-content__bottom {
        justify-content: space-between;
        margin-bottom: 10px; } }

.header-contacts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 175px - 20px);
  max-width: 740px; }
  @media (max-width: 1000px) {
    .header-contacts {
      width: 100%;
      margin-left: 138px;
      justify-content: flex-end;
      max-width: 100%; } }
  @media (max-width: 768px) {
    .header-contacts {
      margin-left: 108px;
      flex-wrap: wrap;
      justify-content: center; } }
  .header-contacts__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 6rem; }
    .header-contacts__item:last-child {
      margin-right: 0; }
    .header-contacts__item--email {
      color: #9F9F9F;
      transition: all 0.2s ease-in-out 0s; }
      @media (max-width: 768px) {
        .header-contacts__item--email {
          width: 100%;
          margin-right: 0;
          display: flex;
          justify-content: center;
          margin-bottom: 10px; } }
      @media (max-width: 480px) {
        .header-contacts__item--email {
          margin-bottom: 0; } }
      .header-contacts__item--email:hover {
        color: #008EB9; }
      .header-contacts__item--email:active {
        color: #1B77A1; }
      .header-contacts__item--email .icon {
        width: 21px;
        height: 16px;
        background-image: url(../images/email-icon.svg); }
      .header-contacts__item--email .text {
        font-size: 1.5rem;
        line-height: 3.2rem;
        position: relative;
        color: inherit; }
        .header-contacts__item--email .text::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 4px;
          width: 100%;
          height: 1px;
          background-image: url(../images/line.svg); }
    .header-contacts__item--phone {
      color: #212324;
      transition: all 0.2s ease-in-out 0s; }
      @media (max-width: 480px) {
        .header-contacts__item--phone {
          width: 100%;
          justify-content: center;
          margin-right: 0; } }
      .header-contacts__item--phone:hover {
        color: #C2A271; }
      .header-contacts__item--phone:active {
        color: #A38453; }
      .header-contacts__item--phone .icon {
        width: 16px;
        height: 15px;
        background-image: url(../images/phone-icon.svg); }
      .header-contacts__item--phone .text {
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 3.2rem;
        color: inherit; }
    .header-contacts__item .icon {
      display: inline-block;
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 1rem; }

.header-nav {
  width: calc(100% - 182px);
  max-width: 1368px; }
  .header-nav-burger {
    display: none; }
  @media (max-width: 1000px) {
    .header-nav {
      width: 100%;
      order: 1; } }
  @media (max-width: 768px) {
    .header-nav {
      order: 0;
      height: 32px;
      width: 35px;
      position: relative;
      z-index: 5; }
      .header-nav-burger {
        display: block;
        width: 32px;
        height: 32px;
        position: relative;
        cursor: pointer; }
        .header-nav-burger__stick {
          display: block;
          width: 32px;
          height: 5px;
          background-color: #1B77A1;
          position: absolute;
          top: 14px;
          opacity: 1;
          transform: translateX(0);
          transition: all 0.2s ease-in-out 0s; }
        .header-nav-burger:before {
          content: '';
          display: block;
          width: 32px;
          height: 5px;
          background-color: #1B77A1;
          position: absolute;
          top: 3px;
          transform-origin: left;
          transition: all 0.2s ease-in-out 0s; }
        .header-nav-burger:after {
          content: '';
          display: block;
          width: 32px;
          height: 5px;
          background-color: #1B77A1;
          position: absolute;
          bottom: 2px;
          transition: all 0.2s ease-in-out 0s;
          transform-origin: left; }
        .header-nav-burger.active .header-nav-burger__stick {
          opacity: 0;
          transform: translateX(-32px); }
        .header-nav-burger.active:before {
          transform: rotate(44deg);
          transform-origin: left; }
        .header-nav-burger.active:after {
          transform: rotate(-44deg);
          transform-origin: left; }
      .header-nav .active + ul {
        transform: translateX(0);
        background-color: #ffffff;
        transition: all 0.2s ease-in-out 0s; }
        .header-nav .active + ul li {
          width: 100%; }
          .header-nav .active + ul li a {
            opacity: 1;
            transition: all 0.2s ease-in-out 0s;
            border-right: 1px solid #EAEAEA;
            box-sizing: border-box; }
          .header-nav .active + ul li .active {
            background-color: #008EB9;
            color: #ffffff;
            transition: all 0.2s ease-in-out 0s; }
            .header-nav .active + ul li .active:after {
              border-color: #ffffff; }
          .header-nav .active + ul li a:hover {
            background-color: #008EB9;
            color: #ffffff;
            transition: all 0.2s ease-in-out 0s; } }
  .header-nav ul {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 768px) {
      .header-nav ul {
        transform: translateX(-320px);
        transition: all 0.2s ease-in-out 0s;
        position: relative;
        width: 320px;
        left: -10px;
        top: 9px; } }
    .header-nav ul li {
      position: relative; }
      @media (max-width: 768px) {
        .header-nav ul li {
          width: 100%; }
          .header-nav ul li:last-child a {
            border-bottom: 1px solid #EAEAEA; }
            .header-nav ul li:last-child a.active {
              border-bottom: 1px solid #008EB9; }
            .header-nav ul li:last-child a:hover {
              border-bottom: 1px solid #008EB9; } }
      .header-nav ul li.has-child.active > div a {
        color: #008EB9; }
        @media (max-width: 768px) {
          .header-nav ul li.has-child.active > div a {
            border-right: 1px solid #008EB9; } }
      .header-nav ul li.has-child.active button::after {
        transform: translateY(calc(-50% + 2px)) rotate(225deg); }
        @media (max-width: 1440px) {
          .header-nav ul li.has-child.active button::after {
            transform: translateY(calc(-50% + 1px)) rotate(225deg); } }
      @media (max-width: 768px) {
        .header-nav ul li.has-child.active > div a {
          color: #ffffff;
          background-color: #008EB9; }
        .header-nav ul li.has-child.active button {
          background: #008EB9;
          color: #ffffff; }
          .header-nav ul li.has-child.active button::after {
            border-color: #ffffff; } }
      .header-nav ul li a, .header-nav ul li button {
        display: block;
        white-space: nowrap;
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: 500;
        background-color: transparent;
        border: none;
        text-transform: uppercase;
        padding: 1.5rem 2rem 1.5rem;
        border-bottom: 2px solid transparent;
        transition: all 0.2s ease-in-out 0s; }
        @media (max-width: 1440px) {
          .header-nav ul li a, .header-nav ul li button {
            padding: 1.5rem 1.5rem 1.5rem; } }
        @media (max-width: 768px) {
          .header-nav ul li a, .header-nav ul li button {
            border-bottom: 0; } }
        .header-nav ul li a:hover, .header-nav ul li a.active, .header-nav ul li button:hover, .header-nav ul li button.active {
          border-color: #008EB9;
          color: #008EB9; }
        .header-nav ul li a:active, .header-nav ul li button:active {
          border-color: #1B77A1;
          color: #1B77A1; }
      .header-nav ul li button {
        position: relative; }
        @media (max-width: 768px) {
          .header-nav ul li button {
            width: 100%;
            text-align: left;
            padding-right: 34px; } }
        .header-nav ul li button::after {
          content: '';
          width: 10px;
          height: 10px;
          border-bottom: 1px solid #212324;
          border-right: 1px solid #212324;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(calc(-50% - 4px)) rotate(45deg);
          transition: all 0.2s ease-in-out 0s; }
          @media (max-width: 1440px) {
            .header-nav ul li button::after {
              width: 6px;
              height: 6px; } }
          @media (max-width: 768px) {
            .header-nav ul li button::after {
              width: 10px;
              height: 10px;
              right: 12px; } }
        .header-nav ul li button:hover::after {
          border-color: #008EB9; }
      .header-nav ul li .nav-dropdown-list {
        position: absolute;
        top: 100%;
        left: 0;
        width: 28rem;
        transition-property: opacity;
        transition-duration: .2s;
        background: #ffffff;
        display: none;
        box-shadow: 0 3px 5px rgba(33, 35, 36, 0.15); }
        @media (max-width: 768px) {
          .header-nav ul li .nav-dropdown-list {
            box-shadow: none;
            width: 100%;
            position: static;
            transform: none;
            opacity: 1;
            display: none; } }
        .header-nav ul li .nav-dropdown-list::after {
          content: '';
          width: 100%;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(180deg, #EBEBEB 0%, rgba(255, 255, 255, 0) 100%);
          pointer-events: none;
          z-index: 1; }
          @media (max-width: 768px) {
            .header-nav ul li .nav-dropdown-list::after {
              display: none; } }
        .header-nav ul li .nav-dropdown-list li {
          width: 100%;
          position: relative;
          z-index: 2; }
          .header-nav ul li .nav-dropdown-list li a {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 1.4rem;
            line-height: 2rem;
            font-weight: 500;
            color: #212324;
            padding: 1rem 3rem;
            min-height: 6rem;
            white-space: normal;
            border: 0;
            background-color: #ffffff;
            transition: all 0.2s ease-in-out 0s; }
            @media (max-width: 768px) {
              .header-nav ul li .nav-dropdown-list li a {
                padding: 1rem 1.5rem;
                background-color: #F8F8F8; } }
            .header-nav ul li .nav-dropdown-list li a:hover {
              color: #ffffff;
              background: #008EB9; }
            .header-nav ul li .nav-dropdown-list li a:active {
              background: #1B77A1; }

.header-callback {
  font-size: 1.4rem;
  line-height: 2.6rem;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: 500;
  color: #ffffff;
  padding: 1rem 3rem;
  background: #1B77A1;
  border: 0;
  transition: all 0.2s ease-in-out 0s; }
  .header-callback:hover {
    background: #008EB9; }
  @media (max-width: 1000px) {
    .header-callback {
      margin-right: 378px; } }
  @media (max-width: 768px) {
    .header-callback {
      margin-right: 45%; } }
  @media (max-width: 480px) {
    .header-callback {
      margin-right: 39%; } }
  .header-callback:active {
    background: #1B77A1; }

.footer {
  position: relative;
  flex: 0 0 auto;
  background: #EAEAEA;
  padding: 2rem 0;
  /*footer-top start*/
  /*footer-top end*/
  /*footer-middle start*/
  /*footer-middle end*/
  /*footer-bottom start*/
  /*footer-bottom end*/ }
  .footer-top {
    max-width: 1772px;
    margin: 0 auto; }
    .footer-top__wrapper {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 480px) {
        .footer-top__wrapper {
          flex-wrap: wrap; } }
    .footer-top__left {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 52.14008%;
      max-width: 938px; }
      @media (max-width: 1000px) {
        .footer-top__left {
          flex-wrap: wrap;
          justify-content: center; } }
      @media (max-width: 480px) {
        .footer-top__left {
          width: 100%; } }
      .footer-top__left .logotype {
        margin-right: 20px;
        min-width: 100px;
        align-self: center; }
        @media (max-width: 1000px) {
          .footer-top__left .logotype {
            position: static;
            margin-right: 10%;
            margin-bottom: 10px; } }
        @media (max-width: 480px) {
          .footer-top__left .logotype {
            margin-right: 0; } }
    .footer-top__right {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 35.9589%; }
      @media (max-width: 1440px) {
        .footer-top__right {
          width: 46%; } }
      @media (max-width: 1000px) {
        .footer-top__right {
          flex-wrap: wrap;
          width: 30%; } }
      @media (max-width: 480px) {
        .footer-top__right {
          width: 100%;
          justify-content: center;
          flex-direction: column;
          align-items: center; } }
  .footer-nav {
    max-width: 745px; }
    @media (max-width: 480px) {
      .footer-nav {
        margin-bottom: 2rem; } }
    .footer-nav ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
      @media (max-width: 1000px) {
        .footer-nav ul {
          justify-content: space-between; } }
      .footer-nav ul li {
        width: 33%;
        padding-right: 10px;
        margin-top: 4rem; }
        .footer-nav ul li:nth-child(1) {
          margin-top: 0; }
        .footer-nav ul li:nth-child(2) {
          margin-top: 0; }
        .footer-nav ul li:nth-child(3) {
          margin-top: 0; }
        @media (max-width: 1440px) {
          .footer-nav ul li {
            width: 50%;
            margin-top: 2rem; }
            .footer-nav ul li:nth-child(3) {
              margin-top: 2rem; } }
        @media (max-width: 1000px) {
          .footer-nav ul li {
            width: 34%; } }
        @media (max-width: 480px) {
          .footer-nav ul li {
            width: 45%; } }
        .footer-nav ul li a {
          white-space: nowrap;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 2.4rem;
          text-transform: uppercase;
          transition: all 0.2s ease-in-out 0s; }
          .footer-nav ul li a:hover, .footer-nav ul li a.active {
            color: #008EB9; }
          .footer-nav ul li a:active {
            color: #1B77A1; }
  .footer__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.5rem; }
    .footer__title--phone .icon {
      width: 16px;
      height: 16px;
      background-image: url(../images/phone-icon.svg); }
    .footer__title--email .icon {
      width: 21px;
      height: 16px;
      background-image: url(../images/email-icon.svg); }
    .footer__title--address .icon {
      width: 25px;
      height: 25px;
      background-image: url(../images/address-icon.svg); }
    .footer__title .icon {
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 1rem; }
    .footer__title .text {
      font-size: 1.3rem;
      color: #9F9F9F;
      text-transform: uppercase; }
  .footer__phone {
    font-size: 2.6rem;
    line-height: 3.5rem;
    font-weight: 600;
    color: #000;
    display: block;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out 0s; }
    .footer__phone:hover {
      color: #C2A271; }
    .footer__phone:active {
      color: #A38453; }
    .footer__phone:last-child {
      margin-bottom: 0; }
  @media (max-width: 1000px) {
    .footer-phones {
      margin-bottom: 3rem; } }
  @media (max-width: 480px) {
    .footer-phones {
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .footer__email {
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: #008EB9;
    position: relative; }
    .footer__email::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: url(../images/line.svg); }
  .footer-contacts {
    margin-left: 3.8rem; }
    @media (max-width: 1000px) {
      .footer-contacts {
        margin-left: 0; } }
  .footer-email {
    margin-bottom: 3.2rem; }
    @media (max-width: 1000px) {
      .footer-email {
        margin-bottom: 3rem; } }
    @media (max-width: 480px) {
      .footer-email {
        display: flex;
        flex-direction: column;
        align-items: center; } }
  .footer-address address {
    font-size: 1.5rem;
    line-height: 2.8rem; }
  @media (max-width: 480px) {
    .footer-address {
      display: flex;
      flex-direction: column;
      align-items: center; } }
  .footer-middle {
    width: 100%;
    border-color: #9F9F9F;
    border-style: solid;
    border-width: 1px 0 1px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0 16px;
    margin-top: 3.8rem; }
    .footer-middle .logotype-pskov {
      position: static; }
  .footer-bottom {
    margin-top: 3.8rem;
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: #9F9F9F; }
    @media (max-width: 768px) {
      .footer-bottom {
        margin-top: 2rem; } }
    .footer-bottom__wrapper {
      max-width: 1435px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start; }
      @media (max-width: 768px) {
        .footer-bottom__wrapper {
          flex-direction: column;
          align-items: center; }
          .footer-bottom__wrapper > div {
            text-align: center;
            margin-bottom: 1.5rem; } }
    .footer-bottom__copyright, .footer-bottom__policy, .footer-bottom__coffee {
      width: 282px; }
    .footer-bottom__policy {
      text-align: center;
      display: flex;
      justify-content: center;
      transition: all 0.2s ease-in-out 0s; }
      .footer-bottom__policy a {
        color: inherit;
        position: relative; }
        .footer-bottom__policy a::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-image: url(../images/line.svg); }
        .footer-bottom__policy a:hover {
          color: #00a2d3; }
        .footer-bottom__policy a:active {
          color: #1B77A1; }
    .footer-bottom__coffee {
      text-align: right; }
      .footer-bottom__coffee a {
        color: inherit;
        transition: all 0.2s ease-in-out 0s; }
        .footer-bottom__coffee a:hover {
          color: #00a2d3; }
        .footer-bottom__coffee a:active {
          color: #1B77A1; }

.main-slider {
  max-width: 1920px; }
  .main-slider__slide {
    position: relative;
    overflow: hidden;
    height: 690px;
    min-height: 690px; }
    @media (max-width: 1440px) {
      .main-slider__slide {
        height: 550px;
        min-height: 550px; } }
    @media (max-width: 768px) {
      .main-slider__slide {
        height: 400px;
        min-height: 400px; } }
    .main-slider__slide-img {
      height: 100%;
      width: 100%;
      position: absolute; }
      .main-slider__slide-img img {
        height: 100%;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
  .main-slider__content {
    background: rgba(255, 255, 255, 0.8);
    padding: 78px 50px 36px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
    width: 574px;
    margin-right: 23.6rem;
    height: 496px; }
    @media (max-width: 1440px) {
      .main-slider__content {
        padding: 58px 42px 30px;
        width: 486px;
        height: 386px;
        margin-right: 14rem; } }
    @media (max-width: 1000px) {
      .main-slider__content {
        padding-right: 75px; } }
    @media (max-width: 768px) {
      .main-slider__content {
        margin-right: 0;
        width: 100%;
        height: 300px;
        padding-right: 42px; } }
    @media (max-width: 480px) {
      .main-slider__content {
        padding: 45px 20px; } }
    .main-slider__content .arrows {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-start; }
      .main-slider__content .arrows .arrow {
        width: 46px;
        height: 46px;
        background: #1B77A1;
        border: 0;
        transition: all 0.2s ease-in-out 0s;
        position: relative; }
        @media (max-width: 768px) {
          .main-slider__content .arrows .arrow {
            width: 40px;
            height: 40px; } }
        .main-slider__content .arrows .arrow::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 17px;
          height: 28px;
          background-image: url(../images/main-slider-arrow.svg);
          background-position: center center;
          -webkit-background-size: contain;
          background-size: contain;
          background-repeat: no-repeat; }
        .main-slider__content .arrows .arrow:hover {
          background: #008EB9; }
        .main-slider__content .arrows .arrow:active {
          background: #1B77A1; }
        .main-slider__content .arrows .arrow.prev::after {
          transform: translate(-50%, -50%) rotate(180deg); }
  .main-slider__title {
    max-width: 400px;
    font-weight: 900;
    font-size: 3.4rem;
    line-height: 4.8rem;
    margin-bottom: 2.5rem; }
    @media (max-width: 768px) {
      .main-slider__title {
        margin: 0 auto;
        margin-bottom: 2.5rem;
        text-align: center; } }
  .main-slider .text {
    max-width: 400px; }
    @media (max-width: 768px) {
      .main-slider .text {
        margin: 0 auto;
        text-align: center; } }
  .main-slider .btn {
    position: absolute;
    bottom: 54px;
    right: -40px; }
    @media (max-width: 1440px) {
      .main-slider .btn {
        bottom: 44px; } }
    @media (max-width: 768px) {
      .main-slider .btn {
        bottom: 30px;
        right: 25px; } }
    @media (max-width: 480px) {
      .main-slider .btn {
        bottom: 22px; } }
  .main-slider .swiper-pagination {
    bottom: 3.8rem; }
    @media (max-width: 1440px) {
      .main-slider .swiper-pagination {
        bottom: 2.8rem; } }
    .main-slider .swiper-pagination-bullet {
      background: #fff;
      border: 1px solid #C2A271;
      width: 8px;
      height: 8px;
      opacity: 1;
      margin: 0 8px;
      transition: all 0.2s ease-in-out 0s; }
      .main-slider .swiper-pagination-bullet-active {
        background: #C2A271;
        border: 1px solid #fff;
        transform: scale(1.45); }

.about {
  padding-top: 4rem; }
  .about__wrapper {
    max-width: 1430px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 480px) {
      .about__wrapper {
        flex-wrap: wrap; } }
  .about-text {
    width: calc(50% - 10px);
    max-width: 586px; }
    @media (max-width: 480px) {
      .about-text {
        width: 100%;
        order: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center; } }
    .about-text__title {
      margin-bottom: 2.5rem; }
      @media (max-width: 480px) {
        .about-text__title {
          text-align: center;
          margin-top: 2.5rem; } }
    .about-text__btn {
      margin-top: 5rem; }
  .about-img {
    width: calc(50% - 10px);
    max-width: 738px; }
    @media (max-width: 480px) {
      .about-img {
        width: 100%; } }
    .about-img img {
      width: 100%;
      height: auto; }
  .about-numbers {
    width: 100%;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    margin-top: 6.7rem;
    background-color: #F8F8F8;
    position: relative; }
    @media (max-width: 768px) {
      .about-numbers {
        flex-wrap: wrap; } }
    .about-numbers:before {
      content: '';
      display: block;
      height: 67px;
      position: absolute;
      bottom: 0;
      background-color: #008EB9;
      width: 100%; }
    .about-numbers__item {
      width: 25%;
      position: relative;
      background: #F8F8F8;
      max-width: 480px; }
      @media (max-width: 768px) {
        .about-numbers__item {
          width: 50%; } }
      @media (max-width: 480px) {
        .about-numbers__item {
          width: 100%; } }
      .about-numbers__item:hover .about-numbers__item-inner::after {
        height: 100%; }
      .about-numbers__item:hover .about-numbers__item-icon {
        right: 50%;
        top: 4.2rem;
        transform: translateX(50%) translateY(0);
        height: 190px; }
        .about-numbers__item:hover .about-numbers__item-icon path {
          fill: #D7B581; }
      .about-numbers__item:hover .about-numbers__item-number {
        color: #ffffff;
        font-size: 12rem;
        left: 50%;
        top: 0;
        transform: translateX(-50%) translateY(4.2rem); }
        @media (max-width: 1440px) {
          .about-numbers__item:hover .about-numbers__item-number {
            font-size: 10rem; } }
        @media (max-width: 1000px) {
          .about-numbers__item:hover .about-numbers__item-number {
            font-size: 9rem; } }
        @media (max-width: 768px) {
          .about-numbers__item:hover .about-numbers__item-number {
            font-size: 12rem; } }
      .about-numbers__item:hover .about-numbers__item-text:after {
        height: calc(100% + 200px); }
      .about-numbers__item:hover .about-numbers__item-text span {
        transform: translateY(-25px); }
      .about-numbers__item-inner {
        /*position: absolute;
        width: 100%;
        height: 100%;*/
        z-index: 0; }
        .about-numbers__item-inner::before {
          content: '';
          width: 100%;
          height: 67px;
          background: #008EB9;
          position: absolute;
          bottom: 0;
          left: 0; }
        .about-numbers__item-inner::after {
          content: '';
          z-index: 1;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: #C2A271;
          box-shadow: 0px 8px 16px rgba(33, 35, 36, 0.3);
          height: 0;
          transition: 0.3s; }
      .about-numbers__item-content {
        width: 100%;
        height: 200px;
        position: relative;
        z-index: 2; }
      .about-numbers__item-icon {
        height: 100px;
        position: absolute;
        top: 50%;
        right: calc(50% + 8px);
        transform: translateY(-50%);
        transition: 0.3s; }
        @media (max-width: 1000px) {
          .about-numbers__item-icon {
            margin-left: 10px; } }
        .about-numbers__item-icon svg {
          height: 100%;
          width: auto; }
          @media (max-width: 1000px) {
            .about-numbers__item-icon svg {
              width: 80%; } }
          @media (max-width: 480px) {
            .about-numbers__item-icon svg {
              width: 100%; } }
        .about-numbers__item-icon path {
          transition: 0.3s; }
      .about-numbers__item-number {
        position: absolute;
        top: 50%;
        left: calc(50% + 8px);
        transform: translateY(-50%);
        font-size: 6rem;
        font-weight: 900;
        color: #000;
        letter-spacing: 0.1em;
        transition: 0.3s; }
        @media (max-width: 1440px) {
          .about-numbers__item-number {
            font-size: 5rem; } }
        @media (max-width: 1000px) {
          .about-numbers__item-number {
            font-size: 4rem; } }
        @media (max-width: 480px) {
          .about-numbers__item-number {
            font-size: 6rem; } }
      .about-numbers__item-text {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        height: 67px;
        position: relative;
        z-index: 3; }
        .about-numbers__item-text span {
          font-size: 2rem;
          line-height: 3.2rem;
          color: #ffffff;
          position: relative;
          z-index: 1;
          transition: 0.3s; }

.main-advantages {
  margin-top: 4.6rem;
  position: relative; }
  .main-advantages::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #EAEAEA; }
  .main-advantages__wrapper {
    max-width: 1430px;
    position: relative;
    z-index: 1; }
    @media (max-width: 1000px) {
      .main-advantages__wrapper > .main-advantages__container {
        overflow: hidden;
        max-width: 680px;
        margin: auto; } }
  .main-advantages__title {
    margin-bottom: 1.6rem; }
  .main-advantages__text {
    max-width: 692px;
    margin: 0 auto 4rem;
    color: rgba(33, 35, 36, 0.3);
    text-align: center; }
  .main-advantages__container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: auto; }
    @media (max-width: 1000px) {
      .main-advantages__container {
        width: 100%;
        margin: 0; }
        .main-advantages__container .swiper-wrapper {
          flex-wrap: nowrap;
          align-items: stretch;
          height: auto; } }
    .main-advantages__container .swiper-button-disabled {
      opacity: .5; }
    .main-advantages__container .advantages-swiper-button-prev, .main-advantages__container .advantages-swiper-button-next {
      display: none; }
      @media (max-width: 1000px) {
        .main-advantages__container .advantages-swiper-button-prev, .main-advantages__container .advantages-swiper-button-next {
          display: block;
          width: 40px;
          height: 40px;
          background: #1B77A1;
          border: 0;
          outline: 0;
          transition: all 0.2s ease-in-out 0s;
          position: absolute;
          z-index: 1; }
          .main-advantages__container .advantages-swiper-button-prev:after, .main-advantages__container .advantages-swiper-button-next:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 17px;
            height: 28px;
            background-image: url(../images/main-slider-arrow.svg);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat; } }
    .main-advantages__container .advantages-swiper-button-prev {
      right: 40px; }
      .main-advantages__container .advantages-swiper-button-prev:after {
        transform: translate(-50%, -50%) rotate(180deg); }
    .main-advantages__container .advantages-swiper-button-next {
      right: 0; }
  .main-advantages__item {
    width: 32.38434%;
    margin: 0;
    margin-right: 1.42349%;
    background: #ffffff;
    padding: 5rem 0 4.2rem;
    border: 1px solid #EAEAEA;
    transition: all 0.2s ease-in-out 0s; }
    .main-advantages__item:nth-child(3n) {
      margin-right: 0; }
    @media (max-width: 1440px) {
      .main-advantages__item {
        overflow: hidden; } }
    @media (max-width: 1000px) {
      .main-advantages__item {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 2rem; } }
    .main-advantages__item:hover .main-advantages__item-icon {
      transform: translateX(15px); }
    .main-advantages__item:hover .main-advantages__item-title {
      color: #af9061; }
    .main-advantages__item:active {
      transform: scale(0.98); }
    .main-advantages__item-title {
      width: 100%;
      text-align: center;
      font-size: 2.6rem;
      line-height: 3.5rem;
      color: #C2A271;
      font-weight: 600;
      margin-bottom: 5px;
      min-height: 7rem;
      padding: 0 1rem;
      transition: all 0.2s ease-in-out 0s; }
    .main-advantages__item-content {
      padding: 0 3.7rem 0 0;
      position: relative;
      display: flex;
      justify-content: space-between;
      overflow: hidden; }
      @media (max-width: 1440px) {
        .main-advantages__item-content {
          padding: 0;
          overflow: visible;
          justify-content: center; } }
    .main-advantages__item-icon {
      width: 145px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -3.2rem;
      margin-right: 1.6rem;
      margin-top: 6.2rem;
      transition: all 0.2s ease-in-out 0s; }
      .main-advantages__item-icon svg {
        height: 100%;
        width: auto; }
      @media (max-width: 1440px) {
        .main-advantages__item-icon {
          position: absolute;
          opacity: .2;
          left: 0; } }
    .main-advantages__item-text {
      max-width: 384px;
      color: #9F9F9F; }
      @media (max-width: 1440px) {
        .main-advantages__item-text {
          padding: 10px;
          text-align: center;
          color: #212324; } }

.main-services {
  padding-top: 7rem;
  padding-bottom: 5rem;
  background: #EAEAEA; }
  @media (max-width: 768px) {
    .main-services {
      padding-top: 2rem; } }
  .main-services__wrapper {
    padding: 0 20px; }
    @media (max-width: 1440px) {
      .main-services__wrapper {
        padding: 0 10px; } }
    .main-services__wrapper > .main-services__container {
      margin-top: 60px; }
    @media (max-width: 1000px) {
      .main-services__wrapper > .main-services__container {
        flex-wrap: wrap;
        margin: auto;
        margin-top: 2.8rem;
        max-width: 680px; } }
  .main-services__container {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1000px) {
      .main-services__container {
        flex-wrap: nowrap;
        width: 100%;
        margin: 0; } }
    .main-services__container.services-swiper-container {
      overflow: hidden;
      position: relative; }
    .main-services__container .swiper-button-disabled {
      opacity: .5; }
    .main-services__container .services-swiper-button-prev, .main-services__container .services-swiper-button-next {
      display: none; }
      @media (max-width: 1000px) {
        .main-services__container .services-swiper-button-prev, .main-services__container .services-swiper-button-next {
          display: block;
          width: 40px;
          height: 40px;
          background: #1B77A1;
          border: 0;
          outline: 0;
          transition: all 0.2s ease-in-out 0s;
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%); }
          .main-services__container .services-swiper-button-prev:after, .main-services__container .services-swiper-button-next:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 17px;
            height: 28px;
            background-image: url(../images/main-slider-arrow.svg);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat; } }
    .main-services__container .services-swiper-button-prev:after {
      transform: translate(-50%, -50%) rotate(180deg); }
    .main-services__container .services-swiper-button-next {
      right: 0; }
  .main-services__item {
    width: calc(50% - 10px);
    height: 290px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex; }
    .main-services__item:nth-child(2n) {
      margin-right: 0; }
    @media (max-width: 1000px) {
      .main-services__item {
        width: 100%;
        height: 200px;
        margin: 0; } }
    @media (max-width: 1000px) {
      .main-services__item {
        flex-wrap: wrap;
        height: auto; } }
    .main-services__item:hover .main-services__item-text-inner {
      color: #C2A271; }
    .main-services__item:hover .main-services__item-img img {
      transform: translate(-50%, -50%) scale(1.1); }
    .main-services__item:active .main-services__item-text-inner {
      color: #A38453; }
    .main-services__item:active .main-services__item-img img {
      transform: translate(-50%, -50%) scale(1.05); }
    .main-services__item-text {
      width: 300px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5rem 3.5rem 5rem 5rem; }
      @media (max-width: 1440px) {
        .main-services__item-text {
          width: 200px;
          padding: 3rem; } }
      @media (max-width: 1000px) {
        .main-services__item-text {
          width: 350px; } }
      @media (max-width: 1000px) {
        .main-services__item-text {
          width: 100%;
          height: 87px; } }
      .main-services__item-text-inner {
        font-size: 2.6rem;
        line-height: 3.5rem;
        font-weight: 600;
        color: #000;
        padding-bottom: 3rem;
        width: 100%;
        position: relative;
        transition: all 0.2s ease-in-out 0s; }
        .main-services__item-text-inner::after {
          content: '';
          width: calc(100% - 30px);
          height: 2px;
          background: #A38453;
          position: absolute;
          bottom: 0;
          left: 0; }
    .main-services__item-img {
      width: calc(100% - 300px);
      height: 100%;
      position: relative;
      overflow: hidden; }
      @media (max-width: 1440px) {
        .main-services__item-img {
          width: calc(100% - 200px); } }
      @media (max-width: 1000px) {
        .main-services__item-img {
          width: calc(100% - 350px); } }
      @media (max-width: 1000px) {
        .main-services__item-img {
          width: 100%;
          height: 213px; } }
      .main-services__item-img img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        transition: all 0.2s ease-in-out 0s; }

.main-program {
  background: #1B77A1;
  padding: 8.2rem 0 4rem;
  position: relative; }
  @media (max-width: 1000px) {
    .main-program {
      padding: 6.2rem 0 0; } }
  @media (max-width: 768px) {
    .main-program {
      padding: 2rem 0 0; } }
  .main-program__wrapper {
    max-width: 1379px;
    display: flex;
    align-items: flex-start;
    padding-bottom: 6.8rem;
    position: relative;
    z-index: 1; }
    @media (max-width: 768px) {
      .main-program__wrapper {
        padding-bottom: 8.8rem; } }
    .main-program__wrapper::after {
      content: attr(data-bg-text);
      width: 107%;
      position: absolute;
      top: 32%;
      left: 14.3rem;
      color: rgba(0, 142, 185, 0.5);
      font-size: 20rem;
      font-weight: 900;
      line-height: 20rem;
      transform: translateY(-50%);
      letter-spacing: 0.1em;
      z-index: -1; }
      @media (max-width: 1850px) {
        .main-program__wrapper::after {
          width: 91%;
          font-size: 16.8rem;
          top: 32.5%; } }
      @media (max-width: 1440px) {
        .main-program__wrapper::after {
          width: 84.3%;
          font-size: 13.5rem;
          top: 33.5%;
          left: 18.3rem; } }
      @media (max-width: 1000px) {
        .main-program__wrapper::after {
          width: 80%;
          font-size: 10.3rem; } }
      @media (max-width: 768px) {
        .main-program__wrapper::after {
          width: 100%;
          left: 0;
          font-size: 8.3rem;
          text-align: center; } }
      @media (max-width: 768px) {
        .main-program__wrapper::after {
          font-size: 5.3rem; } }
  .main-program__icon {
    width: 130px;
    min-width: 130px;
    height: 130px;
    background: #C2A271;
    border: 4px solid #ffffff;
    border-radius: 50%;
    position: relative;
    margin-right: 6.4rem; }
    @media (max-width: 768px) {
      .main-program__icon {
        display: none; } }
    .main-program__icon::after {
      content: '';
      width: 76px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../images/program-icon.svg);
      -webkit-background-size: contain;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat; }
  .main-program__title {
    margin-bottom: 2.4rem; }
    @media (max-width: 768px) {
      .main-program__title {
        text-align: center;
        margin-bottom: 1.4rem; } }
  .main-program__text {
    width: 54.08%; }
    @media (max-width: 1000px) {
      .main-program__text {
        width: 74%; } }
    @media (max-width: 768px) {
      .main-program__text {
        width: 100%;
        text-align: center; } }
    .main-program__text .text {
      width: 100%; }
      @media (max-width: 768px) {
        .main-program__text .text {
          margin-bottom: 1.2rem; } }
  .main-program__btn {
    position: absolute;
    right: 0;
    align-self: center; }
    @media (max-width: 1440px) {
      .main-program__btn {
        right: 25px; } }
    @media (max-width: 1000px) {
      .main-program__btn {
        bottom: 15px; } }

.main-news {
  padding-top: 5rem;
  padding-bottom: 6rem; }
  @media (max-width: 768px) {
    .main-news {
      padding-top: 4rem;
      padding-bottom: 5rem; } }
  .main-news__wrapper {
    max-width: 1430px;
    position: relative; }
    .main-news__wrapper > .main-news__container {
      margin-top: 60px; }
    @media (max-width: 1000px) {
      .main-news__wrapper > .main-news__container {
        overflow: hidden;
        max-width: 680px;
        margin: auto;
        position: relative;
        margin-top: 3.8rem; } }
  .main-news__forward {
    position: absolute;
    right: 10px;
    top: 1.7rem; }
    @media (max-width: 768px) {
      .main-news__forward {
        position: static;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        margin: 0 auto;
        margin-top: 3rem; } }
  .main-news__container {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1000px) {
      .main-news__container {
        width: 100%;
        margin: 0; }
        .main-news__container .swiper-wrapper {
          flex-wrap: nowrap;
          align-items: stretch;
          height: auto; } }
    .main-news__container .swiper-button-disabled {
      opacity: .5; }
    .main-news__container .news-swiper-button-prev, .main-news__container .news-swiper-button-next {
      display: none; }
      @media (max-width: 1000px) {
        .main-news__container .news-swiper-button-prev, .main-news__container .news-swiper-button-next {
          display: block;
          width: 40px;
          height: 40px;
          background: #1B77A1;
          border: 0;
          outline: 0;
          transition: all 0.2s ease-in-out 0s;
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translateY(-50%); }
          .main-news__container .news-swiper-button-prev:after, .main-news__container .news-swiper-button-next:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 17px;
            height: 28px;
            background-image: url(../images/main-slider-arrow.svg);
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat; } }
    .main-news__container .news-swiper-button-prev:after {
      transform: translate(-50%, -50%) rotate(180deg); }
    .main-news__container .news-swiper-button-next {
      right: 0; }
  .main-news__item {
    width: 32.38434%;
    margin: 0;
    margin-right: 1.42349%;
    position: relative;
    display: block;
    background: #ffffff;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.07);
    padding-bottom: 5rem; }
    .main-news__item:nth-child(3n) {
      margin-right: 0; }
    @media (max-width: 1000px) {
      .main-news__item {
        width: 100%;
        margin: 0;
        box-shadow: none;
        border: 1px solid #EAEAEA; } }
    .main-news__item:hover .main-news__item-img {
      transform: translate(-50%, -50%) scale(1.1); }
    .main-news__item:active .main-news__item-img {
      transform: translate(-50%, -50%) scale(1.05); }
    .main-news__item-top {
      width: 100%;
      height: 265px;
      position: relative;
      overflow: hidden; }
      .main-news__item-top::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(33, 35, 36, 0.58) 76.04%);
        z-index: 1; }
    .main-news__item-img {
      z-index: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      transition: all 0.2s ease-in-out 0s; }
    .main-news__item-title {
      position: absolute;
      z-index: 2;
      bottom: 20px;
      left: 0;
      width: 100%;
      padding: 0 20px;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 900;
      color: #ffffff; }
    .main-news__item-date {
      font-size: 1.3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #9F9F9F;
      margin-bottom: 1.2rem;
      padding: 0 2rem;
      margin-top: 2.2rem; }
      .main-news__item-date::before {
        content: '';
        width: 16px;
        height: 12px;
        background-image: url(../images/date-icon.svg);
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 1rem; }
    .main-news__item-text {
      font-size: 1.5rem;
      line-height: 2.8rem;
      color: #918E8E;
      padding: 0 1.6rem 0 2rem; }
    .main-news__item-forward {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: auto;
      top: auto; }

.request-text {
  padding-bottom: 60px; }
  @media (max-width: 1000px) {
    .request-text {
      padding-bottom: 50px; } }
  @media (max-width: 768px) {
    .request-text {
      padding-bottom: 40px; } }
  @media (max-width: 600px) {
    .request-text {
      padding-bottom: 30px; } }
  .request-text p {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: rgba(33, 35, 36, 0.8);
    margin-bottom: 1.5rem; }
    .request-text p:last-child {
      margin-bottom: 0; }

.request-form {
  padding: 60px 10px;
  padding-bottom: 30px;
  background-color: #EAEAEA; }
  @media (max-width: 1000px) {
    .request-form {
      padding-top: 50px; } }
  @media (max-width: 768px) {
    .request-form {
      padding-top: 40px; } }
  @media (max-width: 600px) {
    .request-form {
      padding-top: 30px;
      padding-bottom: 20px; } }
  .request-form h2 {
    margin-bottom: 45px;
    text-align: center;
    font-size: 2.6rem;
    font-weight: 700; }
    @media (max-width: 1000px) {
      .request-form h2 {
        margin-bottom: 40px; } }
    @media (max-width: 768px) {
      .request-form h2 {
        margin-bottom: 35px; } }
    @media (max-width: 600px) {
      .request-form h2 {
        margin-bottom: 30px; } }
  .request-form-item {
    display: flex;
    flex-wrap: wrap; }
    .request-form-item:first-child {
      flex-direction: column; }
    .request-form-item h3 {
      width: 100%;
      margin: 15px 0;
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
      opacity: 0.8; }
      @media (max-width: 600px) {
        .request-form-item h3 {
          margin: 10px 0; } }
    .request-form-item--col {
      width: calc(50% - 10px);
      margin-right: 20px; }
      @media (max-width: 600px) {
        .request-form-item--col {
          width: 100%;
          margin-right: 0; } }
    .request-form-item--row {
      width: 100%;
      margin-right: 0; }
    .request-form-item__btn {
      margin: 10px auto 0;
      line-height: 1.6rem;
      padding: 10px 24px;
      max-width: 230px; }
    .request-form-item .custom-select {
      margin-left: 0;
      position: relative;
      user-select: none;
      color: #212324; }
      .request-form-item .custom-select select {
        display: none; }
    .request-form-item .select-options,
    .request-form-item .select-styled {
      display: none; }
    .request-form-item .select-items div,
    .request-form-item .select-selected {
      font-size: 1.5rem;
      padding: 10px 15px;
      cursor: pointer;
      color: #212324; }
    .request-form-item .select-items div {
      border: 1px solid transparent;
      border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent; }
    .request-form-item .select-selected {
      font-size: 1.5rem;
      color: #212324;
      background-color: #fff; }
    .request-form-item .select-selected::after {
      position: absolute;
      content: "";
      top: 14px;
      right: 12px;
      width: 5px;
      height: 5px;
      background-color: #212324;
      transform: rotate(45deg);
      transition: .3s ease-in; }
    .request-form-item .select-selected::before {
      position: absolute;
      content: "";
      top: 12px;
      right: 11px;
      width: 7px;
      height: 5px;
      background-color: #ffffff;
      transition: .3s ease-in;
      z-index: 1;
      transform-origin: 100% 100%; }
    .request-form-item .select-hide {
      display: none; }
    .request-form-item .select-items {
      position: absolute;
      background-color: #fff;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.082);
      top: 100%;
      left: 0;
      right: 0;
      z-index: 99; }
  .request-form-btn {
    display: flex;
    justify-content: center;
    margin-top: 25px; }
    @media (max-width: 600px) {
      .request-form-btn {
        flex-direction: column;
        align-items: center;
        margin-top: 15px; } }
    .request-form-btn .btn {
      min-width: 200px; }
      @media (max-width: 1000px) {
        .request-form-btn .btn {
          min-width: 180px; } }
      @media (max-width: 768px) {
        .request-form-btn .btn {
          min-width: 160px; } }
      @media (max-width: 600px) {
        .request-form-btn .btn {
          margin-bottom: 20px;
          min-width: 175px; } }
    .request-form-btn .btn-add {
      min-width: 225px; }
      @media (max-width: 1000px) {
        .request-form-btn .btn-add {
          min-width: 205px; } }
      @media (max-width: 768px) {
        .request-form-btn .btn-add {
          min-width: 185px; } }
      @media (max-width: 600px) {
        .request-form-btn .btn-add {
          margin-left: 0;
          padding: 7px 24px;
          min-width: 175px; } }
  .request-form-policy {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: #BCBDBD;
    letter-spacing: 1px; }
    .request-form-policy a {
      color: #008EB9; }
  .request-form form {
    display: flex;
    flex-direction: column;
    max-width: 930px;
    margin: 0 auto; }
    .request-form form label {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px; }
      .request-form form label:nth-child(odd) {
        margin-right: 0; }
      .request-form form label span {
        margin-left: 15px;
        font-size: 10px;
        font-weight: 400;
        color: #BCBDBD; }
      .request-form form label input {
        padding: 10px 15px;
        font-size: 1.5rem;
        font-weight: 400;
        background-color: #ffffff;
        border: 1px solid #EAEAEA;
        color: #212324; }
        .request-form form label input.important-input::placeholder {
          color: #212324; }
        .request-form form label input[type='date'] {
          height: 40px;
          color: #212324; }
          @media (max-width: 1440px) {
            .request-form form label input[type='date'] {
              height: 36px; } }
      .request-form form label input::placeholder {
        color: #BCBDBD;
        opacity: 1; }

.advantages-wrapper {
  max-width: 1425px;
  margin: 0 auto;
  padding: 3rem 10px 0;
  display: flex;
  flex-wrap: wrap; }
  .advantages-wrapper .title-h2 {
    margin-bottom: 6rem; }
  .advantages-wrapper .main-news__container {
    overflow: hidden;
    position: relative; }
    .advantages-wrapper .main-news__container .news-swiper-button-prev {
      top: 0;
      transform: none;
      right: 40px; }
    .advantages-wrapper .main-news__container .news-swiper-button-next {
      top: 0;
      transform: none; }

.advantages-item {
  padding: 0 7.8rem;
  background-image: url("../images/action-bg.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  min-height: 232px;
  position: relative;
  width: 32.38434%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.42349%;
  box-sizing: border-box;
  border: 1px solid #EAEAEA; }
  @media (max-width: 1000px) {
    .advantages-item {
      padding: 0 6.5rem;
      min-height: 210px;
      margin: 0; } }
  .advantages-item:last-child {
    margin-right: 0; }
  .advantages-item:before {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: 60px;
    height: 150px;
    background-image: url("../images/hundred.svg");
    background-repeat: no-repeat;
    top: 50%;
    transform: translateY(-50%); }
  .advantages-item span {
    font-size: 1.5rem;
    line-height: 2.8rem;
    font-weight: 700;
    color: #212324; }
    @media (max-width: 1000px) {
      .advantages-item span {
        text-align: center;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 10px;
        font-size: 14px; } }

.landing-member {
  margin-bottom: 60px; }
  @media (max-width: 1000px) {
    .landing-member {
      margin-bottom: 0; } }
  .landing-member-wrapper {
    max-width: 1425px;
    padding: 0 10px;
    margin: 0 auto; }
  .landing-member .title-h2 {
    margin-bottom: 7.7rem; }
  .landing-member-item {
    padding: 5rem 6rem;
    box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
    margin-bottom: 20px; }
    @media (max-width: 768px) {
      .landing-member-item {
        padding: 4rem 5rem; } }
    @media (max-width: 600px) {
      .landing-member-item {
        padding: 3rem 20px; } }
    .landing-member-item:last-child {
      margin-bottom: 0; }
    .landing-member-item--header {
      display: flex;
      align-items: center;
      position: relative; }
      @media (max-width: 600px) {
        .landing-member-item--header {
          min-height: 60px;
          align-items: flex-start; } }
      .landing-member-item--header .title {
        width: 70.03891%;
        margin-left: 5.99222%; }
        @media (max-width: 600px) {
          .landing-member-item--header .title {
            margin-left: 0;
            width: 100%; } }
        .landing-member-item--header .title-header {
          font-size: 2.6rem;
          line-height: 3.5rem;
          font-weight: 700;
          color: #212324;
          display: block; }
          @media (max-width: 600px) {
            .landing-member-item--header .title-header {
              width: calc(100% - 100px); } }
        .landing-member-item--header .title .sub-header {
          display: block;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: #212324;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-top: 11px; }
      .landing-member-item--header .detail {
        display: block;
        font-size: 1.4rem;
        line-height: 2.4rem;
        text-transform: uppercase;
        color: #008EB9;
        padding-right: 33px;
        position: relative;
        margin-left: 7.7821%;
        cursor: pointer; }
        @media (max-width: 600px) {
          .landing-member-item--header .detail {
            position: absolute;
            top: 6px;
            right: -5px;
            padding-right: 27px; } }
        .landing-member-item--header .detail.active:before {
          transform: rotate(-225deg);
          transition: all .2s; }
        .landing-member-item--header .detail:before {
          content: '';
          display: block;
          position: absolute;
          top: -2px;
          right: 0;
          width: 14px;
          height: 14px;
          border-left: 2px solid #008EB9;
          border-bottom: 2px solid #008EB9;
          transform: rotate(-45deg);
          transform-origin: center;
          transition: all .2s; }
          @media (max-width: 600px) {
            .landing-member-item--header .detail:before {
              width: 10px;
              height: 10px;
              top: 0; } }
      .landing-member-item--header svg {
        width: 7.7821%;
        min-width: 75px; }
        @media (max-width: 768px) {
          .landing-member-item--header svg {
            min-width: 50px;
            height: 50px; } }
        @media (max-width: 600px) {
          .landing-member-item--header svg {
            position: absolute;
            opacity: .2;
            top: 0;
            left: 0; } }
    .landing-member-item--description {
      padding-top: 10px;
      display: none; }
      .landing-member-item--description ul {
        padding-left: 13.77%; }
        @media (max-width: 600px) {
          .landing-member-item--description ul {
            padding-left: 0; } }
        .landing-member-item--description ul li {
          padding-left: 3rem;
          margin-bottom: 1.5rem;
          position: relative;
          font-size: 1.5rem;
          line-height: 2.8rem;
          color: rgba(33, 35, 36, 0.8); }
          .landing-member-item--description ul li:before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #008EB9;
            position: absolute;
            left: .2rem;
            top: 1.2rem; }

.landing-bonus {
  overflow: hidden;
  position: relative; }
  .landing-bonus-bg {
    width: 100%;
    min-width: 1920px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1; }
    .landing-bonus-bg-bottom {
      bottom: 0; }
      .landing-bonus-bg-bottom img {
        width: 100%;
        height: 500px; }
  .landing-bonus-wrapper {
    max-width: 1425px;
    padding: 0 10px;
    margin: 0 auto;
    margin-top: 187px;
    position: relative; }
    @media (max-width: 600px) {
      .landing-bonus-wrapper {
        margin-top: 160px; } }
  .landing-bonus .title-h2 {
    margin-bottom: 6rem; }
    @media (max-width: 600px) {
      .landing-bonus .title-h2 {
        margin-bottom: 3rem; } }
  .landing-bonus .title-h2.white {
    color: #ffffff;
    margin-bottom: 53px; }
  .landing-bonus-offer {
    position: absolute;
    right: 94px;
    top: 30px;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700; }
    @media (max-width: 600px) {
      .landing-bonus-offer {
        right: 20px; } }
  .landing-bonus-rules {
    padding: 6rem 8.46975% 6rem 5.1rem;
    background-color: #ffffff;
    box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem; }
    @media (max-width: 1440px) {
      .landing-bonus-rules {
        padding-right: 5rem; } }
    @media (max-width: 768px) {
      .landing-bonus-rules {
        padding: 4rem 3rem; } }
    @media (max-width: 600px) {
      .landing-bonus-rules {
        flex-wrap: wrap; } }
    .landing-bonus-rules:last-child {
      margin-bottom: 27px; }
    .landing-bonus-rules--left {
      width: 217px;
      display: flex;
      flex-direction: column; }
      @media (max-width: 1000px) {
        .landing-bonus-rules--left {
          margin-right: 20px; } }
      @media (max-width: 768px) {
        .landing-bonus-rules--left {
          width: 180px; } }
      @media (max-width: 600px) {
        .landing-bonus-rules--left {
          width: 100%;
          margin-right: 0;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center; } }
    .landing-bonus-rules--right {
      width: 75.30364%; }
      @media (max-width: 600px) {
        .landing-bonus-rules--right {
          width: 100%;
          margin-top: 20px; } }
    .landing-bonus-rules--icon {
      margin-bottom: 3rem;
      margin-left: 9px; }
      @media (max-width: 768px) {
        .landing-bonus-rules--icon {
          margin-left: 0; } }
      @media (max-width: 600px) {
        .landing-bonus-rules--icon {
          margin-bottom: 0; } }
    .landing-bonus-rules--header {
      display: block;
      font-size: 2.6rem;
      line-height: 3.5rem;
      font-weight: 700;
      color: #212324;
      width: 200px;
      padding-bottom: 3rem;
      border-bottom: 2px solid #C2A271;
      margin-left: 9px;
      margin-bottom: 5.2rem; }
      @media (max-width: 768px) {
        .landing-bonus-rules--header {
          width: 100%;
          margin-left: 0; } }
      @media (max-width: 600px) {
        .landing-bonus-rules--header {
          width: auto;
          border: 0;
          margin-left: 20px;
          padding-bottom: 0;
          margin-bottom: 0;
          align-self: center; } }
    @media (max-width: 600px) {
      .landing-bonus-rules--btn {
        width: 100%;
        display: flex;
        justify-content: center; } }
    .landing-bonus-rules .btn-add {
      margin-left: 0;
      margin-top: 0; }
      @media (max-width: 600px) {
        .landing-bonus-rules .btn-add {
          margin-top: 20px;
          width: calc(50% - 10px);
          max-width: 160px; } }
      @media (max-width: 480px) {
        .landing-bonus-rules .btn-add {
          padding: 10px; } }
      .landing-bonus-rules .btn-add:first-of-type {
        margin-bottom: 2.3rem; }
        @media (max-width: 600px) {
          .landing-bonus-rules .btn-add:first-of-type {
            margin-bottom: 0;
            margin-right: 20px; } }
    .landing-bonus-rules--time {
      display: block;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #4D4F50;
      margin-bottom: 3rem; }
      @media (max-width: 600px) {
        .landing-bonus-rules--time {
          text-align: center; } }
    .landing-bonus-rules--text {
      display: block;
      font-size: 1.5rem;
      line-height: 2.8rem;
      color: rgba(33, 35, 36, 0.8);
      margin-top: 1.5rem; }
    .landing-bonus-rules ul li {
      padding-left: 44px;
      position: relative;
      font-size: 1.5rem;
      line-height: 2.8rem;
      color: rgba(33, 35, 36, 0.8);
      margin-top: 1.5rem; }
      @media (max-width: 600px) {
        .landing-bonus-rules ul li {
          padding-left: 34px; } }
      .landing-bonus-rules ul li:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        border: 3px solid #008EB9;
        border-radius: 50%;
        background-color: #10C0F5; }
        @media (max-width: 600px) {
          .landing-bonus-rules ul li:before {
            width: 20px;
            height: 20px;
            border: 2px solid #008EB9; } }
      .landing-bonus-rules ul li:after {
        content: '';
        display: block;
        position: absolute;
        width: 7px;
        height: 4px;
        border-left: 3px solid #ffffff;
        border-bottom: 3px solid #ffffff;
        transform: rotate(-45deg);
        top: 8px;
        left: 7px; }
        @media (max-width: 600px) {
          .landing-bonus-rules ul li:after {
            width: 6px;
            height: 3px;
            border-left: 2px solid #ffffff;
            border-bottom: 2px solid #ffffff;
            top: 7px;
            left: 6px; } }
    .landing-bonus-rules--attention {
      display: block;
      font-size: 1.8rem;
      line-height: 3.2rem;
      font-weight: 500;
      color: rgba(0, 142, 185, 0.8);
      text-transform: uppercase;
      margin-top: 3rem; }
    .landing-bonus-rules a {
      display: block;
      color: #008EB9;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 2.4rem;
      text-transform: uppercase;
      margin-top: 3rem; }
  .landing-bonus-rate {
    display: flex;
    margin-bottom: 20px; }
    @media (max-width: 600px) {
      .landing-bonus-rate {
        flex-wrap: wrap; } }
    .landing-bonus-rate--left {
      width: 21.06762%;
      margin-right: 4.27046%; }
      @media (max-width: 600px) {
        .landing-bonus-rate--left {
          width: 100%;
          margin-right: 0; } }
      .landing-bonus-rate--left .landing-bonus-rate--item {
        width: 100%; }
        .landing-bonus-rate--left .landing-bonus-rate--item ul {
          padding-left: 0;
          padding-right: 0; }
          @media (max-width: 600px) {
            .landing-bonus-rate--left .landing-bonus-rate--item ul {
              padding: 10px 0; } }
          .landing-bonus-rate--left .landing-bonus-rate--item ul li {
            border-color: transparent;
            text-align: right;
            font-size: 1.8rem;
            font-weight: 500;
            color: rgba(33, 35, 36, 0.8);
            text-transform: none; }
            @media (max-width: 600px) {
              .landing-bonus-rate--left .landing-bonus-rate--item ul li {
                text-align: center;
                padding: 0 5px;
                position: relative; }
                .landing-bonus-rate--left .landing-bonus-rate--item ul li:after {
                  content: '';
                  display: block;
                  width: 2px;
                  height: 25px;
                  background-color: #efe0c9;
                  position: absolute;
                  right: -2px;
                  top: 50%;
                  transform: translateY(-50%); }
                .landing-bonus-rate--left .landing-bonus-rate--item ul li:last-child:after {
                  content: none; } }
    .landing-bonus-rate--right {
      display: flex;
      flex-wrap: wrap;
      width: 74.59075%; }
      @media (max-width: 600px) {
        .landing-bonus-rate--right {
          width: 100%; } }
      .landing-bonus-rate--right .landing-bonus-rate--item {
        margin-right: 1.9084%; }
        @media (max-width: 600px) {
          .landing-bonus-rate--right .landing-bonus-rate--item {
            margin-right: 0;
            margin-bottom: 10px; }
            .landing-bonus-rate--right .landing-bonus-rate--item.quattro {
              margin-bottom: 0; } }
        .landing-bonus-rate--right .landing-bonus-rate--item.quattro {
          margin-right: 0; }
    .landing-bonus-rate--item {
      width: 32.06107%;
      border-radius: 4rem; }
      @media (max-width: 600px) {
        .landing-bonus-rate--item {
          display: flex;
          flex-direction: row-reverse;
          width: 100%; } }
      .landing-bonus-rate--item.start {
        background-color: #08A7D8; }
        .landing-bonus-rate--item.start .header {
          background-color: #1B77A1; }
      .landing-bonus-rate--item.turbo {
        background-color: #1B77A1; }
        .landing-bonus-rate--item.turbo .header {
          background-color: #0E6288; }
      .landing-bonus-rate--item.quattro {
        background-color: #DEBE8E; }
        .landing-bonus-rate--item.quattro .header {
          background-color: #C2A271; }
      .landing-bonus-rate--item .header {
        padding: 1.8rem;
        font-weight: 700;
        font-size: 2.6rem;
        line-height: 35px;
        text-align: center;
        border-radius: 4rem;
        text-transform: uppercase;
        color: #ffffff; }
        @media (max-width: 600px) {
          .landing-bonus-rate--item .header {
            word-break: break-word;
            max-width: 40px;
            line-height: 20px; } }
      .landing-bonus-rate--item ul {
        padding: 0.3rem 20.2381% 1.8rem; }
        @media (max-width: 600px) {
          .landing-bonus-rate--item ul {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 20px 0; } }
        .landing-bonus-rate--item ul li {
          text-align: center;
          font-size: 2.6rem;
          line-height: 3.5rem;
          text-transform: uppercase;
          color: #ffffff;
          font-weight: 700;
          padding: 2.7rem 0;
          border-bottom: 2px solid rgba(33, 35, 36, 0.3); }
          @media (max-width: 600px) {
            .landing-bonus-rate--item ul li {
              width: 25%;
              border-bottom: 0;
              border-right: 2px solid rgba(33, 35, 36, 0.3);
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box; } }
          .landing-bonus-rate--item ul li:last-child {
            border: 0; }
    .landing-bonus-rate--description {
      width: 100%;
      margin-top: 29px;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 3px 0; }
      .landing-bonus-rate--description span {
        display: block;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: rgba(33, 35, 36, 0.8); }

.landing-map {
  margin-bottom: 6rem; }
  .landing-map .title-h2 {
    margin-bottom: 5rem; }
    .landing-map .title-h2 span {
      font-size: 1.8rem;
      line-height: 3.2rem;
      font-weight: 500;
      text-align: center;
      color: #212324; }
  .landing-map .yandex-map {
    height: 500px; }

.landing-action .main-news__item {
  padding-bottom: 3.6rem;
  box-shadow: none;
  border: 0; }
  .landing-action .main-news__item-content {
    padding: 40px 30px 30px;
    border: 1px solid #EAEAEA;
    position: relative; }
  .landing-action .main-news__item-percent {
    position: absolute;
    top: 0;
    height: 10.1rem;
    width: 16rem;
    display: flex;
    align-items: center;
    z-index: 1;
    right: 2.7rem; }
    .landing-action .main-news__item-percent span {
      display: block;
      margin: 0 auto;
      font-size: 4rem;
      line-height: 3.5rem;
      font-weight: 700;
      color: #ffffff; }
    .landing-action .main-news__item-percent:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #C2A271;
      transform: skewx(-28.5deg);
      top: 0;
      left: 0;
      z-index: -1; }
  .landing-action .main-news__item-top {
    height: 370px; }
    .landing-action .main-news__item-top:after {
      content: none; }
  .landing-action .main-news__item-img {
    min-height: auto;
    min-width: auto; }
  .landing-action .main-news__item-text {
    padding: 0;
    font-size: 1.8rem;
    line-height: 3.2rem;
    font-weight: 500;
    color: #212324; }
  .landing-action .main-news__item-forward {
    left: 0;
    top: calc(100% + 20px);
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #BCBDBD;
    font-weight: 400; }
    .landing-action .main-news__item-forward:after {
      content: none; }

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline !important; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #999 !important;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  table {
    border-collapse: collapse !important; }
    table td,
    table th {
      background-color: #fff !important; } }
