.text{
  .wrapper{
    max-width: 1435px;
    padding: 0 10px;
    padding-top: 30px;
  }
  &-block{
    section{
      margin-bottom: 3rem;
    }
    h2{
      font-weight: $font-weight--700;
      font-size: 2.6rem;
      line-height: 3.5rem;
      color: $base-color;
      margin-bottom: 1.5rem;
    }
    p{
      font-weight: $font-weight--400;
      font-size: 1.5rem;
      line-height: 2.8rem;
      color: $base-color-80;
      margin-bottom: 1.5rem;
    }
    img{
      max-width: 1168px;
      display: block;
      margin: 0 auto;
      margin-bottom: 1.5rem;
      width: 100%;
    }
    figure{
      max-width: 1168px;
      margin: 0 auto;
      margin-bottom: 3rem;
      figcaption{
        color: $figcaption-color-80;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: $font-weight--400;
      }
    }
    h3{
      font-weight: $font-weight--500;
      font-size: 1.8rem;
      line-height: 3.2rem;
      margin-bottom: 1.5rem;
      color: $base-color-80;
    }
    ul{
      margin-bottom: 1.5rem;
      li{
        padding-left: 3rem;
        margin-bottom: 1.5rem;
        position: relative;
        font-size: 1.5rem;
        line-height: 2.8rem;
        color: $base-color-80;
        &:before{
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $blue;
          position: absolute;
          left: .2rem;
          top: 1.2rem;
        }
      }
    }
    ol{
      margin-bottom: 1.5rem;
      counter-reset: list;
      li{
        padding-left: 3rem;
        margin-bottom: 1.5rem;
        position: relative;
        font-size: 1.5rem;
        line-height: 2.8rem;
        color: $base-color-80;
        &:before{
          counter-increment: list;
          content: counter(list);
          color: $blue-dark-80;
          font-weight: $font-weight--700;
          position: absolute;
          left: 0;
          padding-right: .9rem;
          background-image: url("../images/list-rect.png");
          background-repeat: no-repeat;
          background-position: right center;
        }
      }
    }
    table{
      margin: 0 auto;
      overflow-x: auto;
      max-width: 100%;
      border-collapse: collapse;
      display: flex;
      margin-bottom: 3rem;
      border: 0;
      border-spacing: 0;
      th{
        background-color: $blue;
        color: $white;
        padding: .8rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-align: left;
        min-width: 150px;
      }
      td{
        color: $base-color-80;
        font-size: 1.5rem;
        line-height: 2.8rem;
        padding: .8rem;
        min-width: 150px;
      }
      tr:nth-child(even){
        background-color: $footer-bg;
      }
    }
  }
  &-video{
    position: relative;
    max-width: 1168px;
    margin: 0 auto;
    margin-bottom: 6rem;
    .video-img{
      position: absolute;
      z-index: 2;
      cursor: pointer;
      img{
        margin-bottom: 0;
      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        width: 70px;
        height: 70px;
        background-color: $blue;
        top: calc(50% - 35px);
        left: calc(50% - 35px);
        background-image: url("../images/play.svg");
        background-repeat: no-repeat;
        background-position: center;
        @include transition();
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(33, 35, 36, 0.5), rgba(33, 35, 36, 0.5));
        opacity: 0;
        @include transition();
      }
      &:hover{
        &:before{
          opacity: 1;
          @include transition();
        }
        &:after{
          border-radius: 50%;
        }
      }
    }
    &-wrapper{
      padding-bottom: 56.25%;
      position: relative;
      width: 100%;
    }
    .video-frame{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border: none;
      margin: auto;
      display: block;
      max-width: 1168px;
    }
  }
  &-slider{
    overflow: hidden;
    position: relative;
    max-width: 1168px;
    margin: 0 auto;
    .swiper-slide{
      &-inner{
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        img{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          max-width: 100%;
          max-height: 100%;
          margin-bottom: 0;
        }
      }
    }

    &-nav{
      position: relative;
      top: -22px;
      margin: 0 auto;
      padding: 0 119px;
      overflow: hidden;
      @media (max-width: 600px) {
        padding: 0 58px;
      }
      @media (max-width: 480px) {
        padding: 0 30px;
        top: -10px;
      }
      .wrapper{
        width: 100%;
      }
      .swiper-slide{
        max-width: 218px;
        opacity: 0;
        transition: opacity .5s;
        &-visible{
          opacity: 1;
          transition: opacity .5s;
          &>div{
            position: relative;
            &:after{
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              background: linear-gradient(0deg, rgba(33, 35, 36, 0.5), rgba(33, 35, 36, 0.5));
            }
          }
        }
        &-thumb-active{
          &>div{
            position: relative;
            &:after{
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              background: linear-gradient(0deg, rgba(27, 119, 161, 0.42), rgba(27, 119, 161, 0.42));
            }
          }
        }
      }

    }

    .swiper-button{
        &-prev, &-next{
          background-image: none;
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          border: 2px solid $blue;
          outline: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          bottom: 0;
          @media (max-width: 480px) {
            width: 25px;
            height: 25px;
          }
        }
        &-next{
          right: 39px;
          @media (max-width: 600px) {
            right: 8px;
          }
          @media (max-width: 480px) {
            right: 0;
            transform: rotate(180deg);
            top: 60%;
          }
          @media (max-width: 400px){
            top: 65%;
          }
          &:before{
            content: '';
            display: block;
            position: absolute;
            height: 2px;
            width: 12px;
            background-color: $blue-dark;
            transform: rotate(-45deg);
            bottom: 13px;
          }
          &:after{
            content: '';
            display: block;
            position: absolute;
            height: 2px;
            width: 12px;
            background-color: $blue-dark;
            transform: rotate(45deg);
            top: 13px;
          }
        }
        &-prev{
          left: 39px;
          @media (max-width: 600px) {
            left: 8px;
          }
          @media (max-width: 480px) {
            left: 0;
            transform: rotate(180deg);
            top: 60%;
          }
          @media (max-width: 400px){
            top: 65%;
          }
          &:before{
            content: '';
            display: block;
            position: absolute;
            height: 2px;
            width: 12px;
            background-color: $blue-dark;
            transform: rotate(-45deg);
            top: 13px;
          }
          &:after{
            content: '';
            display: block;
            position: absolute;
            height: 2px;
            width: 12px;
            background-color: $blue-dark;
            transform: rotate(45deg);
            bottom: 13px;
          }
        }
      }
  }
}
