.main-program{
  background: $blue-dark;
  padding: 8.2rem 0 4rem;
  position: relative;
  @media (max-width: 1000px){
    padding: 6.2rem 0 0;
  }
  @media (max-width: 768px) {
    padding: 2rem 0 0;
  }
  &__wrapper{
    max-width: 1379px;
    display: flex;
    align-items: flex-start;
    padding-bottom: 6.8rem;
    position: relative;
    z-index: 1;
    @media (max-width: 768px) {
      padding-bottom: 8.8rem;
    }
      &::after{
        content: attr(data-bg-text);
        width: 107%;
        position: absolute;
        top: 32%;
        left: 14.3rem;
        color: rgba(0, 142, 185, 0.5);
        font-size: 20rem;
        font-weight: 900;
        line-height: 20rem;
        transform: translateY(-50%);
        letter-spacing: 0.1em;
        z-index: -1;

        @media (max-width: 1850px){
            width: 91%;
            font-size: 16.8rem;
            top: 32.5%;
        }
        @media (max-width: 1440px){
          width: 84.3%;
          font-size: 13.5rem;
          top: 33.5%;
          left: 18.3rem;
        }
        @media (max-width: 1000px){
          width: 80%;
          font-size: 10.3rem;
        }
        @media (max-width: 768px){
          width: 100%;
          left: 0;
          font-size: 8.3rem;
          text-align: center;
        }
        @media (max-width: 768px){
          font-size: 5.3rem;
        }
      }

  }
  &__icon{
    width: 130px;
    min-width: 130px;
    height: 130px;
    background: $brown;
    border: 4px solid $white;
    border-radius: 50%;
    position: relative;
    margin-right: 6.4rem;
    @media (max-width: 768px) {
      display: none;
    }
    &::after{
      content: '';
      width: 76px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../images/program-icon.svg);
      -webkit-background-size: contain;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
  &__title{
    margin-bottom: 2.4rem;
    @media (max-width: 768px) {
      text-align: center;
      margin-bottom: 1.4rem;
    }
  }
  &__text{
    width: percentage(676 / 1250);
    @media (max-width: 1000px){
      width: 74%;
    }
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
    .text{
      width: 100%;
      @media (max-width: 768px) {
        margin-bottom: 1.2rem;
      }
    }
  }
  &__btn{
    position: absolute;
    right: 0;
    align-self: center;
    @media (max-width: 1440px){
      right: 25px;
    }
    @media (max-width: 1000px){
      bottom: 15px;
    }
  }
}