.request-text {
  padding-bottom: 60px;
  @media (max-width: 1000px) {
    padding-bottom: 50px;
  }
  @media (max-width: 768px) {
    padding-bottom: 40px;
  }
  @media (max-width: 600px) {
    padding-bottom: 30px;
  }
  p {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: rgba(33, 35, 36, 0.8);
    margin-bottom: 1.5rem;
    &:last-child{
      margin-bottom: 0;
    }
  }
}