.landing-action{
  .main-news{
    &__item{
      padding-bottom: 3.6rem;
      box-shadow: none;
      border: 0;
      &-content{
        padding: 40px 30px 30px;
        border: 1px solid #EAEAEA;
        position: relative;
      }
      &-percent{
        position: absolute;
        top: 0;
        height: 10.1rem;
        width: 16rem;
        display: flex;
        align-items: center;
        z-index: 1;
        right: 2.7rem;
        span{
          display: block;
          margin: 0 auto;
          font-size: 4rem;
          line-height: 3.5rem;
          font-weight: $font-weight--700;
          color: $white;
        }
        &:before{
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          background-color: $brown;
          transform: skewx(-28.5deg);
          top: 0;
          left: 0;
          z-index: -1;
        }
      }
      &-top{
        height: 370px;
        &:after{
          content: none;
        }
      }
      &-img{
        min-height: auto;
        min-width: auto;
      }
      &-text{
        padding: 0;
        font-size: 1.8rem;
        line-height: 3.2rem;
        font-weight: $font-weight--500;
        color: $base-color;
      }
      &-forward{
        left: 0;
        top: calc(100% + 20px);
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $gray-paginator;
        font-weight: $font-weight--400;
        &:after{
          content: none;
        }
      }
    }
  }
}