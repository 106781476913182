.about {
  padding-top: 4rem;

  &__wrapper {
    max-width: 1430px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px){
      flex-wrap: wrap;
    }
  }

  &-text {
    width: calc(50% - 10px);
    max-width: 586px;
    @media (max-width: 480px){
      width: 100%;
      order: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      margin-bottom: 2.5rem;
      @media (max-width: 480px){
        text-align: center;
        margin-top: 2.5rem;
      }
    }

    &__btn {
      margin-top: 5rem;
    }
  }

  &-img {
    width: calc(50% - 10px);
    max-width: 738px;
    @media (max-width: 480px){
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  &-numbers {
    width: 100%;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    margin-top: 6.7rem;
    background-color: $icons-bg;
    position: relative;
    @media (max-width: 768px){
      flex-wrap: wrap;
    }
    &:before{
      content: '';
      display: block;
      height: 67px;
      position: absolute;
      bottom: 0;
      background-color: $blue;
      width: 100%;
    }
    &__item {
      width: 25%;
      position: relative;
      background: $icons-bg;
      max-width: 480px;
      @media (max-width: 768px){
        width: 50%;
      }
      @media (max-width: 480px){
        width: 100%;
      }
      &:hover {

        .about-numbers__item-inner {
          &::after {
            height: 100%;
          }
        }

        .about-numbers__item-icon {
          right: 50%;
          top: 4.2rem;
          transform: translateX(50%) translateY(0);
          height: 190px;

          path {
            fill: #D7B581;
          }
        }

        .about-numbers__item-number{
          color: $white;
          font-size: 12rem;
          left: 50%;
          top: 0;
          transform: translateX(-50%) translateY(4.2rem);
          @media(max-width: 1440px){
            font-size: 10rem;
          }
          @media (max-width: 1000px) {
            font-size: 9rem;
          }
          @media (max-width: 768px) {
            font-size: 12rem;
          }
        }

        .about-numbers__item-text {
          &:after {
            height: calc(100% + 200px);
          }

          span {
            transform: translateY(-25px);
          }
        }
      }

      &-inner {
        /*position: absolute;
        width: 100%;
        height: 100%;*/
        z-index: 0;

        &::before {
          content: '';
          width: 100%;
          height: 67px;
          background: $blue;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &::after {
          content: '';
          z-index: 1;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: $brown;
          box-shadow: 0px 8px 16px rgba(33, 35, 36, 0.3);
          height: 0;
          @include transition(.3s);
        }
      }

      &-content {
        width: 100%;
        height: 200px;
        position: relative;
        z-index: 2;
      }

      &-icon {
        height: 100px;
        position: absolute;
        top: 50%;
        right: calc(50% + 8px);
        transform: translateY(-50%);
        @include transition(.3s);
        @media (max-width: 1000px){
          margin-left: 10px;
        }
        svg {
          height: 100%;
          width: auto;
          @media (max-width: 1000px){
            width: 80%;
          }
          @media (max-width: 480px){
            width: 100%;
          }
        }

        path {
          @include transition(.3s);
        }
      }

      &-number {
        position: absolute;
        top: 50%;
        left: calc(50% + 8px);
        transform: translateY(-50%);
        font-size: 6rem;
        font-weight: 900;
        color: #000;
        letter-spacing: 0.1em;
        @include transition(.3s);
        @media (max-width: 1440px){
          font-size: 5rem;
        }
        @media (max-width: 1000px){
          font-size: 4rem;
        }
        @media (max-width: 480px){
          font-size: 6rem;
        }
      }

      &-text {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 5px;
        height: 67px;
        position: relative;
        z-index: 3;

        span {
          font-size: 2rem;
          line-height: 3.2rem;
          color: $white;
          position: relative;
          z-index: 1;
          @include transition(.3s);
        }
      }
    }
  }



}