.title-h2{
  width: 100%;
  text-align: center;
  font-weight: 900;
  color: $blue-dark;
  font-size: 3.4rem;
  line-height: 4.8rem;
  &--left{
    text-align: left;
  }
  &--white{
    color: $white;
  }
  h2{
    font-size: inherit;
    font-weight: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    line-height: inherit;
  }
}
.title-h1{
  width: 100%;
  text-align: center;
  font-weight: 900;
  color: $blue-dark;
  font-size: 3.4rem;
  line-height: 4.8rem;
  &--left{
    text-align: left;
  }
  &--white{
    color: $white;
  }
  h1{
    font-size: inherit;
    font-weight: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    line-height: inherit;
  }
}

.text{
  color: $base-color;
  font-size: 1.5rem;
  line-height: 2.8rem;
  font-weight: 400;
  &--white{
    color: $white;
  }
}

a{
  text-decoration: none;
  &:hover{
    text-decoration: none;
  }
}