.main-slider {
  max-width: 1920px;
  &__slide {
    position: relative;
    overflow: hidden;
    height: 690px;
    min-height: 690px;
    @media (max-width: 1440px){
      height: 550px;
      min-height: 550px;
    }
    @media (max-width: 768px) {
      height: 400px;
      min-height: 400px;
    }
    &-img{
      height: 100%;
      width: 100%;
      position: absolute;
      img{
        height: 100%;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__content {
    background: rgba(255, 255, 255, .8);
    padding: 78px 50px 36px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
    width: 574px;
    margin-right: 23.6rem;
    height: 496px;
    @media (max-width: 1440px){
      padding: 58px 42px 30px;
      width: 486px;
      height: 386px;
      margin-right: 14rem;
    }
    @media (max-width: 1000px){
      padding-right: 75px;
    }
    @media (max-width: 768px){
      margin-right: 0;
      width: 100%;
      height: 300px;
      padding-right: 42px;
    }
    @media (max-width: 480px){
      padding: 45px 20px;
    }
    .arrows{
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-start;
      .arrow{
        width: 46px;
        height: 46px;
        background: $blue-dark;
        border: 0;
        @include transition();
        position: relative;
        @media (max-width: 768px){
          width: 40px;
          height: 40px;
        }
        &::after{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 17px;
          height: 28px;
          background-image: url(../images/main-slider-arrow.svg);
          background-position: center center;
          -webkit-background-size: contain;
          background-size: contain;
          background-repeat: no-repeat;
        }
        &:hover{
          background: $blue;
        }
        &:active{
          background: $blue-dark;
        }
        &.prev{
          &::after{
          transform: translate(-50%, -50%) rotate(180deg);
        }
        }
      }
    }

  }

  &__title{
    max-width: 400px;
    font-weight: 900;
    font-size: 3.4rem;
    line-height: 4.8rem;
    margin-bottom: 2.5rem;
    @media (max-width: 768px) {
      margin: 0 auto;
      margin-bottom: 2.5rem;
      text-align: center;
    }
  }

  .text{
    max-width: 400px;
    //margin-bottom: 8.5rem;
    @media (max-width: 768px) {
      margin: 0 auto;
      text-align: center;
    }
  }

  .btn{
    position: absolute;
    bottom: 54px;
    right: -40px;
    @media (max-width: 1440px) {
      bottom: 44px;
    }
    @media (max-width: 768px){
      bottom: 30px;
      right: 25px;
    }
    @media (max-width: 480px){
      bottom: 22px;
    }
  }

  .swiper-pagination{
    bottom: 3.8rem;
    @media (max-width:  1440px){
      bottom: 2.8rem;
    }
    &-bullet{
      background: #fff;
      border: 1px solid $brown;
      width: 8px;
      height: 8px;
      opacity: 1;
      margin: 0 8px;
      @include transition();
      &-active{
        background: $brown;
        border: 1px solid #fff;
        transform: scale(1.45);
      }
    }
  }

}