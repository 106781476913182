.landing-bonus{
  overflow: hidden;
  position: relative;
  &-bg{
    width: 100%;
    min-width: 1920px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    &-bottom{
      bottom: 0;
      img{
        width: 100%;
        height: 500px;
      }
    }
  }
  &-wrapper{
    max-width: 1425px;
    padding: 0 10px;
    margin: 0 auto;
    margin-top: 187px;
    position: relative;
    @media (max-width: 600px){
      margin-top: 160px;
    }
  }
  .title-h2{
    margin-bottom: 6rem;
    @media (max-width: 600px){
      margin-bottom: 3rem;
    }
  }
  .title-h2.white{
    color: $white;
    margin-bottom: 53px;
  }
  &-offer{
    position: absolute;
    right: 94px;
    top: 30px;
    font-size: 1.4rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    color: $white;
    font-weight: $font-weight--700;
    @media (max-width: 600px){
      right: 20px;
    }
  }
  &-rules{
    padding: 6rem percentage(119 / 1405) 6rem 5.1rem;
    background-color: $white;
    box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;
    @media (max-width: 1440px){
      padding-right: 5rem;
    }
    @media (max-width: 768px){
      padding: 4rem 3rem;
    }
    @media (max-width: 600px){
        flex-wrap: wrap;
    }
    &:last-child{
      margin-bottom: 27px;
    }
    &--left{
      width: 217px;
      display: flex;
      flex-direction: column;
      @media (max-width: 1000px){
        margin-right: 20px;
      }
      @media (max-width: 768px){
        width: 180px;
      }
      @media (max-width: 600px){
        width: 100%;
        margin-right: 0;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &--right{
      width: percentage(930 / 1235);
      @media (max-width: 600px){
        width: 100%;
        margin-top: 20px;
      }
    }
    &--icon{
      margin-bottom: 3rem;
      margin-left: 9px;
      @media (max-width: 768px){
        margin-left: 0;
      }
      @media (max-width: 600px){
        margin-bottom: 0;
      }
    }
    &--header{
      display: block;
      font-size: 2.6rem;
      line-height: 3.5rem;
      font-weight: $font-weight--700;
      color: $base-color;
      width: 200px;
      padding-bottom: 3rem;
      border-bottom: 2px solid $brown;
      margin-left: 9px;
      margin-bottom: 5.2rem;
      @media (max-width: 768px){
        width: 100%;
        margin-left: 0;
      }
      @media (max-width: 600px){
        width: auto;
        border: 0;
        margin-left: 20px;
        padding-bottom: 0;
        margin-bottom: 0;
        align-self: center;
      }
    }
    &--btn{
      @media (max-width: 600px){
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    .btn-add{
      margin-left: 0;
      margin-top: 0;
      @media (max-width: 600px){
        margin-top: 20px;
        width: calc(50% - 10px);
        max-width: 160px;
      }
      @media (max-width: 480px){
         padding: 10px;
      }
      &:first-of-type{
        margin-bottom: 2.3rem;
        @media (max-width: 600px){
          margin-bottom: 0;
          margin-right: 20px;
        }
      }
    }
    &--time{
      display: block;
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $black-light;
      margin-bottom: 3rem;
      @media (max-width: 600px){
        text-align: center;
      }
    }
    &--text{
      display: block;
      font-size: 1.5rem;
      line-height: 2.8rem;
      color: $base-color-80;
      margin-top: 1.5rem;
    }
    ul{
      li{
        padding-left: 44px;
        position: relative;
        font-size: 1.5rem;
        line-height: 2.8rem;
        color: $base-color-80;
        margin-top: 1.5rem;
        @media (max-width: 600px){
          padding-left: 34px;
        }
        &:before{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 24px;
          height: 24px;
          box-sizing: border-box;
          border: 3px solid $blue;
          border-radius: 50%;
          background-color: $blue-sky;
          @media (max-width: 600px){
            width: 20px;
            height: 20px;
            border: 2px solid $blue;
          }
        }
        &:after{
          content: '';
          display: block;
          position: absolute;
          width: 7px;
          height: 4px;
          border-left: 3px solid $white;
          border-bottom: 3px solid $white;
          transform: rotate(-45deg);
          top: 8px;
          left: 7px;
          @media (max-width: 600px){
            width: 6px;
            height: 3px;
            border-left: 2px solid $white;
            border-bottom: 2px solid $white;
            top: 7px;
            left: 6px;
          }
        }
      }
    }
    &--attention{
      display: block;
      font-size: 1.8rem;
      line-height: 3.2rem;
      font-weight: $font-weight--500;
      color: $blue-80;
      text-transform: uppercase;
      margin-top: 3rem;
    }
    a{
      display: block;
      color: $blue;
      font-weight: $font-weight--700;
      font-size: 1.4rem;
      line-height: 2.4rem;
      text-transform: uppercase;
      margin-top: 3rem;
    }
  }
  &-rate{
    display: flex;
    margin-bottom: 20px;
    @media (max-width: 600px) {
      flex-wrap: wrap;
    }
    &--left{
      width: percentage(296 / 1405);
      margin-right: percentage(60 / 1405);
      @media (max-width: 600px) {
        width: 100%;
        margin-right: 0;
      }
      .landing-bonus-rate--item{
        width: 100%;

        ul{
          padding-left: 0;
          padding-right: 0;
          @media (max-width: 600px) {
            padding: 10px 0;
          }
          li{
            border-color: transparent;
            text-align: right;
            font-size: 1.8rem;
            font-weight: $font-weight--500;
            color: $base-color-80;
            text-transform: none;
            @media (max-width: 600px) {
              text-align: center;
              padding: 0 5px;
              position: relative;
              &:after{
                content: '';
                display: block;
                width: 2px;
                height: 25px;
                background-color: lighten($gray-light, 15%);
                position: absolute;
                right: -2px;
                top: 50%;
                transform: translateY(-50%);
              }
              &:last-child{
                &:after{
                  content: none;
                }
              }
            }
          }
        }
      }
    }
    &--right{
      display: flex;
      flex-wrap: wrap;
      width: percentage(1048 / 1405);
      @media (max-width: 600px) {
        width: 100%;
      }
      .landing-bonus-rate--item{
        margin-right: percentage(20 / 1048);
        @media (max-width: 600px) {
          margin-right: 0;
          margin-bottom: 10px;
          &.quattro{
            margin-bottom: 0;
          }
        }
        &.quattro{
          margin-right: 0;
        }
      }
    }
    &--item{
      width: percentage(336 / 1048);
      border-radius: 4rem;
      @media (max-width: 600px) {
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
      }
      &.start{
        background-color: $blue-solight;
        .header{
          background-color: $blue-dark;
        }
      }
      &.turbo{
        background-color: $blue-dark;
        .header{
           background-color: $blue-sodark;
        }
      }
      &.quattro{
        background-color: $gray-light;
        .header{
          background-color: $brown;
        }
      }
      .header{
        padding: 1.8rem;
        font-weight: $font-weight--700;
        font-size: 2.6rem;
        line-height: 35px;
        text-align: center;
        border-radius: 4rem;
        text-transform: uppercase;
        color: $white;
        @media (max-width: 600px) {
          word-break: break-word;
          max-width: 40px;
          line-height: 20px;
        }
      }
      ul{
        padding: .3rem percentage(68 / 336) 1.8rem;
        @media (max-width: 600px) {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 20px 0;
          }
        li{
          text-align: center;
          font-size: 2.6rem;
          line-height: 3.5rem;
          text-transform: uppercase;
          color: $white;
          font-weight: $font-weight--700;
          padding: 2.7rem 0;
          border-bottom: 2px solid $base-color-30;
          @media (max-width: 600px) {
              width: 25%;
              border-bottom: 0;
              border-right: 2px solid $base-color-30;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            box-sizing: border-box;
            }
          &:last-child{
            border: 0;
          }
        }
      }
    }
    &--description{
      width: 100%;
      margin-top: 29px;
      background-color: rgba(255,255,255, .8);
      padding: 3px 0;
      span{
        display: block;
        font-size: 1.2rem;
        line-height: 1.6rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $base-color-80;
      }
    }
  }
}