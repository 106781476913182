.error{
  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 10px;
    @media(max-width: 1000px){
       padding: 50px 10px;
    }
    @media(max-width: 768px){
       padding: 40px 10px;
    }
    @media(max-width: 600px){
       padding: 30px 10px;
    }
  }
  .header-block{
    text-align: center;
    h1{
      @media (max-width: 600px){
        font-size: 3.2rem;
      }
    }
  }
  &-image{
    margin-bottom: 5.8rem;
    svg{
      transform-origin: 50% 50%;
      width: 100%;
      height: auto;
    }
    .arrow{
      transform-box: fill-box;
      transform-origin: 100% 80%;
      animation-name: beginning-error, error;
      animation-duration: 2s, 1.5s;
      animation-delay: 0s, 2s;
      animation-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1), cubic-bezier (.8,1.5,.2,-1);
      animation-iteration-count: 1, infinite;
    }

  }
  &-description{
    font-size: 1.5rem;
    line-height: 2.8rem;
    text-align: center;
    color: $base-color-80;
    max-width: 450px;
    margin-bottom: 6rem;
    a{
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-weight: $font-weight--700;
      color: $blue-dark-80;
      text-transform: uppercase;
    }
  }
  .btn{
    padding: 10px 24px;
    &:after{
        top: calc(50% - 2px);
      }
  }
}
@keyframes beginning-error {
  85%{
    transform: rotate(135deg) ;
    animation-timing-function: ease-in;
  }
  100%{
    transform: rotate(-10deg);
  }
}
@keyframes error {
  0%{
    transform: rotate(-10deg);
  }
  90%{
    transform: rotate(10deg);
  }
  100%{
    transform: rotate(-10deg);
  }
}