.main-services {
  padding-top: 7rem;
  padding-bottom: 5rem;
  background: $footer-bg;
  @media (max-width: 768px) {
    padding-top: 2rem;
  }
  &__wrapper {
    padding: 0 20px;
    @media (max-width: 1440px){
      padding: 0 10px;
    }
    & > .main-services__container{
        margin-top: 60px;
      }
    @media (max-width: 1000px){
      & > .main-services__container{
        flex-wrap: wrap;
        margin: auto;
        margin-top: 2.8rem;
        max-width: 680px;
      }
    }
  }

  &__container {

    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1000px){
      flex-wrap: nowrap;
      width: 100%;
      margin: 0;
    }
    &.services-swiper-container{
      overflow: hidden;
      position: relative;
    }
    .swiper-button-disabled{
      opacity: .5;
    }
    .services-swiper-button-prev, .services-swiper-button-next{
        display: none;
      @media (max-width: 1000px) {
        display: block;
        width: 40px;
        height: 40px;
        background: #1B77A1;
        border: 0;
        outline: 0;
        transition: all 0.2s ease-in-out 0s;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        &:after{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 17px;
          height: 28px;
          background-image: url(../images/main-slider-arrow.svg);
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
    .services-swiper-button-prev{
      &:after{
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
    .services-swiper-button-next{
      right: 0;

    }
  }



  &__item {
    width: calc(50% - 10px);
    height: 290px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: flex;
    &:nth-child(2n){
      margin-right: 0;
    }
    @media (max-width: 1000px) {
      width: 100%;
      height: 200px;
      margin: 0;
    }
    @media (max-width: 1000px) {
        flex-wrap: wrap;
        height: auto;
    }
    &:hover{
      .main-services__item-text-inner{
        color: $brown;
      }
      .main-services__item-img img{
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
    &:active{
      .main-services__item-text-inner{
        color: $brown-dark;
      }
      .main-services__item-img img{
        transform: translate(-50%, -50%) scale(1.05);
      }
    }

    &-text {
      width: 300px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5rem 3.5rem 5rem 5rem;
      @media (max-width: 1440px){
        width: 200px;
        padding: 3rem;
      }
      @media (max-width: 1000px) {
        width: 350px;
      }
      @media (max-width: 1000px) {
        width: 100%;
        height: 87px;
      }
      &-inner {
        font-size: 2.6rem;
        line-height: 3.5rem;
        font-weight: 600;
        color: #000;
        padding-bottom: 3rem;
        width: 100%;
        position: relative;
        @include transition();

        &::after {
          content: '';
          width: calc(100% - 30px);
          height: 2px;
          background: $brown-dark;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }

    &-img{
      width: calc(100% - 300px);
      height: 100%;
      position: relative;
      overflow: hidden;
      @media (max-width: 1440px){
        width: calc(100% - 200px);
      }
       @media (max-width: 1000px) {
        width: calc(100% - 350px);
      }
      @media (max-width: 1000px) {
        width: 100%;
        height: 213px;

      }
      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        @include transition();
      }
    }

  }
}