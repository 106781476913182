.gas-station-map{
  .ymaps-2-1-75-balloon{
  }
  .ymaps-2-1-75-image-with-content-content{
    width: 30px!important;
    height: 30px!important;
    border-radius: 50%;
    top: 4px!important;
    left: 4px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px!important;
    line-height: 16px!important;
    font-family: $font-family!important;
    color: $base-color-80;
  }
  .ymaps-2-1-75-balloon__content{
    margin-right: 55px!important;
    padding: 24px 0 26px 20px!important;
    @media (max-width: 600px){
      padding: 14px 0 16px 10px!important;
    }
    @media (max-width: 600px){
      & > ymaps{
        height: auto!important;
      }
    }
    .ymaps-2-1-75-balloon-content__header{
      padding-left: 41px;
      position: relative;
      font-size: 15px;
      line-height: 28px;
      color: $base-color;
      margin-bottom: 8px;
    }
    .ymaps-2-1-75-balloon-content__footer{
      height: auto;
    }
    .gasoline-phone{
      padding-left: 41px;
      background-image: url("../images/phone.svg");
      background-repeat: no-repeat;
      background-position: 4px 5px;
      margin-bottom: 5px;
        &-item{
          font-weight: $font-weight--700;
          color: $base-color-80;
          font-size: 14px;
          line-height: 24px;
        }
    }
    .gasoline-info{
      padding-left: 41px;
      background-image: url("../images/address.svg");
      background-repeat: no-repeat;
      background-position: 3px 3px;
      margin-bottom: 15px;
      font-size: 12px;
      line-height: 16px;
      font-weight: $font-weight--400;
      address{
        color: $base-color-80;
      }
      &-gps{
        display: block;
        color: $gray-paginator;
        opacity: .8;
        &--coordinates{
          color: $blue-dark;
        }
      }
      &-time{
        display: block;
        color: $gray-paginator;
        opacity: .8;
        .time{
          color: $base-color;
        }
      }
    }
    .gasoline-option{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &-image{
        margin-right: 15px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
          height: 30px;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
  .ymaps-2-1-75-balloon__close{
    top: 20px;
    right: 20px;
    &-button{
      width: 24px;
      height: 24px;
      background: url("../images/close-baloon.svg") no-repeat;
    }
  }
}