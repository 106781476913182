.request-page {
  .wrapper {
    max-width: 1435px;
    padding: 0 10px;
    padding-top: 30px;
    padding-bottom: 60px;
    @media (max-width: 1000px) {
      padding-bottom: 50px;
    }
    @media (max-width: 768px) {
      padding-bottom: 40px;
      padding-top: 25px;
    }
    @media (max-width: 600px) {
      padding-bottom: 30px;
      padding-top: 20px;
    }
  }
}