.main-advantages{
  margin-top: 4.6rem;
  position: relative;
  &::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: $footer-bg;
  }
  &__wrapper{
    max-width: 1430px;
    position: relative;
    z-index: 1;
    @media (max-width: 1000px) {
    & > .main-advantages__container{
      overflow: hidden;
      max-width: 680px;
      margin: auto;
    }
  }
  }
  &__title{
    margin-bottom: 1.6rem;
  }
  &__text{
    max-width: 692px;
    margin: 0 auto 4rem;
    color: $base-color-30;
    text-align: center;
  }
  &__container{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: auto;
    @media (max-width: 1000px){
      width: 100%;
      margin: 0;
      .swiper-wrapper{
        flex-wrap: nowrap;
        align-items: stretch;
        height: auto;
      }
    }
    .swiper-button-disabled{
      opacity: .5;
    }
    .advantages-swiper-button-prev, .advantages-swiper-button-next{
        display: none;
      @media (max-width: 1000px) {
        display: block;
        width: 40px;
        height: 40px;
        background: #1B77A1;
        border: 0;
        outline: 0;
        transition: all 0.2s ease-in-out 0s;
        position: absolute;
        z-index: 1;
        &:after{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 17px;
          height: 28px;
          background-image: url(../images/main-slider-arrow.svg);
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
    .advantages-swiper-button-prev{
      right: 40px;
      &:after{
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
    .advantages-swiper-button-next{
      right: 0;
    }

  }
  &__item{
    width: percentage(455 / 1405);
    margin: 0;
    margin-right: percentage(20 / 1405);
    background: $white;
    padding: 5rem 0 4.2rem;
    border: 1px solid $footer-bg;
    @include transition();
    &:nth-child(3n){
      margin-right: 0;
    }
    @media (max-width: 1440px){
      overflow: hidden;
    }
    @media (max-width: 1000px){
      width: 100%;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
    &:hover{
      //transform: scale(1.025);
      .main-advantages__item-icon{
        transform: translateX(15px);
      }
      .main-advantages__item-title{
        color: $brown-light;
      }
    }
    &:active{
      transform: scale(0.98);
    }
    &-title{
      width: 100%;
      text-align: center;
      font-size: 2.6rem;
      line-height: 3.5rem;
      color: $brown;
      font-weight: 600;
      margin-bottom: 5px;
      min-height: 7rem; //2 line-height
      padding: 0 1rem;
      @include transition();
    }
    &-content{
      padding: 0 3.7rem 0 0;
      position: relative;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      @media (max-width: 1440px) {
        padding: 0;
        overflow: visible;
        justify-content: center;
      }
    }
    &-icon{
      width: 145px;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -3.2rem;
      margin-right: 1.6rem;
      margin-top: 6.2rem;
      @include transition();
      svg{
        height: 100%;
        width: auto;
      }
      @media (max-width: 1440px) {
        position: absolute;
        opacity: .2;
        left: 0;
      }
    }
    &-text{
      max-width: 384px;
      color: $gray;
      @media (max-width: 1440px) {
        padding: 10px;
        text-align: center;
        color: $base-color;
      }
    }
  }
}