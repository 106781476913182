.logotype {
  width: 128px;
  max-width: 128px;
  margin-right: 10px;
  @include transition();
  @media(max-width: 1000px){
    position: absolute;
    top: 10px;
  }
  @media (max-width: 768px){
    width: 100px;
  }
  &:hover{
    transform: scale(1.1);
  }
  &:active{
    transform: scale(1);
  }
  &-pskov{
    width: 175px;
    max-width: 175px;
    margin-right: 0;
    @media(max-width: 1000px){
      right: 10px;
      top: 50px;
    }
    @media(max-width: 768px){
      width: 150px;
      max-width: 150px;
      top: 84px;
      right: 11%;
    }
    @media (max-width: 480px){
       width: 100px;
      right: 10px;
      top: 96px;
    }
  }
  a{
    display: flex;
    img{
      width: 100%;
    }
  }
}