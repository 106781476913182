// Семантические цвета

$base-color:                  #212324;
$base-color-30:               rgba(33,35,36, .3);
$base-color-80:               rgba(33,35,36, .8);
$gray:                        #9F9F9F;
$gray-light:                  #DEBE8E;
$gray-text:                   #918E8E;
$gray-paginator:              #BCBDBD;
$brown:                       #C2A271;
$brown-dark:                  #A38453;
$brown-light:                 lighten(#A38453, 5%);
$blue:                        #008EB9;
$blue-80:                     rgba(0, 142, 185, .8);
$blue-dark:                   #1B77A1;
$blue-sodark:                 #0E6288;
$blue-sky:                    #10C0F5;
$blue-solight:                #08A7D8;
$blue-dark-80:                rgba(27, 119, 161, .8);
$blue-light:                  lighten($blue, 5%);
$figcaption-color-80:         rgba(188, 189, 189, .8);
$black-80:                    rgba(0, 0, 0, .8);
$black-light:                 #4D4F50;
$light-black:                 #242424;
$body-bg-color:               #fff;
$footer-bg:                   #EAEAEA;
$icons-bg:                    #F8F8F8;
$message-bg:                  #f9f9f9;

$white:                       #ffffff;

$link-color:                  #41afd3;
$link-color--hover:           darken($link-color, 5%);
$link-color--active:          darken($link-color, 10%);


// Базовая типографика

$font-size--root:             10px;



$font-size:                   16px; // rem(16px)
$font-size--small:            80%;

$font-family:                 'Roboto', 'Arial', 'Helvetica CY', 'Nimbus Sans L', sans-serif;
$font-family--monospace:      'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New', monospace;

$font-weight--100:            100;
$font-weight--200:            200;
$font-weight--300:            300;
$font-weight--400:            400;
$font-weight--500:            500;
$font-weight--600:            600;
$font-weight--700:            700;
$font-weight--800:            800;
$font-weight--900:            900;

$font-weight:                 $font-weight--400;

$line-height:                 normal;


// Ширины

$wrapper: (
  'max-width': 1920px,
  'min-width': 280px,
  'padding': 0 10px
);
