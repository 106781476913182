.main-news {
  padding-top: 5rem;
  padding-bottom: 6rem;
  @media (max-width: 768px) {
    padding-top: 4rem;
    padding-bottom: 5rem;
  }
  &__wrapper {
    max-width: 1430px;
    position: relative;
    & > .main-news__container{
      margin-top: 60px;
    }
    @media (max-width: 1000px){
     & > .main-news__container{
        overflow: hidden;
        max-width: 680px;
        margin: auto;
        position: relative;
       margin-top: 3.8rem;
      }
    }
  }

  &__forward {
    position: absolute;
    right: 10px;
    top: 1.7rem;
    @media (max-width: 768px) {
      position: static;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      margin: 0 auto;
      margin-top: 3rem;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1000px){
      width: 100%;
      margin: 0;
      .swiper-wrapper{
        flex-wrap: nowrap;
        align-items: stretch;
        height: auto;
      }
    }
    .swiper-button-disabled{
      opacity: .5;
    }
    .news-swiper-button-prev, .news-swiper-button-next{
        display: none;
      @media (max-width: 1000px) {
        display: block;
        width: 40px;
        height: 40px;
        background: #1B77A1;
        border: 0;
        outline: 0;
        transition: all 0.2s ease-in-out 0s;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        &:after{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 17px;
          height: 28px;
          background-image: url(../images/main-slider-arrow.svg);
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
    .news-swiper-button-prev{
      &:after{
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
    .news-swiper-button-next{
      right: 0;
    }
  }

  &__item {
    width: percentage(455 / 1405);
    margin: 0;
    margin-right: percentage(20 / 1405);
    position: relative;
    display: block;
    background: $white;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.07);
    padding-bottom: 5rem;
    &:nth-child(3n){
      margin-right: 0;
    }
    @media (max-width: 1000px) {
      width: 100%;
      margin: 0;
      box-shadow: none;
      border: 1px solid #EAEAEA;
    }
    &:hover{
      .main-news__item-img{
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
    &:active{
      .main-news__item-img{
        transform: translate(-50%, -50%) scale(1.05);
      }
    }

    &-top {
      width: 100%;
      height: 265px;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(33, 35, 36, 0.58) 76.04%);
        z-index: 1;
      }
    }

    &-img {
      z-index: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      @include transition();
    }

    &-title {
      position: absolute;
      z-index: 2;
      bottom: 20px;
      left: 0;
      width: 100%;
      padding: 0 20px;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: 900;
      color: $white;
    }

    &-date {
      font-size: 1.3rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $gray;
      margin-bottom: 1.2rem;
      padding: 0 2rem;
      margin-top: 2.2rem;

      &::before {
        content: '';
        width: 16px;
        height: 12px;
        background-image: url(../images/date-icon.svg);
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 1rem;
      }
    }

    &-text {
      font-size: 1.5rem;
      line-height: 2.8rem;
      color: $gray-text;
      padding: 0 1.6rem 0 2rem;
    }

    &-forward {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: auto;
      top: auto;
    }

  }
}