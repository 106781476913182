.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .content {
    flex: 1 0 auto;
    position: relative;
  }
}

ol, ul {
  list-style: none;
}

.wrapper {
  margin: 0 auto;
  padding: map-get($wrapper, 'padding');
  max-width: map-get($wrapper, 'max-width');
  min-width: map-get($wrapper, 'min-width');
}

h1 {
  font-weight: $font-weight--900;
  line-height: 5rem;
  font-size: 3.4rem;
  color: $blue-dark;
  margin-bottom: 3rem;
}


.btn {
  font-size: 1.4rem;
  line-height: 2.6rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #ffffff;
  background: $brown;
  border: 2px solid $brown;
  padding: 12px 24px;
  width: 100%;
  max-width: 22.8rem;
  position: relative;
  display: inline-block;
  text-align: center;
  @include transition();
  @media (max-width: 1440px) {
    padding: 10px 20px;
  }
  @media (max-width: 1440px) {
    padding: 8px 18px;
  }

  &::after {
    content: '';
    width: 4rem;
    height: 2px;
    background: $brown-dark;
    position: absolute;
    right: 0;
    top: calc(50% - 4px);
    transform: translateX(50%);
    @include transition();
    @media (max-width: 1440px) {
      top: calc(50% - 2px);
    }
  }

  &:hover {
    border-color: $brown-dark;

    &::after {
      transform: translateX(0);
    }
  }

  &:active {
    background: $brown-dark;
  }

}

.btn-add {
  font-size: 1.4rem;
  line-height: 2.6rem;
  font-weight: 500;
  text-transform: uppercase;
  color: $blue;
  background: transparent;
  border: 2px solid $blue;
  padding: 12px 24px;
  width: 100%;
  max-width: 22.8rem;
  position: relative;
  display: inline-block;
  text-align: center;
  margin-left: 40px;
  @include transition();

  &:hover {
    background-color: $blue;
    color: #ffffff;
  }
}

.form-input {
  font-size: 1.5rem;
  line-height: 2.8rem;
  padding: 10px 16px;
  color: $base-color;
  margin-bottom: 15px;
  border: 1px solid $footer-bg;

  &::placeholder {
    color: $gray-paginator;
    opacity: 1;
  }
}

.form-checkbox {
  margin-bottom: 15px;

  input {
    display: none;

    &:checked + .form-checkbox-span span {
      background-color: $blue;
      @include transition();

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 8px;
        height: 3px;
        background-color: $white;
        border-radius: 2px;
        transform: rotate(45deg);
        top: 10px;
        left: 2px;
        @media (max-width: 768px) {
          width: 7px;
          top: 8px;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 15px;
        height: 3px;
        background-color: $white;
        border-radius: 2px;
        transform: rotate(-49deg);
        position: absolute;
        left: 5px;
        top: 8px;
        @media (max-width: 768px) {
          width: 10px;
          top: 6px;
        }
      }
    }
  }

  &-span {
    display: inline-block;
    padding-left: 39px;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 1px;
    color: $base-color;
    position: relative;
    @media(max-width: 768px) {
      font-size: 1.4rem;
    }

    span {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 2px solid $blue;
      position: absolute;
      left: 0;
      cursor: pointer;
      @include transition();
      @media (max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }

    a {
      color: $blue;
    }
  }
}

.form-submit {
  display: inline-block;
  position: relative;

  &:after {
    content: '';
    width: 4rem;
    height: 2px;
    background: #A38453;
    position: absolute;
    right: 0;
    top: calc(50% - 4px);
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    transition: all 0.2s ease-in-out 0s;
  }

  &:hover {
    &:after {
      transform: translateX(0);
    }
  }

  input {
    width: 200px;
    padding: 10px 24px;

    &:after {
      display: none;
    }
  }
}

.forward {
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: $blue;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  @include transition();

  &::after {
    content: '';
    width: 15px;
    height: 8px;
    background-image: url(../images/all-arrow.svg);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    margin-left: 1.5rem;

    @include transition();
  }

  &:hover {
    color: $blue-light;

    &::after {
      transform: translateX(100%);
    }
  }

  &:active {
    color: $blue-dark;
  }
}

div.modal-feedback {
  max-width: 720px;
  margin: 0 auto;
  position: relative;

  .wrapper {
    padding: 3rem 3rem 4rem;
    max-width: none;
    margin: 0;
  }
}

.mfp-close-btn-in .mfp-close {
  color: transparent;
  top: 5px;
  right: 5px;

  &:before, &:after {
    content: '';
    display: block;
    height: 44px;
    width: 2px;
    left: 50%;
    background-color: $gray;
    position: absolute;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
    top: 0;
  }
}

html {
  margin-right: 0;
}

@media (max-width: 1440px) {
  html {
    font-size: 8.5px;
  }
}

@media (max-width: 1000px) {
  html {
    font-size: 8px;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 7px;
  }
}

.form-error{
  padding: 8px 4px 0;
  color: #e00000;
  font-size: 12px;
}