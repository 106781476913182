.request-form {
  padding: 60px 10px;
  padding-bottom: 30px;
  background-color: $footer-bg;
  @media (max-width: 1000px) {
    padding-top: 50px;
  }
  @media (max-width: 768px) {
    padding-top: 40px;
  }
  @media (max-width: 600px) {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  h2 {
    margin-bottom: 45px;
    text-align: center;
    font-size: 2.6rem;
    font-weight: $font-weight--700;
    @media (max-width: 1000px) {
      margin-bottom: 40px;
    }
    @media (max-width: 768px) {
      margin-bottom: 35px;
    }
    @media (max-width: 600px) {
      margin-bottom: 30px;
    }
  }

  &-item {
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      flex-direction: column;
    }

    h3 {
      width: 100%;
      margin: 15px 0;
      font-size: 1.8rem;
      font-weight: $font-weight--500;
      text-align: center;
      opacity: 0.8;
      @media (max-width: 600px){
        margin: 10px 0;
      }
    }

    &--col {
      width: calc(50% - 10px);
      margin-right: 20px;
      @media (max-width: 600px){
        width: 100%;
        margin-right: 0;
      }
    }

    &--row {
      width: 100%;
      margin-right: 0;
    }

    &__btn{
      margin: 10px auto 0;
      line-height: 1.6rem;
      padding: 10px 24px;
      max-width: 230px;
    }


    .custom-select {
      margin-left: 0;
      position: relative;
      user-select: none;
      color: $base-color;

      select {
        display: none;
      }
    }

    .select-options,
    .select-styled {
      display: none;
    }

    .select-items div,
    .select-selected {
      font-size: 1.5rem;
      padding: 10px 15px;
      cursor: pointer;
      color: $base-color;
    }

    .select-items div {
      border: 1px solid transparent;
      border-color: transparent transparent rgba(0, 0, 0, .1) transparent;
    }

    .select-selected {
      font-size: 1.5rem;
      color: $base-color;
      background-color: #fff;
    }

    .select-selected::after {
      position: absolute;
      content: "";
      top: 14px;
      right: 12px;
      width: 5px;
      height: 5px;
      background-color: $base-color;
      transform: rotate(45deg);
      transition: .3s ease-in;
    }

    .select-selected::before {
      position: absolute;
      content: "";
      top: 12px;
      right: 11px;
      width: 7px;
      height: 5px;
      background-color: $white;
      transition: .3s ease-in;
      z-index: 1;
      transform-origin: 100% 100%;
    }

    .select-hide {
      display: none;
    }

    .select-items {
      position: absolute;
      background-color: #fff;
      box-shadow: 0 1px 4px rgba(0, 0, 0, .082);
      top: 100%;
      left: 0;
      right: 0;
      z-index: 99;
    }
  }

  &-btn {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      margin-top: 15px;
    }

    .btn {
      min-width: 200px;
      @media (max-width: 1000px) {
        min-width: 180px;
      }
      @media (max-width: 768px) {
        min-width: 160px;
      }
      @media (max-width: 600px) {
        margin-bottom: 20px;
        min-width: 175px;
      }
    }

    .btn-add {
      min-width: 225px;
      @media (max-width: 1000px) {
        min-width: 205px;
      }
      @media (max-width: 768px) {
        min-width: 185px;
      }
      @media (max-width: 600px) {
        margin-left: 0;
        padding: 7px 24px;
        min-width: 175px;
      }
    }
  }

  &-policy {
    margin-top: 20px;
    font-size: 12px;
    font-weight: $font-weight--400;
    text-align: center;
    text-transform: uppercase;
    color: $gray-paginator;
    letter-spacing: 1px;

    a {
      color: $blue;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    max-width: 930px;
    margin: 0 auto;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      &:nth-child(odd) {
        margin-right: 0;
      }

      span {
        margin-left: 15px;
        font-size: 10px;
        font-weight: $font-weight--400;
        color: $gray-paginator;
      }

      input {
        padding: 10px 15px;
        font-size: 1.5rem;
        font-weight: $font-weight--400;
        background-color: #ffffff;
        border: 1px solid $footer-bg;
        color: $base-color;

        &.important-input {
          &::placeholder {
            color: $base-color;
          }
        }

        &[type='date'] {
          height: 40px;
          color: $base-color;
          @media (max-width: 1440px){
            height: 36px;
          }
        }
      }

      input::placeholder {
        color: $gray-paginator;
        opacity: 1;
      }
    }
  }
}

