.pagination{
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  @media (max-width: 600px) {
     height: 45px;
  }
  &-prev, &-next{
    position: relative;
    width: 50px;
    height: 100%;
    cursor: pointer;
    &:before, &:after{
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: $blue;
      left: 25%;
      transform: translateX(-50%);
    }
    &:before{
      transform: rotate(45deg);
      top: 30px;
      @media (max-width: 600px) {
        top: 27px;
      }
    }
    &:after{
      top: 20px;
      transform: rotate(-45deg);
      @media (max-width: 600px) {
        top: 17px;
      }
    }
  }
  &-next{
    transform: rotate(180deg);
  }
  &-list{
    display: flex;
    box-sizing: border-box;
    border: 2px solid $blue;
    height: 100%;
    &__item{
      &.active{
        & + .pagination-list__item{
          a{
            &:after{
              display: none;
            }
          }
        }
        a{
          color: $white;
          background-color: $blue;
          &:after{
            display: none;
          }
        }
      }
      &:hover{
        a{
          background-color: $blue;
          color: $white;
          @include transition();
          &:after{
            opacity: 0;
          }
        }
        & + .pagination-list__item{
          a{
            &:after{
              opacity: 0;
              @include transition();
            }
          }
        }
      }
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 100%;
        position: relative;
        font-size: 14px;
        line-height: 24px;
        font-weight: $font-weight--500;
        color: $gray-paginator;
        @include transition();
        @media (max-width: 600px) {
          width: 45px;
        }
        &:after{
          content: '';
          width: 1px;
          height: 16px;
          position: absolute;
          background-color: $footer-bg;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          @include transition();
        }
      }
    }
  }
}