.footer {
  position: relative;
  flex: 0 0 auto;
  background: $footer-bg;
  padding: 2rem 0;

  /*footer-top start*/
  &-top {
    max-width: 1772px;
    margin: 0 auto;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      @media (max-width: 480px){
        flex-wrap: wrap;
      }
    }

    &__left {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: percentage(938 / 1799);
      max-width: 938px;
      @media (max-width: 1000px){
        flex-wrap: wrap;
        justify-content: center;
      }
      @media (max-width: 480px){
        width: 100%;
      }
      .logotype{
        margin-right: 20px;
        min-width: 100px;
        align-self: center;
        @media (max-width: 1000px){
          position: static;
          margin-right: 10%;
          margin-bottom: 10px;
        }
        @media (max-width: 480px){
          margin-right: 0;
        }
      }
    }

    &__right {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: percentage(630 / 1752);
      @media(max-width: 1440px){
        width: 46%;
      }
      @media (max-width: 1000px){
        flex-wrap: wrap;
        width: 30%;
      }
      @media (max-width: 480px){
        width: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &-nav {
    max-width: 745px;
      @media (max-width: 480px){
        margin-bottom: 2rem;
      }
    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media (max-width: 1000px){
        justify-content: space-between;
      }
      li {
        width: 33%;
        padding-right: 10px;
        margin-top: 4rem;
        &:nth-child(1) {
          margin-top: 0;
        }
        &:nth-child(2){
          margin-top: 0;
        }
        &:nth-child(3) {
          margin-top: 0;
        }
        @media (max-width: 1440px){
          width: 50%;
          margin-top: 2rem;
          &:nth-child(3){
            margin-top: 2rem;
          }
        }
        @media (max-width: 1000px){
          width: 34%;
        }
        @media (max-width: 480px){
          width: 45%;
        }
        a {
          white-space: nowrap;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 2.4rem;
          text-transform: uppercase;
          @include transition();

          &:hover, &.active {
            color: $blue;
          }

          &:active {
            color: $blue-dark;
          }
        }
      }
    }
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-bottom: 1.5rem;

    &--phone {
      .icon {
        width: 16px;
        height: 16px;
        background-image: url(../images/phone-icon.svg);
      }
    }

    &--email {
      .icon {
        width: 21px;
        height: 16px;
        background-image: url(../images/email-icon.svg);
      }
    }

    &--address {
      .icon {
        width: 25px;
        height: 25px;
        background-image: url(../images/address-icon.svg);
      }
    }

    .icon {
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 1rem;
    }

    .text {
      font-size: 1.3rem;
      color: $gray;
      text-transform: uppercase;
    }
  }

  &__phone {
    font-size: 2.6rem;
    line-height: 3.5rem;
    font-weight: 600;
    color: #000;
    display: block;
    margin-bottom: 5px;
    @include transition();

    &:hover {
      color: $brown;
    }

    &:active {
      color: $brown-dark;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  &-phones{
    @media (max-width: 1000px){
      margin-bottom: 3rem;
    }
    @media (max-width: 480px){
       display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__email {
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: $blue;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: url(../images/line.svg);
    }
  }

  &-contacts {
    margin-left: 3.8rem;
    @media (max-width: 1000px){
      margin-left: 0;
    }
  }

  &-email {
    margin-bottom: 3.2rem;
    @media (max-width: 1000px){
      margin-bottom: 3rem;
    }
    @media (max-width: 480px){
       display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-address {
    address {
      font-size: 1.5rem;
      line-height: 2.8rem;
    }
    @media (max-width: 480px){
       display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  /*footer-top end*/

  /*footer-middle start*/
  &-middle {
    width: 100%;
    border-color: $gray;
    border-style: solid;
    border-width: 1px 0 1px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0 16px;
    margin-top: 3.8rem;
    .logotype-pskov{
      position: static;
    }
  }

  /*footer-middle end*/

  /*footer-bottom start*/
  &-bottom {
    margin-top: 3.8rem;
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: $gray;
    @media (max-width: 768px){
      margin-top: 2rem;
    }
    &__wrapper {
      max-width: 1435px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        &>div{
          text-align: center;
          margin-bottom: 1.5rem;
        }
      }
    }

    &__copyright, &__policy, &__coffee{
      width: 282px;
    }

    &__policy {
      text-align: center;
      display: flex;
      justify-content: center;
      @include transition();
      a{
        color: inherit;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-image: url(../images/line.svg);
        }

        &:hover {
          color: $blue-light;
        }

        &:active {
          color: $blue-dark;
        }
      }


    }

    &__coffee {
      text-align: right;
      a {
        color: inherit;
        @include transition();

        &:hover {
          color: $blue-light;
        }

        &:active {
          color: $blue-dark;
        }
      }
    }

  }

  /*footer-bottom end*/

}