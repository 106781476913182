.contacts {
  .wrapper {
    max-width: 1435px;
  }

  h2{
    font-size: 2.6rem;
    line-height: 3.5rem;
    font-weight: $font-weight--700;
    color: $base-color;
    text-align: center;
  }

  .header-block {
    margin: 30px 0;
    @media (max-width: 768px){
       margin: 20px 0;
    }
  }

  &-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    position: relative;
    z-index: 1;
    @media (max-width: 768px){
      flex-wrap: wrap;
      margin-bottom: 50px;
    }
    @media (max-width: 600px){
      margin-bottom: 40px;
    }
    &-left{
      max-width: 300px;
      width: 100%;
      @media (max-width: 1000px){
        max-width: 240px;
      }
      @media (max-width: 768px){
        max-width: none;
        display: flex;
        justify-content: space-between;
      }
      @media (max-width: 600px) {
        flex-wrap: wrap;
      }
    }
    &-item {
      margin-bottom: 30px;
      @media (max-width: 768px){
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-right: 20px;
        &:nth-child(3n){
          margin-right: 0;
        }
      }
      @media (max-width: 600px) {
        width: calc(50% - 10px);
        &:nth-child(3n){
          margin-right: 20px;
        }
        &:nth-child(2n){
          margin-right: 0;
        }

      }
      &.phone {
        .contacts-main-item--header {
          &:before {
            background-image: url("../images/phone.svg");
          }
        }

        a {
          display: block;
          font-size: 2.6rem;
          line-height: 3.5rem;
          font-weight: $font-weight--700;
          color: $black-80;
          @media(max-width: 375px){
            font-size: 2rem;
            line-height: 3.2rem;
          }
        }
      }

      &.address{
        .contacts-main-item--header {
          &:before {
            background-image: url("../images/address.svg");
            width: 18px;
            height: 18px;
          }
        }
      }

      &.email{
        .contacts-main-item--header{
          &:before{
            background-image: url("../images/mail.svg");
            width: 18px;
            height: 14px;
          }
        }
        a{
          font-size: 1.5rem;
          line-height: 2.8rem;
          color: $blue;
          border-bottom: 1px dotted $blue;
        }
      }

      &--header {
        display: block;
        padding-left: 39px;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $base-color-30;
        position: relative;
        margin-bottom: 15px;
        letter-spacing: 1px;
        text-transform: uppercase;

        &:before {
          content: '';
          display: flex;
          position: absolute;
          left: 0;
          width: 16px;
          height: 16px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      address{
        font-size: 1.5rem;
        line-height: 2.8rem;
        color: $light-black;
      }
    }
    .yandex-map{
      height: 504px;
      max-width: 1069px;
      width: 100%;
      padding-right: 20px;
      padding-bottom: 20px;
      position: relative;
      @media (max-width: 768px){
        height: 494px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
      }
      &:after{
        content: '';
        display: block;
        position: absolute;
        width: calc(100%  - 20px);
        height: calc(100% - 20px);
        left: 20px;
        top: 20px;
        background-color: $footer-bg;
        z-index: -1;
        @media (max-width: 768px){
          width: 100%;
          height: calc(100% + 10px);
          left: 0;
          top: -10px;
        }
      }
    }
  }
  &-employees{
    position: relative;
    z-index: 0;
    &:before{
      content: '';
      display: block;
      height: 300px;
      width: 100%;
      background-color: $message-bg;
      position: absolute;
      top: -90px;
      z-index: -1;
    }
    h2{
      margin-bottom: 60px;
      @media(max-width: 768px){
        margin-bottom: 50px;
      }
      @media(max-width: 600px){
        margin-bottom: 40px;
      }
    }
    &-section{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 60px;
      @media(max-width: 768px){
        margin-bottom: 50px;
      }
      @media(max-width: 600px){
        margin-bottom: 40px;
      }
      &--item{
        padding: 30px;
        box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
        background-color: $white;
        display: flex;
        flex-direction: column;
        width: calc(50% - 10px);
        margin-right: 20px;
        margin-bottom: 20px;
        @media(max-width: 768px){
          padding: 20px;
        }
        @media(max-width: 600px){
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
        }
        &:nth-child(2n){
          margin-right: 0;
        }
        &__header{
          display: inline-block;
          margin-bottom: 10px;
          font-size: 2.6rem;
          line-height: 3.5rem;
          font-weight: $font-weight--700;
          color: $base-color-80;
        }
        &__subheader{
          display: inline-block;
          margin-bottom: 15px;
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $base-color-30;
        }
        &__phone{
          display: inline-block;
          padding-left: 39px;
          position: relative;
          font-size: 1.5rem;
          line-height: 2.8rem;
          font-weight: $font-weight--700;
          color: $base-color;
          &:before{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url("../images/phone.svg");
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  &-feedback{
    background-color: $message-bg;
    .wrapper{
      padding: 30px 10px 60px;
      max-width: 712px;
      @media(max-width: 768px){
        padding: 20px 10px 50px;
      }
      @media(max-width: 600px){
        padding: 20px 10px 40px;
      }
    }
    h2{
      font-size: 2.6rem;
      line-height: 3.5rem;
      font-weight: $font-weight--700;
      color: $base-color;
      text-align: center;
      margin-bottom: 60px;
      @media(max-width: 768px){
         margin-bottom: 40px;
      }
      @media(max-width: 600px){
         margin-bottom: 30px;
      }
    }
    &-form{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &--left{
        display: flex;
        flex-direction: column;
        width: calc(50% - 10px);
        margin-right: 20px;
        @media(max-width: 600px){
          width: 100%;
          margin-right: 0;
          margin-bottom: 15px;
        }
        input{
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      &--right{
        width: calc(50% - 10px);
        @media(max-width: 600px){
          width: 100%;
        }
        textarea{
          width: 100%;
          resize: none;
          max-height: 115px;
          margin-bottom: 0;
          @media(max-width: 1440px){
            max-height: 104px;
          }
          @media(max-width: 768px){
            max-height: 97px;
          }
        }
      }
      .form-checkbox{
        width: 100%;
        margin-bottom: 30px;
        margin-top: 30px;
        @media(max-width: 768px){
          margin-bottom: 25px;
          margin-top: 25px;
        }
        @media(max-width: 768px){
          margin-bottom: 20px;
          margin-top: 20px;
          text-align: center;
        }
        @media(max-width: 430px){
          text-align: left;
        }
        &-span{
          line-height: 2.4rem;
        }
      }
    }
  }
}