/* roboto-regular - cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v20-cyrillic-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v20-cyrillic-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-cyrillic-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-cyrillic-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-cyrillic-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-cyrillic-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v20-cyrillic-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto-v20-cyrillic-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-cyrillic-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-cyrillic-ext_latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-cyrillic-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-cyrillic-ext_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v20-cyrillic-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto-v20-cyrillic-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-cyrillic-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-cyrillic-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-cyrillic-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-cyrillic-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - cyrillic-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/roboto-v20-cyrillic-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local('Roboto Black'), local('Roboto-Black'),
       url('../fonts/roboto-v20-cyrillic-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v20-cyrillic-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v20-cyrillic-ext_latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v20-cyrillic-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v20-cyrillic-ext_latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}