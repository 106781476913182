.landing-member{
  margin-bottom: 60px;
  @media (max-width: 1000px){
    margin-bottom: 0;
  }
  &-wrapper{
    max-width: 1425px;
    padding: 0 10px;
    margin: 0 auto;
  }
  .title-h2{
    margin-bottom: 7.7rem;
  }
  &-item{
    padding: 5rem 6rem;
    box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
    margin-bottom: 20px;
    @media (max-width: 768px){
      padding: 4rem 5rem;
    }
    @media (max-width: 600px){
      padding: 3rem 20px;
    }
    &:last-child{
      margin-bottom: 0;
    }
    &--header{
      display: flex;
      align-items: center;
      position: relative;
      @media (max-width: 600px){
        min-height: 60px;
        align-items: flex-start;
      }
      .title{
        width: percentage(900 / 1285);
        margin-left: percentage(77 / 1285);
        @media (max-width: 600px){
          margin-left: 0;
          width: 100%;
        }
        &-header{
          font-size: 2.6rem;
          line-height: 3.5rem;
          font-weight: $font-weight--700;
          color: $base-color;
          display: block;
          @media (max-width: 600px){
            width: calc(100% - 100px);
          }
        }
        .sub-header{
          display: block;
          font-size: 1.2rem;
          line-height: 1.6rem;
          color: $base-color;
          letter-spacing: 1px;
          text-transform: uppercase;
          margin-top: 11px;

        }
      }
      .detail{
        display: block;
        font-size: 1.4rem;
        line-height: 2.4rem;
        text-transform: uppercase;
        color: $blue;
        padding-right: 33px;
        position: relative;
        margin-left: percentage(100 / 1285);
        cursor: pointer;
        @media (max-width: 600px){
          position: absolute;
          top: 6px;
          right: -5px;
          padding-right: 27px;
        }
        &.active{
          &:before{
            transform: rotate(-225deg);
            transition: all .2s;
          }
        }
        &:before{
          content: '';
          display: block;
          position: absolute;
          top: -2px;
          right: 0;
          width: 14px;
          height: 14px;
          border-left: 2px solid $blue;
          border-bottom: 2px solid $blue;
          transform: rotate(-45deg);
          transform-origin: center;
          transition: all .2s;
          @media (max-width: 600px){
            width: 10px;
            height: 10px;
            top: 0;
          }
        }
      }
      svg{
        width: percentage(100 / 1285);
        min-width: 75px;
        @media (max-width: 768px){
          min-width: 50px;
          height: 50px;
        }
        @media (max-width: 600px){
          position: absolute;
          opacity: .2;
          top: 0;
          left: 0;
        }
      }
    }
    &--description{
      padding-top: 10px;
      display: none;
      ul{
        padding-left: 13.77%;
        @media (max-width: 600px){
          padding-left: 0;
        }
        li{
          padding-left: 3rem;
          margin-bottom: 1.5rem;
          position: relative;
          font-size: 1.5rem;
          line-height: 2.8rem;
          color: rgba(33, 35, 36, 0.8);
          &:before{
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #008EB9;
            position: absolute;
            left: .2rem;
            top: 1.2rem;
          }
        }
      }
    }
  }
}