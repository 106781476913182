.cookie-policy{
  position: fixed;
  width: 100%;
  z-index: 50;
  background-color: $blue;
  .wrapper{
    max-width: 1435px;
    padding: 30px 10px;
  }
  &-row{
    display: flex;
    justify-content: space-between;
  }
  &-text{
    font-size: 1.5rem;
    line-height: 2.8rem;
    color: $white;
    opacity: .8;
    width: percentage(1048 / 1404);
  }
  &-button{
    width: 217px;
    button{
      padding: 10px 24px;
      &:after{
        top: calc(50% - 2px);
      }
    }
  }
}