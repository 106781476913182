.advantages{
  &-wrapper{
      max-width: 1425px;
      margin: 0 auto;
      padding: 3rem 10px 0;
      display: flex;
      flex-wrap: wrap;
      .title-h2{
        margin-bottom: 6rem;
      }
    .main-news__container{
      overflow: hidden;
      position: relative;
      .news-swiper-button-prev{
        top: 0;
        transform: none;
        right: 40px;
      }
      .news-swiper-button-next{
        top: 0;
        transform: none;
      }
    }
  }
  &-item{
    padding: 0 7.8rem;
    background-image: url('../images/action-bg.svg');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    min-height: 232px;
    position: relative;
    width: percentage(455 / 1405);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: percentage(20/ 1405);
    box-sizing: border-box;
    border: 1px solid $footer-bg;
    @media (max-width: 1000px){
        padding: 0 6.5rem;
        min-height: 210px;
        margin: 0;
    }
    &:last-child{
      margin-right: 0;
    }
    &:before{
      content: '';
      position: absolute;
      left: 0;
      display: block;
      width: 60px;
      height: 150px;
      background-image: url("../images/hundred.svg");
      background-repeat: no-repeat;
      top: 50%;
      transform: translateY(-50%);
    }
    span{
      font-size: 1.5rem;
      line-height: 2.8rem;
      font-weight: $font-weight--700;
      color: $base-color;
      @media (max-width: 1000px){
        text-align: center;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 10px;
        font-size: 14px;
      }
    }
  }
}