/*------------------------------------*\
  # Transitions
\*------------------------------------*/
@mixin transition($properties...) {
  @if length($properties) >= 1 {
    transition: $properties;
  } @else {
    transition: all 0.2s ease-in-out 0s;
  }
}
