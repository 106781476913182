.header {
  &__wrapper {
    max-width: 1822px;
    padding-top: 0.8rem;
    //padding-bottom: 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-content {
    width: calc(100% - 120px - 20px);
    max-width: 1610px;
    @media (max-width: 1000px) {
      width: 100%;
    }

    &__top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //padding-left: 3.8rem;
      margin-bottom: 2.4rem;
      @media (max-width: 1440px) {
        margin-bottom: 1.4rem;
      }
      @media (max-width: 1000px) {
        margin-bottom: 2.4rem;
      }
      @media (max-width: 480px) {
        margin-bottom: 2rem;
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @media (max-width: 1000px) {
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      @media (max-width: 768px) {
        justify-content: space-between;
        margin-bottom: 10px;
      }
    }
  }

  &-contacts {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 175px - 20px);
    max-width: 740px;
    @media (max-width: 1000px) {
      width: 100%;
      margin-left: 138px;
      justify-content: flex-end;
      max-width: 100%;
    }
    @media (max-width: 768px) {
      margin-left: 108px;
      flex-wrap: wrap;
      justify-content: center;
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 6rem;

      &:last-child {
        margin-right: 0;
      }

      &--email {
        color: $gray;
        @include transition();
        @media (max-width: 768px) {
          width: 100%;
          margin-right: 0;
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
        }
        @media (max-width: 480px) {
          margin-bottom: 0;
        }

        &:hover {
          color: $blue;
        }

        &:active {
          color: $blue-dark;
        }

        .icon {
          width: 21px;
          height: 16px;
          background-image: url(../images/email-icon.svg);
        }

        .text {
          font-size: 1.5rem;
          line-height: 3.2rem;
          position: relative;
          color: inherit;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 4px;
            width: 100%;
            height: 1px;
            background-image: url(../images/line.svg);
          }
        }
      }

      &--phone {
        color: $base-color;
        @include transition();
        @media (max-width: 480px) {
          width: 100%;
          justify-content: center;
          margin-right: 0;
        }

        &:hover {
          color: $brown;
        }

        &:active {
          color: $brown-dark;
        }

        .icon {
          width: 16px;
          height: 15px;
          background-image: url(../images/phone-icon.svg);
        }

        .text {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 3.2rem;
          color: inherit;
        }
      }

      .icon {
        display: inline-block;
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        margin-right: 1rem;
      }

    }
  }

  &-nav {
    width: calc(100% - 182px);
    max-width: 1368px;

    &-burger {
      display: none;
    }

    @media (max-width: 1000px) {
      width: 100%;
      order: 1;
    }
    @media (max-width: 768px) {
      order: 0;
      height: 32px;
      width: 35px;
      position: relative;
      z-index: 5;
      &-burger {
        display: block;
        width: 32px;
        height: 32px;
        position: relative;
        cursor: pointer;

        &__stick {
          display: block;
          width: 32px;
          height: 5px;
          background-color: $blue-dark;
          position: absolute;
          top: 14px;
          opacity: 1;
          transform: translateX(0);
          @include transition();
        }

        &:before {
          content: '';
          display: block;
          width: 32px;
          height: 5px;
          background-color: $blue-dark;
          position: absolute;
          top: 3px;
          transform-origin: left;
          @include transition();
        }

        &:after {
          content: '';
          display: block;
          width: 32px;
          height: 5px;
          background-color: $blue-dark;
          position: absolute;
          bottom: 2px;
          @include transition();
          transform-origin: left;
        }

        &.active {
          .header-nav-burger__stick {
            opacity: 0;
            transform: translateX(-32px);
          }

          &:before {
            transform: rotate(44deg);
            transform-origin: left;
          }

          &:after {
            transform: rotate(-44deg);
            transform-origin: left;

          }
        }
      }
      .active + ul {
        transform: translateX(0);
        background-color: $white;
        @include transition();

        li {
          width: 100%;

          a {
            opacity: 1;
            @include transition();
            border-right: 1px solid $footer-bg;
            box-sizing: border-box;
          }

          .active {
            background-color: $blue;
            color: $white;
            @include transition();

            &:after {
              border-color: $white;
            }
          }

          a:hover {
            background-color: $blue;
            color: $white;
            @include transition();
          }
        }
      }

    }

    ul {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 768px) {
        transform: translateX(-320px);
        @include transition();
        position: relative;
        width: 320px;
        left: -10px;
        top: 9px;
      }

      li {
        position: relative;
        @media (max-width: 768px) {
          width: 100%;
          &:last-child {
            a {
              border-bottom: 1px solid $footer-bg;

              &.active {
                border-bottom: 1px solid $blue;
              }

              &:hover {
                border-bottom: 1px solid $blue;
              }
            }
          }
        }

        &.has-child {

          &.active {
            & > div {
              a {
                color: $blue;
                @media (max-width: 768px) {
                  border-right: 1px solid $blue;
                }
              }
            }

            button {
              &::after {
                transform: translateY(calc(-50% + 2px)) rotate(225deg);
                @media(max-width: 1440px) {
                  transform: translateY(calc(-50% + 1px)) rotate(225deg);
                }
              }
            }
          }

          @media (max-width: 768px) {
            &.active {
              & > div a {
                color: $white;
                background-color: $blue;
              }
              button{
                background: $blue;
                color: $white;
                &::after{
                  border-color: $white;
                }
              }
            }
          }
        }


        a, button {
          display: block;
          white-space: nowrap;
          font-size: 1.4rem;
          line-height: 2.4rem;
          font-weight: 500;
          background-color: transparent;
          border: none;
          text-transform: uppercase;
          padding: 1.5rem 2rem 1.5rem;
          border-bottom: 2px solid transparent;
          @include transition();
          @media (max-width: 1440px) {
            padding: 1.5rem 1.5rem 1.5rem;
          }
          @media (max-width: 768px) {
            border-bottom: 0;
          }

          &:hover, &.active {
            border-color: $blue;
            color: $blue;
          }

          &:active {
            border-color: $blue-dark;
            color: $blue-dark;
          }
        }

        button {
          position: relative;
          @media (max-width: 768px) {
            width: 100%;
            text-align: left;
            padding-right: 34px;
          }

          &::after {
            content: '';
            width: 10px;
            height: 10px;
            border-bottom: 1px solid $base-color;
            border-right: 1px solid $base-color;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(calc(-50% - 4px)) rotate(45deg);
            @include transition();

            @media(max-width: 1440px) {
              width: 6px;
              height: 6px;
            }
            @media(max-width: 768px){
              width: 10px;
              height: 10px;
              right: 12px;
            }
          }

          &:hover {
            &::after {
              border-color: $blue;
            }
          }
        }

        .nav-dropdown-list {
          position: absolute;
          top: 100%;
          left: 0;
          width: 28rem;
          transition-property: opacity;
          transition-duration: .2s;
          background: $white;
          display: none;
          box-shadow: 0 3px 5px rgba(33, 35, 36, 0.15);
          @media (max-width: 768px) {
            box-shadow: none;
            width: 100%;
            position: static;
            transform: none;
            opacity: 1;
            display: none;
          }

          &::after {
            content: '';
            width: 100%;
            height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(180deg, #EBEBEB 0%, rgba(255, 255, 255, 0) 100%);
            pointer-events: none;
            z-index: 1;
            @media (max-width: 768px) {
              display: none;
            }
          }

          li {
            width: 100%;
            position: relative;
            z-index: 2;

            a {
              display: flex;
              align-items: center;
              width: 100%;
              font-size: 1.4rem;
              line-height: 2rem;
              font-weight: 500;
              color: $base-color;
              padding: 1rem 3rem;
              min-height: 6rem;
              white-space: normal;
              border: 0;
              background-color: $white;
              @include transition();
              @media (max-width: 768px) {
                padding: 1rem 1.5rem;
                background-color: $icons-bg;
              }

              &:hover {
                color: $white;
                background: $blue;
              }

              &:active {
                background: $blue-dark;
              }
            }

          }
        }

      }
    }
  }

  &-callback {
    font-size: 1.4rem;
    line-height: 2.6rem;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 500;
    color: $white;
    padding: 1rem 3rem;
    background: $blue-dark;
    border: 0;
    @include transition();

    &:hover {
      background: $blue;
    }

    @media (max-width: 1000px) {
      margin-right: 378px;
    }
    @media (max-width: 768px) {
      margin-right: 45%;
    }
    @media (max-width: 480px) {
      margin-right: 39%;
    }

    &:active {
      background: $blue-dark;
    }
  }

}