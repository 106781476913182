.guest {
  .wrapper {
    max-width: 1435px;
    padding: 30px 10px;
    @media (max-width: 768px){
      padding: 20px 10px;
    }
  }

  .header-block {
    margin-bottom: 30px;
    @media (max-width: 768px){
      margin-bottom: 20px;
    }
  }

  &-section {
    display: flex;
    @media (max-width: 600px){
      flex-wrap: wrap;
    }
    &-left {
      width: percentage(1048 / 1405);
      @media (max-width: 600px){
        width: 100%;
      }
    }

    &-right {
      margin-left: 20px;
      width: 337px;
      @media (max-width: 768px){
        width: 250px;
      }
      @media (max-width: 600px){
        width: 100%;
        margin-left: 0;
      }
    }

    &-message {
      padding: 30px;
      background-color: $message-bg;
      overflow: hidden;
      box-sizing: border-box;
      border: 1px solid transparent;
      margin-bottom: 20px;
      @include transition();
      @media (max-width: 768px){
        padding: 20px;
      }
      &.background {
        border: 1px solid #BCBDBD;
        background-color: transparent;
        @include transition();
      }

      &--header {
        font-weight: $font-weight--500;
        font-size: 1.8rem;
        line-height: 3.2rem;
        color: $base-color-80;
        max-width: 900px;
        margin-bottom: 10px;
        overflow: hidden;
      }

      &--info {
        overflow: hidden;

        span {
          display: inline-flex;
          align-items: center;
          position: relative;
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $gray-paginator;

          svg {
            margin-right: 10px;
          }

          &.name {

          }

          &.date {
            margin-left: 30px;
          }
        }
      }

      &--answer {
        max-width: 900px;
        padding: 30px 0 15px;
        display: none;
        @media (max-width: 768px){
           padding: 20px 0 10px;
        }
        @media (max-width: 600px){
           padding: 10px 0 10px;
        }
        &.active + span{
            span{
              text-indent: 0;
              @include transition();
            }
          }

        .answer {
          display: inline-block;
          color: $base-color-80;
          font-size: 1.5rem;
          line-height: 2.8rem;
          padding-left: 28px;
          position: relative;
          &:before {
            content: '';
            display: block;
            width: 13px;
            height: 2px;
            background-color: $blue;
            position: absolute;
            left: 0;
            top: 1.2rem;
          }
        }
      }

      &--decision {
        margin-top: 15px;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-weight: $font-weight--500;
        font-size: 1.4rem;
        line-height: 2.4rem;
        text-transform: uppercase;
        color: $blue;
        @media (max-width: 768px){
          margin-top: 10px;
        }
        &:hover {
          color: $blue-dark;

          svg {
            path {
              fill: $blue-dark;

              &:nth-child(2) {
                animation-name: messageone;
                animation-duration: 2s;
                animation-timing-function: steps(1, end);
                animation-iteration-count: infinite;
              }

              &:nth-child(3) {
                animation-name: messagetwo;
                animation-duration: 2s;
                animation-timing-function: steps(1, end);
                animation-iteration-count: infinite;
              }

              &:nth-child(4) {
                animation-name: messagethree;
                animation-duration: 2s;
                animation-timing-function: steps(1, end);
                animation-iteration-count: infinite;
              }
            }
          }
        }

        svg {
          margin-right: 15px;
        }
        span{
            text-indent: -6rem;
            overflow: hidden;
            @include transition();
        }
      }
    }

    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
      background-image: url("../images/form-bg.svg");
      background-repeat: no-repeat;
      background-position: 88px 88%;
      @media (max-width: 768px){
        padding: 20px;
      }
      h2 {
        font-size: 2.6rem;
        line-height: 3.5rem;
        color: $base-color;
        font-weight: $font-weight--700;
        text-align: center;
        margin-bottom: 30px;
        @media (max-width: 768px){
          margin-bottom: 20px;
        }
      }

      .form-input {
        width: 100%;
      }

      textarea.form-input {
        resize: none;
        height: 200px;
        margin-bottom: 10px;
      }

    }
  }
}

@keyframes messageone {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes messagetwo {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes messagethree {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}