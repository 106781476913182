.breadcrumbs {
  background: $icons-bg;
  padding-top: 1.4rem;

  &__wrapper{
    max-width: 1435px;
  }

  &-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__item {
      padding-right: 2.6rem;
      margin-right: 1rem;
      position: relative;
      margin-bottom: 1.4rem;

      &::after{
        content: '';
        width: 1.6rem;
        height: 1px;
        background: $brown;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;
        &::after{
          display: none;
        }
      }
    }

    &__link {
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $base-color-30;
      @include transition();
      &:hover{
        color: $blue-light;
      }
      &:active{
        color: $blue-dark;
      }
    }
    &__active-page {
      font-size: 1.2rem;
      line-height: 1.6rem;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $blue;
    }
  }

}