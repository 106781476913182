.landing-page{
  .main-slider{
    &__content{
      padding: 60px;
      height: 365px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      padding-bottom: 20px;
      padding-top: 160px;
      @media (max-width: 1440px){
        margin-right: 23.6rem;
       height: 345px;
      }
      @media (max-width: 1000px){
        margin: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      @media (max-width: 768px){
        padding-top: 120px;
        height: 315px;
        top: 45%;
      }
      @media (max-width: 600px){
        padding: 10px;
        padding-top: 90px;
        padding-bottom: 20px;
        top: 43%;
      }
      &:before{
        content: '';
        display: block;
        height: 130px;
        width: 677px;
        position: absolute;
        top: 0;
        left: -36px;
        background: linear-gradient(83.94deg, $brown 28.21%, #EDC382 60.91%);
        transform: skew(-29deg);
        @media (max-width: 1440px){
          width: 600px;
          height: 115px;
        }
        @media (max-width: 768px){
          width: 100%;
          background: linear-gradient(87.5deg, $blue -9.47%, $blue-dark 59.79%);
          transform: none;
          left: 0;
        }
        @media (max-width: 600px){
          height: 83px;
        }
      }
      &:after{
        content: '';
        display: block;
        width: 315px;
        height: 200px;
        position: absolute;
        top: -40px;
        right: -45px;
        background: linear-gradient(87.5deg, $blue -9.47%, $blue-dark 59.79%);
        transform: skew(-29deg);
        @media (max-width: 1440px){
          width: 285px;
          height: 180px;
          top: -36px;
        }
        @media (max-width: 768px){
          display: none;
        }
      }
      .header{
        position: absolute;
        top: 5px;
        z-index: 1;
        left: 40px;
        display: block;
        width: 550px;
        height: 120px;
        @media (max-width: 1440px){
          width: 495px;
          height: 108px;
          top: 3px;
          left: 10px;
        }
        @media (max-width: 768px){
          left: 50%;
          transform: translateX(-50%);
          top: 7px;
        }
        @media (max-width: 600px){
          width: 300px;
          height: 80px;
        }
        img{
          width: 100%;
        }
        &-text{
          font-size: 1.1rem;
          color: $white;
          position: relative;
          top: -40px;
          left: -20px;
          @media (max-width: 768px){
            left: 0;
          }
          @media (max-width: 600px){
            display: block;
            max-width: 100px;
            top: -20px;
            text-align: center;
            left: 10px;
          }
        }
      }
      p{
        align-self: flex-end;
      }
      .buttons{
        align-self: flex-end;
        display: flex;
        height: 50px;
        width: 100%;
        @media (max-width: 768px){
          justify-content: center;
        }
        a{
          @media (max-width: 768px){
            display: flex;
            align-items: center;
            justify-content: center;
          }
          @media (max-width: 600px){
            padding: 10px;
          }
          &:first-child{
            margin-left: 0;
          }
        }
      }
    }
    &__slide{
      height: 800px;
      @media (max-width: 1440px){
          height: 720px;
      }
      @media (max-width: 600px){
          height: 600px;
      }
    }
  }
  .about{
    padding-top: 0;
    margin-bottom: 4.2rem;
    &-numbers{
      margin-top: 6rem;
      @media (max-width: 1000px){
        flex-wrap: wrap;
      }
      &:before{
        content: none;
      }
      &__item{
        @media (max-width: 1000px){
          width: 50%;
        }
        @media (max-width: 480px){
          width: 100%;
        }
        &:hover{
          z-index: 5;
          &:first-child{
            .about-numbers__item-icon{
              @media (max-width: 1440px){
                padding-left: 0;
              }
            }
          }
          .about-numbers__item-number{
            font-size: 3.4rem;
            line-height: 5rem;
            text-align: center;
            color: $base-color;
            transform: translateX(-50%) translateY(calc(76px));
            @media (max-width: 480px) {
              transform: translateX(-50%) translateY(calc(60px));
            }
          }
          .about-numbers__item-icon{
            opacity: .2;
            svg{
              @media (max-width: 1440px){
                width: 100%;
              }
            }
            @media (max-width: 480px) {
              top: 0;
            }
          }
          .about-numbers__item-inner{
            &:after{
              height: 263px;
              @include transition();
              @media (max-width: 480px) {
                height: 215px;
              }
            }
          }
          .about-numbers__item-text{
            top: calc(100% + 56px);
            height: auto;
            padding: 22px;
            @include transition();
            @media (max-width: 1440px){
              top: calc(100% + 73px);
            }
            @media (max-width: 480px){
              top: calc(100% + 68px);
            }
            span{
              transform: none;
            }
          }
        }
        &:first-child{
          .about-numbers__item-icon{
            @media (max-width: 1440px){
              padding-left: 10px;
            }
            @media (max-width: 1000px) {
              padding-left: 0;
            }
          }
        }

        &-inner{
          position: relative;
            z-index: 4;
          background-color: #F8F8F8;
          &:before{
            content: none;
          }
          &:after{
            top: -7px;
            box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
            background-color: $white;
            @include transition();
          }
        }
        &-content{
          display: flex;
          justify-content: center;
          @media (max-width: 1440px) {
            height: 183px;
          }
          @media (max-width: 480px){
            height: 140px;
          }
        }
        &-icon{
          right: calc(65% + 8px);
          svg{
            @media (max-width: 1440px){
              width: 80%;
            }
          }
        }
        &-number{
          font-size: 2.6rem;
          line-height: 3.5rem;
          color: $base-color;
          white-space: nowrap;
          left: calc(35% + 8px);
          @media (max-width: 1440px){
            font-size: 2rem;
          }
        }
        &-text{
          position: absolute;
          top: 0;
          height: 0;
          overflow: hidden;
          width: 100%;
          background-color: $blue;
          padding: 0 22px;
          box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
          @include transition();
          span{
            font-size: 1.5rem;
            line-height: 2.8rem;
          }
        }
      }
    }
  }
  .landing-question{
    margin-bottom: 6rem;
    .wrapper{
      max-width: 1425px;
    }
    .guest-section{
      &-left{
        @media (max-width: 600px) {
          order: 2;
        }
      }
      &-right{
        @media (max-width: 600px) {
          order: 1;
          margin-bottom: 20px;
        }
      }
    }
    .pagination{
      margin-top: 6rem;
    }
  }
  .forward{
    font-weight: $font-weight--700;
  }
  .main-news{
    padding: 0;
  }
  .main-news__wrapper{
    max-width: 1425px;
    & > .main-news__container{
          width: 100%;
          margin: 6rem auto;
      .main-news__container{
        margin: 0;
      }
      .main-news__item{
        width: percentage(455 / 1405);
        margin: 0;
        margin-right: percentage(20 / 1405);
        @media (max-width: 1000px) {
          width: 100%;
          margin: 0;
        }
        &:nth-child(3n){
          margin-right: 0;
        }
      }
    }
  }

}