.poll {
  .wrapper {
    max-width: 1435px;
    padding-top: 30px;
    padding-bottom: 60px;
    @media (max-width: 768px) {
      padding-top: 25px;
      padding-bottom: 50px;
    }
    @media (max-width: 600px) {
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }

  h2 {
    font-weight: $font-weight--700;
    font-size: 2.6rem;
    line-height: 3.5rem;
    color: $base-color;
    margin-bottom: 1.5rem;
  }

  &-voting {
    &--advantages {
      margin-bottom: 3rem;

      span {
        display: block;
        font-size: 1.5rem;
        line-height: 2.8rem;
        color: $base-color-80;
        margin-bottom: 1.5rem;
      }

      ul {
        li {
          padding-left: 3rem;
          margin-bottom: 1.5rem;
          position: relative;
          font-size: 1.5rem;
          line-height: 2.8rem;
          color: $base-color-80;

          &:before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $blue;
            position: absolute;
            left: .2rem;
            top: 1rem;
          }
        }
      }
    }

    &--choose {
      padding-bottom: 30px;
      border-bottom: 1px solid $footer-bg;
      margin-bottom: 30px;
      @media (max-width: 768px) {
        margin-bottom: 25px;
        padding-bottom: 25px;
      }
      @media (max-width: 600px) {
        margin-bottom: 20px;
        padding-bottom: 20px;
      }

      &__wrapper {
        background-color: $footer-bg;
        padding: 29px 26px 25px;
        margin-bottom: 20px;
        @media (max-width: 768px) {
          padding: 24px 21px 20px;
        }
        @media (max-width: 600px) {
          padding: 19px 20px 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      h3 {
        font-weight: $font-weight--500;
        font-size: 1.8rem;
        line-height: 3.2rem;
        margin-bottom: 3rem;
        color: $base-color-80;
      }

      label {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        cursor: pointer;
        min-height: 40px;
        &:last-child{
          margin-bottom: 0;
        }
        @media (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 20px;
          min-height: auto;
        }

        &:hover {
          .radio-btn {
            &:before {
              opacity: 1;
              @include transition();
            }

            .choose {
              border: 1px solid $blue;
              @include transition();
            }
          }
        }

        input {
          display: none;

          &:checked + .radio-btn {
            &:before {
              opacity: 1;
            }

            .choose {
              border: 1px solid $blue;

              &:before {
                content: '';
                display: block;
                width: 2px;
                height: 5px;
                border-radius: 1px;
                background-color: $white;
                position: absolute;
                transform: rotate(-43deg);
                left: 7px;
                top: 10px;
              }

              &:after {
                content: '';
                display: block;
                width: 2px;
                height: 10px;
                border-radius: 1px;
                background-color: $white;
                position: absolute;
                transform: rotate(43deg);
                left: 11px;
                top: 6px;
              }
            }
          }

          &:checked ~ .percent {
            .percent-some {
              background-color: $blue;
            }
          }
        }

        & > .radio-btn {
          font-size: 1.5rem;
          line-height: 24px;
          min-height: 24px;
          color: $base-color-80;
          padding-left: 39px;
          position: relative;
          width: percentage(280 / 1363);
          margin-right: 10px;
          @media (max-width: 768px) {
            width: 100%;
            margin-right: 0;
          }

          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 3px;
            top: 3px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: $blue;
            opacity: 0;
            @include transition();
          }

          .choose {
            display: block;
            left: 0;
            position: absolute;
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            border: 2px solid $blue;
            border-radius: 50%;
            @include transition();
          }
        }

        .percent {
          display: flex;
          opacity: 0;
          width: percentage(1064 / 1363);
          transition: all .5s;
          @media (max-width: 768px) {
            width: 100%;
            margin-top: 15px;
          }

          &-some {
            display: block;
            background-color: $brown;
            height: 40px;
            transition: all .5s;
            width: 0;
          }

          &-description {
            display: flex;
            align-items: center;
            margin-left: 3rem;
            @media (max-width: 768px) {
              flex-direction: column;
              justify-content: center;
            }

            &-left {
              font-size: 2.6rem;
              line-height: 3.5rem;
              color: $base-color;
              font-weight: $font-weight--700;
            }

            &-right {
              font-size: 1.2rem;
              line-height: 1.6rem;
              letter-spacing: 1px;
              text-transform: uppercase;
              color: $base-color-30;
              margin-left: 1.5rem;
              text-align: center;
              @media (max-width: 768px) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  &-archive {
    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4rem;
      margin-top: 3rem;
    }

    &-item {
      display: block;
      padding: 30px;
      width: percentage(457 / 1415);
      margin-right: percentage(22 / 1415);
      box-shadow: 4px 6px 16px rgba(33, 35, 36, 0.15);
      @include transition();
      margin-bottom: 20px;
      @media (max-width: 1000px) {
        width: calc(50% - 10px);
        margin-right: 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
      @media (max-width: 768px) {
        padding: 21px;
      }
      @media (max-width: 600px) {
        width: 100%;
        margin-right: 0;
        padding: 20px;
      }

      &:hover {
        box-shadow: 8px 10px 20px rgba(33, 35, 36, 0.15);
        @include transition();
      }

      &:nth-child(3n) {
        margin-right: 0;
      }

      &--header {
        font-weight: $font-weight--500;
        font-size: 1.8rem;
        line-height: 3.2rem;
        color: $base-color;
        display: block;
        margin-bottom: 10px;
        @media(max-width: 600px){
          margin-bottom: 5px;
        }
      }

      &--date {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media(max-width: 600px){
          margin-bottom: 15px;
        }
        svg {
          margin-right: 10px;
        }

        span {
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $base-color-30;
        }
      }

      &--value {
        margin-bottom: 21px;
        @media(max-width: 600px){
          margin-bottom: 15px;
        }
        .number {
          font-size: 7.2rem;
          line-height: 8rem;
          font-weight: $font-weight--900;
          text-align: center;
          display: block;
          margin-bottom: 5px;
        }

        .description {
          display: block;
          font-size: 1.5rem;
          line-height: 2.2rem;
          text-align: center;
          color: $base-color;
          opacity: .8;
        }
      }

      &--votebar {
        height: 36px;
        background-color: $brown;
        margin-bottom: 30px;
        @media(max-width: 600px){
          margin-bottom: 25px;
        }
        .most {
          background-color: $blue;
          height: 100%;
        }
      }

      &--details {
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: $font-weight--500;
        text-transform: uppercase;
        color: $blue;
        padding-right: 27px;
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 3px;
          right: 0;
          width: 14px;
          height: 8px;
          background-image: url('../images/details-arrow.svg');
        }
      }
    }
  }
}