.landing-map{
  margin-bottom: 6rem;
  .title-h2{
    margin-bottom: 5rem;
    span{
      font-size: 1.8rem;
      line-height: 3.2rem;
      font-weight: $font-weight--500;
      text-align: center;
      color: $base-color;
    }
  }
  .yandex-map{
    height: 500px;
  }
}